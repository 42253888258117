@charset "utf-8";

/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
  position: relative;
  &__item {
    font-size: 1.6rem;
    font-weight: 500;
    width: calc(100% / 6);
    padding: 17px 0;
    text-align: center;
    background: #fff;
    position: relative;
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 6px;
      margin-top: -5px;
      background: url(../img/icon/ico_rank_bla.png) no-repeat center center;
      background-size: contain;
    }
    $pickUpOfficeList: divorce, debt, real-estate, labor-problems, traffic-accident, corporate, criminal-case, debt-collection, consumer-damage, inheritance;
    @each $name in $pickUpOfficeList {
      &.#{$name} {
        &::before {
          background: url(../img/icon/ico_#{$name}_bla.png) no-repeat center center;
          background-size: contain;
        }
      }
    }
    &:not(.active) {
      cursor: pointer;
    }
    &.active {
      color: #fff;
      background: $color-theme;
      &::before {
        background: url(../img/icon/ico_rank_whi.png) no-repeat center center;
        background-size: contain;
      }
      @each $name in $pickUpOfficeList {
        &.#{$name} {
          &::before {
            background: url(../img/icon/ico_#{$name}_whi.png) no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
  }
}
.tabItem {
  background: #fff;
  min-height: 300px;
  .tabList {
    display: none;
  }
  .tabList.show {
    display: block!important;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    tab
  =================================*/
  .tabMenu {
    flex-wrap: wrap;
    &__item {
      font-size: 1.1rem;
      padding: 5px;
      width: calc(100% / 3);
      &::before {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .tabItem {
    min-height: inherit;
  }
}