@charset "utf-8";

/*=================================
  pager
=================================*/
.pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &List {
    display: flex;
    &__item {
      margin-right: 10px;
      a,span {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1;
        padding: 15px 12px;
        display: block;
      }
      a {
        color: #333;
        border-bottom: 4px solid #ccc;
        text-decoration: none;
        @include mq-desktop {
          &:hover {
            color: $color-theme;
            border-color: $color-blue;
          }
        }
      }
      span {
        color: $color-theme;
        border-bottom: 4px solid $color-blue;
      }
    }
  }
  .output {
    font-size: 1.8rem;
    .num {
      font-weight: 600;
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    &List {
      justify-content: center;
      &__item {
        a,span {
          font-size: 1.8rem;
          padding: 12px 10px;
        }
      }
    }
    .output {
      display: none;
    }
  }
}