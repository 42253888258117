@charset "utf-8";

/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}
.js-accordion__item {
  display: none;
}


@media screen and (max-width: $display-width-s){
  /*=================================
    accordion
  =================================*/
}