@charset "utf-8";

/* =================================
  header
================================= */
.header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  background: $color-theme;
  &__top {
    position: relative;
    .ttl--site {
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      width: 25%;
      text-align: center;
      .logo {
        display: block;
        padding: 30px 10px;
        text-decoration: none;
        @include animation-base;
      }
      .sub {
        font-size: 1.4rem;
        font-weight: 300;
        color: #000;
        display: block;
        margin-bottom: 3px;
        @include animation-base;
      }
      @include mq-desktop {
        &:hover {
          .logo,.sub {
            opacity: .8;
          }
        }
      }
    }
  }
  .gNav {
    &__inner {
      padding: 10px 3% 10px 0;
      margin-left: 31%;
      display: flex;
      justify-content: flex-end;
    }
    .officeNum {
      color: #fff;
    }
    &__list {
      &__item {
        margin-left: 20px;
        display: inline-block;
        a {
          font-size: 1.3rem;
          font-weight: 300;
          color: #fff;
          padding-left: 14px;
          position: relative;
          text-decoration: none;
          &::before {
            content: "";
            display: inline-block;
            border-style: solid;
            border-width: 5px 0 5px 8px;
            border-color: transparent transparent transparent #fff;
            position: absolute;
            top: 5px;
            left: 0;
          }
          .icon {
            display: none;
          }
          @include mq-desktop {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .headerNav__cover{
    display: none;
  }
  .searchBox {
    &__inner {
      max-width: inherit;
      padding-right: calc((100% - #{$base-width}) / 2);
      padding-left: 31%;
    }
    .inputBox {
      padding: 5px;
      .ken {
        width: calc((115% - 120px) / 2);
        padding-left: 80px;
        &::before {
          font-size: 1.2rem;
          top: 2px;
          left: 10px;
        }
        &::after {
          width: 22px;
          height: 22px;
          bottom: 8px;
          left: 15px;
        }
        .area {
          right: 10px;
          a {
            font-size: 1.2rem;
          }
        }
      }
      .naiyo {
        width: calc((85% - 120px) / 2);
        padding-left: 80px;
        &::before {
          font-size: 1.2rem;
          top: 2px;
          left: 10px;
        }
        &::after {
          width: 22px;
          height: 22px;
          bottom: 8px;
          left: 30px;
        }
      }
      .btn {
        width: 121px;
        a {
          &::before {
            width: 24px;
            height: 24px;
          }
        }
      }
      .input {
        font-size: 1.8rem;
        padding: 12px 0;
      }
    }
  }
}
@media screen and (max-width: 1025px) {
  /* =================================
    header
  ================================= */
  .header {
    &__top {
      .ttl--site {
        .logo {
          padding: 10px;
          text-align: left;

        }
      }
    }
    .searchBox {
      display: none;
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /* =================================
    header
  ================================= */
  .header {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    &__top {
      .ttl--site {
        position: static;
        max-width: 55%;
        width: inherit;
        .logo {
          padding: 9px 0 0 10px;
        }
        .sub {
          font-size: 1rem;
          color: #444;
          margin-bottom: 0;
        }
        img {
          width: 150px;
          vertical-align: baseline;
        }
      }
    }
    .gNav {
      width: 100%;
      height: calc(90vh - 54px);
      overflow-y: auto;
      position: fixed;
      top: 54px;
      left: 0;
      margin: 0;
      padding: 0;
      z-index: 9999;
      opacity: 0;
      background: #333;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      &__inner {
        padding: 35px 3%;
        margin: 0 auto;
        display: block;
        .navTtl {
          font-size: 2rem;
          color: #fff;
          text-align: center;
          margin-bottom: 20px;
        }
        .navBox {
          text-align: center;
          margin-bottom: 30px;
          a {
            font-size: 1.4rem;
            color: #fff;
            text-align: center;
            padding: 29px 17px;
            overflow: hidden;
            background: #3d3d3d;
            border: 1px solid #515151;
            border-radius: 4px;
            display: inline-block;
          }
          img {
            display: block;
            margin: 0 auto 5px;
          }
        }
      }
      .officeNum {
        display: none;
      }
      &__list {
        display: block;
        width: 90%;
        margin: 0 auto;
        overflow: hidden;
        background: #3d3d3d;
        border: 1px solid #515151;
        border-radius: 4px;
        &__item {
          margin-left: 0;
          display: inline-block;
          a {
            padding: 15px 12px 15px;
            display: inline-block;
            &::before {
              content: none;
            }
            .icon {
              width: 26px;
              margin-right: 5px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
      &.show {
        visibility: visible;
        opacity: 1;
      }
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
    }
    .headerNav__cover{
      height: 0;
      width: 100vw;
      background: rgba(34, 34, 34, .95);
      text-align: center;
      position: absolute;
      top: 54px;
      display: none;
      z-index: 9998;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
      span {
        font-size: 1.6rem;
        font-weight: 700;
        color: #fff;
        position: absolute;
        left: 50%;
        bottom: 3vh;
        transform: translateX(-50%);
        &::before {
          content: "";
          width: 15px;
          height: 3px;
          display: inline-block;
          background: #fff;
          transform: rotate(45deg);
          position: absolute;
          top: 12px;
          left: -25px;
        }
        &::after {
          content: "";
          width: 15px;
          height: 3px;
          display: inline-block;
          background: #fff;
          transform: rotate(-45deg);
          position: absolute;
          top: 12px;
          left: -25px;
        }
      }
    }
    /*バーガーボタン設定*/
    .burger {
      width: 30px;
      height: 26px;
      margin: auto;
      position: absolute;
      right: 18px;
      top: 15px;
      cursor: pointer;
      z-index: 9999;
      span {
        width: 30px;
        height: 4px;
        display: block;
        background: #347cbd;
        position: absolute;
        top: 11px;
        right: 0;
        left: 0;
        margin: 0 auto;
        transition: width 0.2s, right 0.2s, left 0.2s;
        &.burger--top{
          transform: translateY(-11px);
        }
        &.burger--middle{
          transform: translateY(0px);
          position: relative;
          background: none;
          &::before{
            content: "";
            display: block;
            position: absolute;
            width: 30px;
            height: 4px;
            background: #347cbd;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            width: 30px;
            height: 4px;
            background: #347cbd;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
        }
        &.burger--bottom{
          transform: translateY(11px);
        }
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        background: transparent;
        box-shadow: none;
        position: fixed;
        .burger--top{
          left: 100%;
          width: 0px;
        }
        .burger--middle {
          &::before{
            transform: rotate(135deg);
          }
          &::after {
            transform: rotate(45deg);
          }
        }
        .burger--bottom{
          right: 100%;
          width: 0px;
        }
      }
    }
  }
}