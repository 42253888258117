@charset "utf-8";

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  padding: 30px 0;
  ul {
    display: flex;
    align-items: center;
    li {
      font-size: 1.4rem;
      line-height: 1;
      position: relative;
      a,span {
        font-size: 1.4rem;
        line-height: 1;
        display: block;
        padding: 10px 8px 12px;
        background: #fff;
        border: 1px solid #ddd;
      }
      & + li {
        padding-left: 20px;
        &::before {
          content: "≫";
          font-size: 1rem;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 20px 3%;
    ul {
      li {
        font-size: 1.2rem;
        a,span {
          font-size: 1.2rem;
          padding: 8px 6px 10px;
        }
        & + li {
          &::before {
            font-size: 1rem;
          }
        }
      }
    }
  }
}