@charset "utf-8";

//transition初期設定
@mixin animation-base($property:all, $duration:.2s, $timing:ease-out) {
    transition-property: $property;
    transition-duration: $duration;
    transition-timing-function: $timing;
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}