@charset "utf-8";
/*=================================
  topPage
=================================*/
#topPage {
  //サーチ
  .searchBox {
    padding: 30px 3%;
    &__inner {
      margin: 0 auto;
      display: block;
    }
    .officeNum {
      display: block;
    }
  }
  //セクション共通
  .secWrap {
    padding: 50px 1%;
    .topTtl {
      font-size: 2.6rem;
      font-weight: 500;
      color: #222;
      text-align: center;
      margin-bottom: 44px;
      &.white {
        color: #fff;
      }
    }
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
    }
    &.white {
      background: #fff;
    }
    &.gray {
      background: #eee;
    }
  }
  .layout--2col {
    padding-top: 50px;
  }
  //メリット
  .merit {
    &List {
      display: flex;
      &__item {
        background: #fff;
        width: calc((100% - 4px) / 3);
        padding: 138px 2% 60px;
        .meritTtl {
          font-size: 2rem;
          font-weight: 500;
          color: $color-theme;
          text-align: center;
          margin-bottom: 20px;
        }
        .meritText {
          line-height: 1.5;
        }
        &:not(:last-child) {
          margin-right: 2px;
        }
        @for $meritNum from 1 through 3 {
          &:nth-child(#{$meritNum}) {
            background: #fff url(../img/icon/ico_mer0#{$meritNum}.png) no-repeat center top 50px;
            .meritTtl {
              padding-top: 60px;
              position: relative;
              &::before {
                content: "メリット#{$meritNum}";
                font-size: 1.4rem;
                color: #333;
                background: #eee;
                border-radius: 50px;
                padding: 8px 23px;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }
  /* 都道府県 */
  /* 主要都市 */
  .prefMajor {
    &List {
      display: flex;
      &__item {
        text-align: center;
        width: calc((100% - 5.2%) / 5);
        min-width: 120px;
        margin-bottom: 20px;
        a {
          font-size: 2.4rem;
          color: #fff;
          text-decoration: none;
          padding: 19% 0;
          display: block;
          .ruby {
            @include font-roboto;
            font-size: 1.2rem;
            font-weight: 700;
            display: block;
          }
          @include mq-desktop {
            &:hover {
              opacity: .9;
            }
          }
        }
        &:not(:nth-child(5n + 1)) {
          margin-left: 1.3%;
        }
        $prefMajorList: tokyo, osaka, aichi, hokkaido, fukuoka;
        @each $name in $prefMajorList {
          &.#{$name} {
            a {
              background: url(../img/pref_#{$name}.png) no-repeat center center;
              background-size: cover;
            }
          }
        }
      }
    }
  }
  /* 都道府県一覧 */
  .pref {
    &Wrap {
      display: flex;
      flex-wrap: wrap;
      &__block {
        width: calc((100% - 5.2%) / 5);
        margin-bottom: 20px;
        .prefTtl {
          color: $color-theme;
          margin-bottom: 10px;
        }
        .prefList {
          display: flex;
          flex-wrap: wrap;
          &__item {
            margin: 0 10px 12px 0;
            line-height: 1;
            a {
              font-size: 1.4rem;
              color: #222;
            }
          }
        }
        &:not(:nth-child(5n + 1)) {
          margin-left: 1.3%;
        }
      }
    }
  }
  /* 厳選事務所 */
  .pickUpOffice {
    background: #77b8f2 url(../img/bg_pickup_office.png) no-repeat center top;
    .tabList__ttl {
      font-size: 2rem;
      font-weight: 500;
      background: #f3f3f3;
      padding: 27px 32px;
      position: relative;
      .date {
        color: $color-theme;
        margin-right: 2rem;
      }
      &::after {
        content: "";
        width: calc(100% / 6);
        height: 4px;
        display: block;
        background: $color-theme;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__list {
      border-top: 1px solid #ccc;
      background: #f3f3f3;
      display: flex;
      &__item {
        width: calc(100% / 4);
        min-height: 380px;
        padding: 15px 15px 25px;
        position: relative;
        background: #f3f3f3;
        .thumb {
          margin-bottom: 20px;
        }
        .textWrap {
          .name {
            display: block;
            text-align: center;
          }
          .text {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.8571;
            margin-top: 15px;
          }
        }
        .areaWrap {
          position: absolute;
          left: 15px;
          bottom: 25px;
          &::before {
            content: "";
            width: 14px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/ico_taiou.png) no-repeat center center;
            background-size: cover;
            vertical-align: middle;
          }
          .lavel,.area {
            font-size: 1.2rem;
            display: inline-block;
          }
          .area {
            &::before {
              content: "/";
              display: inline-block;
              margin: 0 .2rem 0 0;
            }
          }
        }
        &:not(:first-child) {
          border-left: 1px solid #ccc;
        }
        @include mq-desktop {
          &:hover {
            background: #fff;
            opacity: 1!important;
          }
        }
      }
    }
  }
  /* 新着 */
  .newCont {
    background: linear-gradient(#333 30%, #fff 30%, #fff);
    &__wrap {
      display: flex;
      justify-content: space-between;
    }
    &__ttl {
      font-size: 2.6rem;
      font-weight: 500;
      color: #fff;
      margin-bottom: 50px;
      .ruby {
        font-size: 1.4rem;
        @include font-roboto;
        font-weight: 500;
        margin-left: 2rem;
        vertical-align: middle;
      }
    }
    &__item {
      width: calc((100% - 60px) / 2);
    }
    &__list {
      background: #fff;
      padding: 10px;
      &__item {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
        .thumb {
          width: 120px;
          float: left;
          position: relative;
          &.new {
            &::before {
              content: "NEW";
              @include font-roboto;
              font-size: 1.4rem;
              font-weight: 700;
              line-height: 1;
              color: #fff;
              background: rgba(232, 48, 33,.9);
              padding: 7px 16px 5px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        .textWrap {
          width: calc(100% - 120px);
          padding: 0 0 0 15px;
          float: right;
          &__ttl {
            font-size: 1.6rem;
            font-weight: 500;
          }
          &__text {
            font-size: 1.4rem;
            line-height: 1.8571;
            color: #222;
          }
          .info {
            &__tag {
              display: inline-block;
              margin-right: 5px;
              &__item {
                font-size: 1.2rem;
                line-height: 1;
                color: #fff;
                padding: 4px 8px 5px;
                background: #333;
                border-radius: 2px;
                display: inline-block;
              }
            }
            &__date {
              font-size: 1.2rem;
              display: inline-block;
              background: url(../img/icon/ico_cal.png) no-repeat left center;
              padding: 0 0 0 20px;
            }
          }
        }
      }
    }
  }
  .articleCat {
    padding-top: 0;
    padding-bottom: 0;
    &__wrap {
      display: flex;
      flex-wrap: wrap;
    }
    &__item {
      width: calc((100% - 80px) / 3);
      margin-bottom: 35px;
      &:not(:nth-child(3n + 1)) {
        margin-left: 40px;
      }
    }
    &__ttl {
      font-size: 2.6rem;
      font-weight: 300;
      padding: 36px 0;
      position: relative;
      &::before {
        content: "";
        width: 80px;
        height: 4px;
        display: inline-block;
        background: $color-theme;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__list {
      &__item {
        background: #fff;
        padding: 15px 10px;
        &:not(:last-child) {
          border-bottom: 1px solid #ddd;
        }
        .thumb {
          width: 80px;
          float: left;
        }
        .textWrap {
          width: calc(100% - 80px);
          padding: 0 0 0 12px;
          float: right;
          &__ttl {
            font-size: 1.4rem;
            font-weight: 500;
          }
          &__view {
            font-size: 1.2rem;
            line-height: 1;
            background: url(../img/icon/ico_eye.png) no-repeat left center;
            padding-left: 22px;
            margin-top: 10px;
          }
        }
      }
    }
    .moreLink {
      text-align: center;
      margin-top: 30px;
      a {
        font-size: 1.4rem;
        position: relative;
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          box-sizing: border-box;
          display: inline-block;
          margin-right: 4px;
          border: 4px solid transparent;
          border-left: 8px solid #005bac;
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    topPage
  =================================*/
  #topPage {
    //セクション共通
    .secWrap {
      padding: 30px 0;
      .topTtl {
        font-size: 2.2rem;
        margin-bottom: 30px;
      }
    }
    .layout--2col {
      padding-top: 0;
    }
    //メリット
    .merit {
      &List {
        display: block;
        padding: 0 4.3%;
        &__item {
          width: 100%;
          padding: 50px 4.3% 25px;
          .meritTtl {
            font-size: 1.6rem;
            margin-bottom: 15px;
          }
          &:not(:last-child) {
            margin: 0 0 4.3%;
          }
          @for $meritNum from 1 through 3 {
            &:nth-child(#{$meritNum}) {
              background: #fff url(../img/icon/ico_mer0#{$meritNum}.png) no-repeat center top 30px;
              background-size: 50px;
              .meritTtl {
                padding-top: 50px;
                &::before {
                  font-size: 1.2rem;
                  padding: 6px 18px;
                }
              }
            }
          }
        }
      }
    }
    /* 都道府県 */
    /* 主要都市 */
    .prefMajor {
      display: none;
      &List {
        flex-wrap: wrap;
        justify-content: center;
        &__item {
          margin-bottom: 5px;
          a {
            font-size: 1.8rem;
            .ruby {
              font-size: 1.1rem;
            }
          }
          &:not(:nth-child(5n + 1)) {
            margin-left: 5px;
          }
        }
      }
    }
    /* 都道府県一覧 */
    .pref {
      padding: 0 4.3%;
      &Wrap {
        display: block;
        &__block {
          width: 100%;
          margin-bottom: 18px;
          .prefTtl {
            font-size: 1.6rem;
            font-weight: 500;
          }
          &:not(:nth-child(5n + 1)) {
            margin-left: 0;
          }
        }
      }
    }
    /* 厳選事務所 */
    .pickUpOffice {
      padding-left: 3%;
      padding-right: 3%;
      .tabList__ttl {
        font-size: 1.6rem;
        padding: 10px 15px 15px;
        .date {
          display: block;
          margin: 0 0 5px 0;
        }
        &::after {
          width: 100px;
          height: 3px;
        }
      }
      &__list {
        display: block;
        &__item {
          width: 100%;
          min-height: inherit;
          padding: 15px 15px 55px;
          .thumb {
            margin-bottom: 15px;
            margin: 0 auto;
            text-align: center;
          }
          .textWrap {
            .text {
              margin-top: 10px;
            }
          }
          .areaWrap {
            bottom: 15px;
          }
          &:not(:first-child) {
            border-left: none;
            border-top: 1px solid #ccc;
          }
        }
      }
    }
    /* 新着 */
    .newCont {
      background: #333;
      padding-left: 3%;
      padding-right: 3%;
      &__wrap {
        display: block;
      }
      &__ttl {
        font-size: 2.2rem;
        margin-bottom: 30px;
        .ruby {
          font-size: 1.2rem;
          margin-left: 1.5rem;
        }
      }
      &__item {
        width: 100%;
        & + .newCont__item {
          margin-top: 30px;
        }
      }
      &__list {
        padding: 0;
        &__item {
          padding: 15px;
          margin-bottom: 0;
          .thumb {
            width: 80px;
            &.new {
              &::before {
                font-size: 1.2rem;
                padding: 5px 6px 3px;
              }
            }
          }
          .textWrap {
            width: calc(100% - 80px);
            padding: 0 0 0 10px;
            &__ttl {
              font-size: 1.4rem;
            }
            &__text {
              line-height: 1.2142;
              margin-top: 3px;
            }
            .info {
              margin-top: 10px;
              &__tag {
                margin-right: 4px;
                &__item {
                  font-size: 1.1rem;
                  padding: 4px 4px 5px;
                }
              }
              &__date {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }
    .articleCat {
      padding: 30px 3%;
      &__wrap {
        display: block;
      }
      &__item {
        width: 100%;
        margin-bottom: 30px;
        &:not(:nth-child(3n + 1)) {
          margin-left: 0;
        }
      }
      &__ttl {
        font-size: 2rem;
        padding: 4px 15px 8px;
        margin-bottom: 12px;
        &::before {
          width: 5px;
          height: 100%;
        }
      }
      &__list {
        &__item {
          background: #fff;
          padding: 15px 10px;
          border-bottom: 1px solid #ddd;
          .textWrap {
            padding: 0 0 0 10px;
            &__view {
              font-size: 1.1rem;
              padding-left: 18px;
            }
          }
        }
      }
      .moreLink {
        margin-top: 25px;
      }
    }
  }
}