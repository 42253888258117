@charset "utf-8";
/*=================================
  footer
=================================*/
.footer {
  &__cont {
    &--cate {
      background: #333;
      padding: 40px 3%;
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .fNavCate {
        &__ttl {
          font-size: 2.2rem;
          font-weight: 300;
          color: #fff;
          margin-bottom: 30px;
        }
        &--btn {
          width: 300px;
          margin-right: 20px;
          margin-bottom: 6px;
          &__wrap {
            display: flex;
            justify-content: space-between;
          }
          .fNavCate__item {
            width: 100%;
            a {
              font-size: 1.4rem;
              color: #fff;
              border: 1px solid #515151;
              border-radius: 4px;
              background: #3d3d3d;
              padding: 33px 27px 35px;
              display: block;
              text-align: center;
              text-decoration: none;
              @include mq-desktop {
                &:hover {
                  text-decoration: underline;
                }
              }
              img {
                display: block;
                margin: 0 auto;
                margin-bottom: 10px;
              }
            }
            & + .fNavCate__item {
              margin-left: 20px;
            }
          }
        }
        &--list {
          width: calc(100% - 356px);
          min-width: 664px;
          &__wrap {
            background: #3d3d3d;
            border-radius: 4px;
            border: 1px solid #515151;
            position: relative;
          }
          &__item {
            display: inline-block;
            a {
              font-size: 1.4rem;
              color: #fff;
              padding: 20px 15px;
              background: #3d3d3d;
              display: block;
              text-decoration: none;
              .icon {
                width: 32px;
                margin-right: 7px;
                vertical-align: middle;
              }
              @include mq-desktop {
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:last-child {
              &::after {
                content: "";
                width: 100%;
                height: 1px;
                display: block;
                background: #515151;
                position: absolute;
                top: 50%;
                left: 0;
              }
            }
          }
        }
      }
    }
    &--list {
      background: #444;
      padding: 30px 0;
      .fNavList {
        max-width: $base-width;
        margin: 0 auto;
        text-align: center;
        &__item {
          display: inline-block;
          margin: 0 18px;
          a {
            font-size: 1.4rem;
            color: #fff;
            text-decoration: none;
            position: relative;
            &::before {
              content: "";
              left: 8px;
              box-sizing: border-box;
              width: 6px;
              height: 6px;
              border: 4px solid transparent;
              border-left: 8px solid #fff;
              display: inline-block;
            }
            @include mq-desktop {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    &--group {
      background: #2f74b7;
      padding: 30px 0 25px;
      .group {
        &Ttl {
          color: #fff;
          text-align: center;
          margin-bottom: 10px;
        }
        &List {
          width: 90%;
          margin: 0 auto;
          &__item {
            display: inline-block;
            margin: 0 20px 0 0;
            a {
              font-size: 1.6rem;
              color: #fff;
            }
          }
        }
      }
    }
    &--bnr {
      padding: 40px 1%;
      .bnr {
        &Ttl {
          font-size: 2.4rem;
          font-weight: 400;
          text-align: center;
          margin-bottom: 40px;
        }
        &List {
          max-width: $base-width;
          margin: 0 auto;
          &__item {
            display: inline-block;
            margin: 0 8px 15px 0;
            a {
              display: block;
              @include mq-desktop {
                &:hover {
                  opacity: .8;
                }
              }
            }
          }
        }
      }
    }
  }
  .copy {
    background: #333;
    text-align: center;
    padding: 35px 0;
    &__logo {
      margin-bottom: 10px;
    }
    &__text {
      font-size: 1.4rem;
      color: #fff;
      display: block;
      text-align: center;
    }
  }
}

.fixedBnr {
  width: 100%;
  max-width: $base-width;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    footer
  =================================*/
  .footer {
    &__cont {
      &--cate {
        padding: 30px 3%;
        &__inner {
          display: block;
        }
        .fNavCate {
          &__ttl {
            font-size: 2rem;
            margin-bottom: 15px;
            text-align: center;
          }
          &--btn {
            width: 100%;
            margin-right: 0;
            margin-bottom: 25px;
            &__wrap {
              justify-content: center;
            }
            .fNavCate__item {
              width: inherit;
              a {
                padding: 26px 17px 27px;
              }
              & + .fNavCate__item {
                margin-left: 10px;
              }
            }
          }
          &--list {
            width: 100%;
            min-width: inherit;
            &__item {
              display: inline-block;
              a {
                padding: 15px 12px 15px;
                .icon {
                  width: 26px;
                  margin-right: 5px;
                }
              }
              &:last-child {
                &::after {
                  content: none;
                }
              }
            }
          }
        }
      }
      &--list {
        padding: 25px 3%;
        .fNavList {
          text-align: left;
          &__item {
            margin: 0 10px 5px 0;
            a {
              font-size: 1.2rem;
            }
          }
        }
      }
      &--group {
        padding: 25px 3% 20px;
        .group {
          &Ttl {
            margin-bottom: 15px;
          }
          &List {
            width: 100%;
            &__item {
              margin: 0 10px 5px 0;
              a {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
      &--bnr {
        padding: 30px 3%;
        .bnr {
          &Ttl {
            font-size: 2rem;
            margin-bottom: 25px;
          }
          &List {
            &__item {
              width: 47%;
              margin: 0 1% 2%;
              a {
                display: block;
              }
            }
          }
        }
      }
    }
    .copy {
      padding: 25px 0;
      &__text {
        font-size: 1.2rem;
      }
    }
  }
  .fixedBnr {
    bottom: 0;
  }
}