@charset "utf-8";

//変数の定義
$base-width: 1160px;
$base-width-in: 1030px;
$display-width-s: 767px;
$base-color: #fff;
$font-color-base: #333;
$color-theme: #005bac;
$color-red: #cb290e;
$color-blue: #337cbd;
$color-blue-dark: #2667a1;
$color-pink: #ee063c;
$color-pink-dark: #b0022b;

@mixin mq-sp {
    @media screen and (min-width: 0) and (max-width: $display-width-s) {
        @content;
    }
}
@mixin mq-tab {
    @media screen and (min-width: $display-width-s) and (max-width: 1024px) {
        @content;
    }
}
@mixin mq-desktop {
    @media screen and (min-width: $display-width-s) {
        @content;
    }
}

//フォントの定義
%font {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
  font-feature-settings: "palt";
  color: $font-color-base;
  letter-spacing: .04rem;
  line-height: 1.875;
  @include mq-sp{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.57142;
  } //::スマホ
}

@mixin font-roboto {
  font-family: 'Roboto Condensed', sans-serif;
}
%font-roboto {
  font-family: 'Roboto Condensed', sans-serif;
}

//タイトルの定義
@mixin ttl-base($size,$color:$font-color-base,$lineHeight:1.25,$weight:bold) {
  font-size: $size;
  color: $color;
  line-height: $lineHeight;
  font-weight: $weight;
  font-family: 'Roboto', "Noto Sans JP", "源ノ角ゴシック", "Yu Gothic Medium","游ゴシック Medium","游ゴシック",YuGothic,YuGothicM,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,"ＭＳ Ｐゴシック",sans-serif;
}

@mixin spInner {
  width: calc(100% - 7%);
  max-width: $base-width;
  margin-left: auto;
  margin-right: auto;
  @include mq-desktop() {
    width: 100%;
  } //::PC
}

//余白の定義
%margin-wrap {
  @include mq-sp{//::スマホ
    margin: 0 8px;
  }
}
%margin-wrap-in {
  margin: 0 24px;
  @include mq-sp{//::スマホ
    margin: 0 12px;
  }
}