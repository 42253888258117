@charset "utf-8";

a {
  &:hover {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
}
@media screen and (min-width: $display-width-s){
  /* =================================
    hover
  ================================= */
  a {
    @include animation-base(all);
  }
  .widelink {
    @include animation-base;
    cursor: pointer;
    .widelink__cover {
      position: relative;
      overflow: hidden;
      &::before {
        content: "詳しく見る";
        font-size: 1.4rem;
        color: #fff;
        letter-spacing: .06em;
        line-height: 1;
        text-align: center;
        opacity: 0;
        padding: 10px 0;
        width: 55%;
        border: 1px solid #fff;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        transition-property: opacity,top;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        background-color: rgba(31, 31, 31,.5);
        color: white;
        transition-property: opacity;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
    }
    .widelink__article {
      position: relative;
      overflow: hidden;
      &::before {
        content: "この記事を読む";
        font-size: 1.2rem;
        color: #fff;
        line-height: 1;
        text-align: center;
        opacity: 0;
        padding: 10px 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        transition-property: opacity,top;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;
        opacity: 0;
        background-color: rgba(31, 31, 31,.3);
        color: white;
        transition-property: opacity;
        transition-duration: 0.15s;
        transition-timing-function: ease-out;
      }
    }
    &:hover {
      a {
        text-decoration: none;
      }
      .widelink__cover,.widelink__article {
        &::before {
          opacity: 1;
          top: 50%;
        }
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .hoverBg {
    .widelink__cover {
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
    &:hover {
      background: #ebfafa;
    }
  }
}