@charset "utf-8";
/*=================================
  事務所詳細
=================================*/
.pageNav {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  &__list {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    a {
      font-size: 1.6rem;
      text-decoration: none;
      padding: 18px 5px;
      display: block;
      text-align: center;
      @include mq-desktop {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__item {
      width: calc(100% / 6);
      position: relative;
      border-right: 1px solid #ddd;
      z-index: 100;
      a {
        color: #333;
        @include mq-desktop {
          &:hover {
            color: #fff;
            background: $color-theme;
          }
        }
      }
      &:first-child {
        border-left: 1px solid #ddd;
      }
      &:hover {
        .pageNav__listChild {
          opacity: 1;
          visibility: visible;
          top: 100%;
        }
      }
    }
    &Child {
      width: 100%;
      position: absolute;
      top: 70%;
      left: 0;
      opacity: 0;
      visibility: hidden;
      @include animation-base;
      &__item {
        a {
          color: #fff;
          background: rgba(0,0,0, 0.9);
          @include mq-desktop {
            &:hover {
              background: rgba(0,0,0, 0.8);
            }
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid #6a6a6a;
        }
      }
    }
  }
}

.bengoDetail {
  background: #eee;
  padding: 40px 1% 100px;
  &--2col {
    max-width: $base-width;
    margin: 0 auto;
    position: relative;
  }
  .bengoInfo {
    width: 480px;
    position:absolute;
    top: 0;
    right: 0;
    &__inner {
      width: 480px;
      background: #fff;
      border-bottom: 1px solid #ddd;
      .contact {
        &Item {
          .ttl {
            font-size: 1.8rem;
            color: #fff;
            width: 20%;
            height: 100px;
            min-width: 100px;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
          }
          .text {
            width: 80%;
            padding: 16px 18px;
            border-top: 1px solid #ddd;
            border-right: 1px solid #ddd;
            display: table-cell;
            vertical-align: middle;
          }
          &.tel {
            .ttl {
              background: #338d3d;
            }
            .text {
              a {
                font-size: 3.6rem;
                font-weight: 500;
                color: #338d3d;
                &::before {
                  content: "";
                  width: 23px;
                  height: 30px;
                  display: inline-block;
                  margin-right: 7px;
                  background: url(../img/icon/ico_tel_g.png) no-repeat center center;
                  background-size: contain;
                }
              }
            }
          }
          &.status {
            .ttl {
              background: $color-blue;
            }
            .text {
              font-size: 1.8rem;
              .time {
                margin-right: 3rem;
                display: inline-block;
              }
              .holiday {
                display: inline-block;
                .label {
                  font-size: 1.4rem;
                  font-weight: 600;
                  background: #eee;
                  padding: 5px 19px;
                  border-radius: 50px;
                  display: inline;
                  margin-right: 1rem;
                }
                .day {
                  display: inline;
                }
              }
            }
          }
          &:last-of-type {
            .text {
              border-bottom: 1px solid #ddd;
            }
          }
        }
        .mailBtn {
          padding: 20px 25px 25px;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          .btn--blue {
            font-size: 1.8rem;
            max-width: 100%;
            padding-top: 22px;
            padding-bottom: 22px;
            text-decoration: none;
            &::before {
              content: "";
              width: 21px;
              height: 17px;
              display: inline-block;
              background: url(../img/icon/ico_mail.png) no-repeat;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
      .moreInfo {
        &__item {
          .ttl {
            font-size: 1.8rem;
            color: #fff;
            background: #333;
            padding: 20px;
          }
          .cont {
            padding: 20px 28px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            .areaList {
              &__item {
                font-size: 1.6rem;
                color: #222;
                display: inline-block;
                margin-right: 1.5rem;
                a {
                  font-size: 1.6rem;
                }
              }
            }
            .list {
              &__item {
                font-size: 1.6rem;
                color: #222;
                display: inline-block;
                padding-left: 34px;
                background: url(../img/icon/ico_arw04.png) no-repeat left center;
                background-size: contain;
                margin: 0 1.5rem 1rem 0;
              }
            }
          }
        }
      }
    }
  }
  &__cont {
    padding-right: 520px;
  }
  .bengoMv {
    margin: 0 auto 20px;
    display: flex;
    flex-wrap: wrap;
    &__item {
      &:first-child {
        width: 100%;
        margin: 0 auto;
      }
      &:nth-child(n + 2) {
        width: calc((100% - 40px) / 3);
        margin: 20px 0 0 20px;
        &:nth-child(3n - 1) {
          margin-left: 0;
        }
      }
    }
  }
  &.form {
    background: #fff;
  }
}
.bengoSec {
  padding: 0 0 80px;
  &__inner {
    max-width: calc(#{$base-width} + 2%);
    margin: 0 auto;
    padding: 0 1%;
  }
  &Ttl {
    font-size: 3.6rem;
    font-weight: 500;
    color: #222;
    padding: 50px 0;
    text-align: center;
    background: #fff;
  }
}
// 強み
.strong {
  &List {
    &__item {
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        padding: 120px 0 100px;
        position: relative;
        .strongTtl {
          width: 160px;
          height: 160px;
          text-align: center;
          position: absolute;
          top: -70px;
          left: 50%;
          transform: translateX(-50%);
          &__inner {
            font-size: 2.6rem;
            font-weight: 500;
            color: #fff;
            width: 160px;
            height: 160px;
            background: $color-blue;
            display: table-cell;
            vertical-align: middle;
          }
        }
        .strongCont {
          & > {
            @include singleStyle-base;
          }
          @include singleStyle-parts;
          h1 {
            margin-top: 40px;
          }
          img:not([class]) {
            max-width: 380px;
            margin-bottom: 15px;
          }
          .ttl {
            font-size: 3.6rem;
            font-weight: 500;
            color: #222;
            margin-bottom: 30px;
          }
        }
      }
      &:nth-child(2n - 1) {
        img:not([class]) {
          float: right;
          margin-left: 60px;
        }
      }
      &:nth-child(2n) {
        background: #eee;
        img:not([class]) {
          float: left;
          margin-right: 60px;
        }
      }
    }
  }
}
//料金
.price {
  .bengoSecTtl {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }
}
//所属弁護士
.member {
  background: #eee;
  .bengoSecTtl {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }
  &List {
    padding-top: 68px;
    &__item {
      .memberInfo {
        &__band {
          color: #fff;
          background: #333;
          padding: 18px 30px 18px 50px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .img {
            width: 100px;
            margin-right: 30px;
          }
          .ttl {
            font-size: 1.8rem;
            margin-right: 40px;
            .name {
              font-size: 2.8rem;
              margin-right: 1rem;
            }
          }
          .affInfo {
            display: flex;
            margin-top: 5px;
            &__item {
              .label {
                font-size: 1.4rem;
                color: #fff;
                background: $color-blue;
                border-radius: 50px;
                padding: 2px 12px;
                min-width: 80px;
                text-align: center;
                margin-right: 10px;
                display: inline-block;
              }
              .text {
                font-size: 1.8rem;
                display: inline-block;
              }
              & + .affInfo__item {
                margin-left: 40px;
              }
            }
          }
        }
        &__sub {
          padding: 20px 0;
          background: #fff;
          display: flex;
          .subInfo {
            width: 50%;
            padding: 0 25px;
            & + .subInfo {
              border-left: 1px solid #d5d5d5;
            }
            &Cont {
              display: flex;
              .subInfo__ttl {
                width: 125px;
              }
              .subInfo__cont {
                width: calc(100% - 125px);
              }
              & + .subInfoCont {
                margin-top: 30px;
              }
            }
          }
        }
      }
      & + .memberList__item {
        margin-top: 30px;
      }
    }
  }
}
//アクセス
.access {
  padding-bottom: 0;
  .gMap {
    margin: 0 auto;
    iframe {
      width: 100%;
    }
  }
  table {
    margin: 0;
    border: none;
    tr,th,td {
      font-size: 1.4rem;
      background: transparent;
      border: none;
    }
    th {
      font-weight: 500;
      color: $color-theme;
      padding-left: calc((100% - #{$base-width}) / 2);
      padding-right: 30px;
    }
    td {
      padding-right: calc((100% - #{$base-width}) / 2);
    }
    tr:nth-child(2n + 1) {
      background: #f4f4f4;
    }
  }
}
@media screen and (max-width: 1250px) and (min-width: $display-width-s) {
  //アクセス
  .access {
    table {
      th {
        padding: 20px;
      }
      td {
        padding: 20px;
      }
    }
  }
}
//事務所概要
.office {
  .bengoSecTtl {
    border-bottom: 1px solid #dbdbdb;
  }
  &__inner {
    padding: 0 1%;
  }
  table {
    margin: 0;
    border: none;
    max-width: $base-width;
    margin: 0 auto;
  }
  tr,th,td {
    font-size: 1.4rem;
    background: transparent;
  }
  th,td {
    border: none;
  }
  th {
    color: $color-theme;
    padding-left: 0;
  }
}
.fixedBnr.bengo {
  border: 5px solid $color-blue;
  background: #f1f8ff;
  .bnrInner {
    display: flex;
    .cont {
      width: calc(100% - 150px);
      padding: 25px 35px 40px;
      .name {
        font-size: 2.6rem;
        font-weight: 400;
        margin-bottom: 5px;
        color: #222;
      }
      .info {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .tel {
          margin-right: 30px;
          .label {
            font-size: 1.4rem;
            color: #fff;
            background: #338d3d;
            border-radius: 50px;
            min-width: 80px;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
          }
          .num {
            display: inline-block;
            vertical-align: bottom;
            a {
              font-size: 3.2rem;
              font-weight: 500;
              line-height: 1;
              color: #338d3d;
              display: block;
              &::before {
                content: "";
                width: 23px;
                height: 30px;
                display: inline-block;
                margin-right: 7px;
                background: url(../img/icon/ico_tel_g.png) no-repeat center center;
                background-size: contain;
                vertical-align: middle;
              }
            }
          }
        }
        .status {
          display: flex;
          align-items: center;
          margin-top: 5px;
          &__item {
            .label {
              font-size: 1.4rem;
              background: #fff;
              border-radius: 50px;
              min-width: 80px;
              text-align: center;
              display: inline-block;
              margin-right: 15px;
            }
            .text {
              font-size: 1.8rem;
              display: inline-block;
            }
            & + .status__item {
              margin-left: 20px;
            }
          }
        }
      }
    }
    .message {
      width: 150px;
      text-align: center;
      background: $color-blue;
      position: relative;
      .text {
        font-size: 1.8rem;
        font-weight: 500;
        color: #fff;
        padding: 20px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .bengoDetail {
    padding: 20px 3% 60px;
    .bengoInfo {
      width: 100%;
      position: static;
      &__inner {
        width: 100%;
        .contact {
          &Item {
            display: flex;
            .ttl {
              font-size: 1.4rem;
              width: 60px;
              height: inherit;
              min-width: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .text {
              width: calc(100% - 60px);
              padding: 10px;
              display: block;
            }
            &.tel {
              .text {
                a {
                  font-size: 2.4rem;
                  &::before {
                    width: 18px;
                    height: 24px;
                    margin-right: 5px;
                  }
                }
              }
            }
            &.status {
              .text {
                font-size: 1.4rem;
                .time {
                  margin-right: 1.5rem;
                }
                .holiday {
                  .label {
                    font-size: 1.2rem;
                    padding: 3px 8px;
                    margin-right: .5rem;
                  }
                }
              }
            }
          }
          .mailBtn {
            padding: 15px;
            .btn--blue {
              font-size: 1.6rem;
              padding-top: 18px;
              padding-bottom: 18px;
            }
          }
        }
        .moreInfo {
          &__item {
            .ttl {
              font-size: 1.6rem;
              padding: 12px;
            }
            .cont {
              padding: 15px;
              .areaList {
                &__item {
                  font-size: 1.4rem;
                  margin-right: 1rem;
                  a {
                    font-size: 1.4rem;
                  }
                }
              }
              .list {
                &__item {
                  font-size: 1.4rem;
                  padding-left: 30px;
                }
              }
            }
          }
        }
      }
    }
    &__cont {
      padding-right: 0;
      margin: 0;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    事務所詳細
  =================================*/
  .pageNav {
    display: none;
  }
  .bengoSec {
    padding: 0 0 40px;
    &__inner {
      padding: 0 3%;
    }
    &Ttl {
      font-size: 2.2rem;
      padding: 25px 0;
    }
  }
  // 強み
  .strong {
    &List {
      &__item {
        &__inner {
          padding: 80px 3% 45px;
          .strongTtl {
            width: 100px;
            height: 100px;
            top: -35px;
            &__inner {
              font-size: 1.8rem;
              width: 100px;
              height: 100px;
            }
          }
          .strongCont {
            h1 {
              margin-top: 20px;
            }
            img:not([class]) {
              max-width: 80%;
              margin: 0 auto 15px;
              display: block;
            }
            .ttl {
              font-size: 1.8rem;
              margin-bottom: 20px;
            }
          }
        }
        &:nth-child(2n - 1) {
          img:not([class]) {
            float: none;
            margin-left: auto;
          }
        }
        &:nth-child(2n) {
          img:not([class]) {
            float: none;
            margin-right: auto;
          }
        }
      }
    }
  }
  //所属弁護士
  .member {
    &List {
      padding-top: 40px;
      &__item {
        .memberInfo {
          &__band {
            padding: 20px 10px 10px;
            display: block;
            text-align: center;
            .img {
              margin: 0 auto;
            }
            .ttl {
              font-size: 1.4rem;
              margin: 5px 0 0;
              .name {
                font-size: 2rem;
                margin-right: .5rem;
              }
            }
            .affInfo {
              flex-wrap: wrap;
              justify-content: center;
              margin-top: 5px;
              &__item {
                margin-right: 1.5rem;
                .label {
                  font-size: 1.1rem;
                  padding: 2px 8px;
                  min-width: inherit;
                  margin-right: 3px;
                }
                .text {
                  font-size: 1.4rem;
                }
                & + .affInfo__item {
                  margin-left: 0;
                }
              }
            }
          }
          &__sub {
            padding: 10px 0;
            display: block;
            .subInfo {
              width: 100%;
              padding: 0 3%;
              font-size: 1.3rem;
              & + .subInfo {
                border-left: none;
              }
              &Cont {
                .subInfo__ttl {
                  width: 8em;
                }
                .subInfo__cont {
                  width: calc(100% - 8em);
                }
                & + .subInfoCont {
                  margin-top: 10px;
                }
              }
              & + .subInfo {
                margin-top: 10px;
              }
            }
          }
        }
        & + .memberList__item {
          margin-top: 20px;
        }
      }
    }
  }
  //アクセス
  .access {
    table {
      display: table;
      white-space: normal;
      tbody {
        border: none;
      }
      th {
        padding: 15px 10px;
        width: 10em;
      }
      td {
        padding: 15px 10px;
      }
    }
  }
  //事務所概要
  .office {
    &__inner {
      padding: 0 3%;
    }
    table {
      display: table;
      white-space: normal;
      tbody {
        border: none;
      }
      th,td {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      th {
        width: 8em;
      }
    }
  }
  .fixedBnr.bengo {
    border: 3px solid $color-blue;
    .bnrInner {
      .cont {
        width: calc(100% - 90px);
        padding: 10px 15px 15px;
        .name {
          font-size: 1.5rem;
          display: none;
        }
        .info {
          .tel {
            margin: 0;
            .label {
              font-size: 1.2rem;
              min-width: 60px;
              margin-right: 3px;
            }
            .num {
              a {
                font-size: 2rem;
                &::before {
                  width: 18px;
                  height: 25px;
                }
              }
            }
          }
          .status {
            flex-wrap: wrap;
            &__item {
              margin-right: 5px;
              .label {
                font-size: 1.2rem;
                min-width: 60px;
                margin-right: 3px;
              }
              .text {
                font-size: 1.3rem;
              }
              & + .status__item {
                margin-left: 0px;
              }
            }
          }
        }
      }
      .message {
        width: 90px;
        .text {
          font-size: 1.2rem;
          padding: 5px;
        }
      }
    }
  }
}

/* 問い合わせフォーム */
/* .mw_wp_form */
.mw_wp_form {
  input,textarea {
    font-size: 1.8rem;
    font-weight: 400;
    width: 100%;
    border: 2px solid #e5e5e5;
    background: #fff;
    padding: 5px 10px;
  }
  .hs {
    border: 2px solid #fbd193;
    background: #fffaf0;
  }
  .his {
    font-size: 1.2rem;
    color: #fff;
    background: #c94941;
    display: inline-block;
    margin: 3px 0 0 4px;
    padding: 4px 10px 5px;
  }
  th {
    width: 250px;
  }
  td {
    span {
      margin: 10px 0 0 14px;
      color: #666;
      display: block
    }
  }
  table {
    display: table!important;
    white-space: inherit;
    tbody {
      display: table-row-group;;
    }
  }
  .submit {
    text-align: center;
    margin: 20px auto 0;
    input[type="submit"] {
      border: 1px solid #666;
      background: #f6f6f6;
      padding: 20px 0;
      margin: 2px 0;
      max-width: 400px;
      cursor: pointer;
      @include mq-desktop {
        &:hover {
          background: #ddd;
        }
      }
    }
  }
}

.mw_wp_form_preview {
  td {
    span {
      display: none;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /* 問い合わせフォーム */
  /* .mw_wp_form */
  .mw_wp_form {
    input,textarea {
      font-size: 1.6rem;
      padding: 5px 5px;
    }
    .his {
      font-size: 1.1rem;
      margin: 0 0 0 10px;
      padding: 3px 0;
      width: 3.5em;
      text-align: center;
    }
    th {
      width: 100%;
      display: block;
    }
    td {
      width: 100%;
      display: block;
      span {
        font-size: 1.1rem;
        margin: 6px 0 0;
      }
    }
    .submit {
      margin: 0 auto 40px;
      input[type="submit"] {
        margin: 4px 0;
        padding: 20px 0;
      }
    }
  }
}