@charset "utf-8";
/*=================================
  下層ページ共通
=================================*/
/* 記事一覧 */
.articleList--simple {
  display: flex;
  flex-wrap: wrap;
  .articleList__item {
    width: calc((100% - 80px) / 3);
    margin-bottom: 40px;
    @include mq-tab {
      width: calc((100% - 20px) / 2);
      &:nth-child(2n) {
        margin-left: 20px!important;
      }
    }
    &:not(:nth-child(3n + 1)) {
      margin-left: 40px;
      @include mq-tab {
        &:not(:nth-child(3n + 1)) {
          margin-left: inherit;
        }
      }
    }
    .itemWrap {
      position: relative;
      .thumb {
        img {
          width: 100%;
        }
      }
      .textWrap {
        color: #fff;
        padding: 15px;
        background: rgba(0,0,0,.7);
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        &__ttl {
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.625;
          color: #fff;
          display: block;
        }
        .info {
          font-size: 1.2rem;
          margin-top: 16px;
          overflow: hidden;
          &__date {
            float: left;
            padding: 3px 0 3px 20px;
            background: url(../img/icon/ico_cal_w.png) no-repeat left center;
          }
          &__view {
            float: right;
            padding: 3px 0 3px 20px;
            background: url(../img/icon/ico_eye_w.png) no-repeat left center;
          }
        }
      }
    }
    @include mq-desktop {
      &.widelink {
        &:hover {
          .textWrap {
            background: rgba(0,91,172, 0.8);
          }
        }
      }
    }
  }
}
.articleList--col2 {
  .articleList__item {
    width: 100%;
    margin-bottom: 30px;
    .itemWrap {
      display: flex;
      .thumb {
        width: 360px;
        img {
          width: 100%;
        }
      }
      .textWrap {
        width: calc(100% - 360px);
        color: #fff;
        padding: 15px 25px;
        background: #333;
        @include animation-base;
        &__ttl {
          font-size: 2rem;
          font-weight: 400;
          line-height: 1.625;
          color: #fff;
          display: block;
        }
        &__text {
          font-size: 1.4rem;
          font-weight: 400;
          margin-top: 10px;
        }
        .tagList {
          display: flex;
          flex-wrap: wrap;
          margin-top: 15px;
          &__item {
            font-size: 1.2rem;
            color: #fff;
            border: 1px solid #fff;
            padding: 3px 4px;
            & + .tagList__item {
              margin-left: 6px;
            }
          }
        }
        .info {
          font-size: 1.2rem;
          margin-top: 10px;
          overflow: hidden;
          &__date {
            float: left;
            padding: 3px 0 3px 20px;
            background: url(../img/icon/ico_cal_w.png) no-repeat left center;
          }
          &__view {
            float: right;
            padding: 3px 0 3px 20px;
            background: url(../img/icon/ico_eye_w.png) no-repeat left center;
          }
        }
      }
    }
    @include mq-desktop {
      &.widelink {
        &:hover {
          .textWrap {
            background: $color-theme;
          }
        }
      }
    }
  }
}
/* 弁護士を探す */
.pushBox {
  margin: 60px 0 0;
  &__ttl {
    font-size: 2.8rem;
    line-height: 1.4;
    color: #fff;
    padding: 68px 30px;
    background: url(../img/img_sec02.png) no-repeat;
    background-size: cover;
  }
  &__list {
    padding: 30px 30px 10px;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background: #eee;
    &__item {
      font-size: 1.8rem;
      font-weight: 500;
      color: #e50000;
      margin-bottom: 20px;
      padding: 0 0 0 30px;
      background: url(../img/icon/ico_chk02.png) no-repeat left center;
    }
  }
  .band {
    font-size: 2rem;
    font-weight: 500;
    color: #fff;
    text-align: center;
    padding: 25px 0;
    background: #f14537;
  }
  .pref {
    color: #fff;
    background: #333;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    a {
      color: #fff;
    }
    &__block {
      width: calc((100% - 40px) / 2);
    }
    &Ttl {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: 7px;
    }
    &Wrap {
      margin-bottom: 10px;
    }
    &List {
      &__item {
        display: inline-block;
        margin: 0 10px 10px 0;
      }
    }
    .pushBtn {
      margin-top: 20px;
      width: 100%;
      a {
        display: block;
        padding: 15px 0;
        border-radius: 6px;
        border: 3px solid #fff;
        color: #fff;
        text-align: center;
        &::before {
          display: inline-block;
          content: "";
          margin-right: 4px;
          box-sizing: border-box;
          width: 6px;
          height: 6px;
          border: 4px solid transparent;
          border-left: 8px solid #fff;
        }
        @include mq-desktop {
          &:hover {
            background: rgba(255,255,255, 0.1);
          }
        }
      }
    }
  }
}

/* 関連記事 */
.relatedBox {
  &__ttl {
    font-size: 2.4rem;
    margin-top: 54px;
    padding: 40px 20px;
    border-top: 4px solid #999;
    font-weight: 500;
    line-height: 1.2272;
    position: relative;
    &::before {
      content: "";
      width: 28%;
      height: 4px;
      display: block;
      background: $color-theme;
      position: absolute;
      top: -4px;
      left: 0;
    }
  }
  .articleList__item {
    width: calc((100% - 40px) / 2);
    &:not(:nth-child(3n + 1)) {
      margin-left: inherit;
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
}
/* 事案絞り込み */
.caseSearch {
  text-align: center;
  margin-bottom: 45px;
  .caseSwitch {
    margin: 20px auto;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    color: #fff;
    display: inline-block;
    border-radius: 50px;
    background: $color-blue;
    padding: 1px 10px;
    position: relative;
    &::before {
      content: "";
      box-sizing: border-box;
      width: 6px;
      height: 6px;
      border: 4px solid transparent;
      border-left: 8px solid #fff;
      display: inline-block;
      vertical-align: middle;
      margin: -3px 1px 0 0;
    }
    &.open {
      &::before {
        transform: rotate(-90deg);
        margin-top: -5px;
      }
    }
  }
  .caseList {
    padding: 30px 15px;
    background: #f6f6f6;
    border: 1px solid #ddd;
    text-align: left;
    &__item {
      display: inline-block;
      margin: 0 15px;
      a {
        font-size: 1.6rem;
        color: #333;
        text-decoration: none;
        padding: 4px 0 5px 15px;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          border-width: 5px 0 5px 8px;
          border-style: solid;
          border-color: transparent transparent transparent $color-theme;
          position: absolute;
          top: 12px;
          left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .articleList--col2 {
    .articleList__item {
      margin-bottom: 15px;
      .itemWrap {
        display: block;
        position: relative;
        .thumb {
          width: 100%;
        }
        .textWrap {
          width: 100%;
          height: 100%;
          padding: 20px 5%;
          background: rgba(0,0,0, 0.8);
          position: absolute;
          bottom: 0;
          left: 0;
          &__ttl {
            font-size: 1.8rem;
            font-weight: 400;
            line-height: 1.5;
          }
          &__text {
            font-size: 1.3rem;
            margin-top: 8px;
          }
          .tagList {
            margin-top: 10px;
            &__item {
              & + .tagList__item {
                margin-left: 4px;
              }
            }
          }
          .info {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    下層ページ共通
  =================================*/
  .articleList--simple {
    display: block;
    .articleList__item {
      width: 100%;
      margin-bottom: 15px;
      &:not(:nth-child(3n + 1)) {
        margin-left: 0;
      }
      .itemWrap {
        .textWrap {
          .info {
            margin-top: 5px;
          }
        }
      }
    }
  }

  /* 弁護士を探す */
  .pushBox {
    margin: 40px 0 0;
    &__ttl {
      font-size: 1.8rem;
      padding: 12px 3%;
    }
    &__list {
      padding: 15px 15px 5px;
      &__item {
        font-size: 1.4rem;
        margin-bottom: 15px;
        padding: 3px 0 3px 30px;
      }
    }
    .band {
      font-size: 1.6rem;
      padding: 20px 10px;
    }
    .pref {
      padding: 15px 15px 25px;
      display: block;
      &__block {
        width: 100%;
      }
      &Ttl {
        font-size: 1.4rem;
      }
      &Wrap {
        margin-bottom: 15px;
      }
      &List {
        &__item {
          display: inline-block;
          margin: 0 8px 5px 0;
        }
      }
      .pushBtn {
        margin-top: 25px;
      }
    }
  }

  /* 関連記事 */
  .relatedBox {
    padding: 0 3%;
    &__ttl {
      font-size: 1.8rem;
      margin-top: 40px;
      padding: 20px 3% 20px;
      &::before {
        width: 130px;
      }
    }
    .articleList__item {
      width: 100%;
      &:nth-child(2n) {
        margin-left: inherit;
      }
    }
  }
  /* 事案絞り込み */
  .caseSearch {
    margin-bottom: 30px;
    .caseSwitch {
      margin: 15px auto;
      font-size: 1.5rem;
      padding: 2px 8px;
      &::before {
        width: 5px;
        height: 5px;
        border: 3px solid transparent;
        border-left: 7px solid #fff;
      }
    }
    .caseList {
      padding: 15px 3%;
      &__item {
        margin: 0 5px;
        a {
          font-size: 1.4rem;
          padding: 2px 0 3px 12px;
          &::before {
            border-width: 4px 0 4px 6px;
            top: 8px;
          }
        }
      }
    }
  }
}