@charset "utf-8";

/*=================================
  title
=================================*/
.contTtl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #222;
  text-align: center;
  margin-bottom: 44px;
  &.white {
    color: #fff;
  }
}
.mainTtl--col2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  .ttl {
    font-size: 3.4rem;
    font-weight: 500;
    color: #222;
    line-height: 1.3;
  }
}
.pageTtl {
  padding: 40px 12px;
  background: #fff;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    padding-left: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    &::before {
      content: "";
      width: 12px;
      height: 100%;
      display: block;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#005bac+0,005bac+33,337cbd+33,337cbd+66,337cbd+66,dddddd+66,dddddd+100 */
      background: #005bac; /* Old browsers */
      background: -moz-linear-gradient(left,  #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  #005bac 0%,#005bac 33%,#337cbd 33%,#337cbd 66%,#337cbd 66%,#dddddd 66%,#dddddd 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  #005bac 0%,#005bac 33%,#337cbd 33%,#337cbd 66%,#337cbd 66%,#dddddd 66%,#dddddd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005bac', endColorstr='#dddddd',GradientType=1 ); /* IE6-9 */
      position: absolute;
      top: 0;
      left: 0;
    }
    .ttlWrap {
      max-width: calc(100% - 300px);
      .catch {
        font-size: 2.4rem;
        font-weight: 400;
        color: $color-theme;
        display: block;
      }
      .ttl {
        font-size: 3.6rem;
        font-weight: 400;
      }
    }
    .field {
      width: 280px;
      position: relative;
      &Ttl {
        font-size: 1.6rem;
        font-weight: 500;
        text-align: center;
        background: #fff;
        padding: 5px;
        display: inline-block;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      &List {
        border: 2px solid #d0d0d0;
        border-radius: 10px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        &__item {
          width: 30px;
          text-align: center;
          position: relative;
          img {
            width: 24px;
          }
          .balloon {
            font-size: 1.2rem;
            color: #fff;
            background: $color-theme;
            border-radius: 50px;
            width: 100px;
            text-align: center;
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            @include animation-base;
            &::after {
              content: "";
              display: inline-block;
              box-sizing: border-box;
              width: 6px;
              height: 6px;
              border: 4px solid transparent;
              border-top: 8px solid $color-theme;
              position: absolute;
              bottom: -10px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          @include mq-desktop {
            &:hover {
              .balloon {
                visibility: visible;
                opacity: 1;
                top: -20px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    title
  =================================*/
  .contTtl {
    font-size: 2.2rem;
    margin-bottom: 30px;
  }
  .mainTtl--col2 {
    display: block;
    margin-bottom: 20px;
    .ttl {
      font-size: 2.4rem;
    }
    .btn--grayRound {
      margin-top: 20px;
    }
  }
  .pageTtl {
    padding: 30px 3%;
    background: #fff;
    &__inner {
      padding-left: 20px;
      display: block;
      &::before {
        content: "";
        width: 12px;
        height: 100%;
        display: block;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#005bac+0,005bac+33,337cbd+33,337cbd+66,337cbd+66,dddddd+66,dddddd+100 */
        background: #005bac; /* Old browsers */
        background: -moz-linear-gradient(left,  #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #005bac 0%,#005bac 33%,#337cbd 33%,#337cbd 66%,#337cbd 66%,#dddddd 66%,#dddddd 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  #005bac 0%,#005bac 33%,#337cbd 33%,#337cbd 66%,#337cbd 66%,#dddddd 66%,#dddddd 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005bac', endColorstr='#dddddd',GradientType=1 ); /* IE6-9 */
        position: absolute;
        top: 0;
        left: 0;
      }
      .ttlWrap {
        max-width: inherit;
        .catch {
          font-size: 1.6rem;
          line-height: 1.2;
          margin-bottom: 5px;
        }
        .ttl {
          font-size: 2.4rem;
        }
      }
      .field {
        width: 100%;
        margin-top: 20px;
        &Ttl {
          font-size: 1.4rem;
          top: -15px;
        }
        &List {
          border: 1px solid #d0d0d0;
          border-radius: 6px;
          padding: 10px;
          &__item {
            width: 25px;
            img {
              width: 20px;
            }
            .balloon {
              display: none;
            }
          }
        }
      }
    }
  }
}