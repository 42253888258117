@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

a:hover {
  outline: none;
}

a:active {
  outline: none;
}

a:focus {
  outline: none;
}

@media screen and (min-width: 767px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: .06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    border: 1px solid #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity,top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "この記事を読む";
    font-size: 1.2rem;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity,top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.3);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #ebfafa;
  }
}

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
}

.btnWrap.center {
  text-align: center;
}

.btnWrap.right {
  text-align: right;
}

.btnWrap > * + * {
  margin-top: 9px;
}

.btn--red {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #ea5454;
  box-shadow: 0 5px 0 #ab1414;
  border-radius: 3px;
  padding: 17px 0px;
  width: 100%;
}

.btn--blue {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #337cbd;
  box-shadow: 0 4px 0 #2667a1;
  border-radius: 5px;
  padding: 17px 0px;
  width: 100%;
}

.btn--blue.lg {
  font-size: 1.8rem;
  padding: 15px 0;
  text-decoration: none;
}

.btn--white {
  font-size: 1.8rem;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  background: #fff;
  box-shadow: 0 5px 0 #555;
  border-radius: 5px;
  padding: 17px 0px;
  width: 100%;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .btn--white:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.btn--border {
  font-size: 1.5rem;
  color: #333;
  border: 4px solid #333;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
}

.btn--border::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  margin-right: 4px;
  border: 4px solid transparent;
  border-left: 8px solid #333;
}

.btn--grayRound {
  font-size: 1.4rem;
  color: #222;
  background: #eee;
  border-radius: 50px;
  text-decoration: none;
  padding: 6px 15px;
  display: inline-block;
  margin-top: 5px;
}

.link--back {
  margin: 0 0 30px;
}

.link--back a {
  font-size: 1.5rem;
  padding: 4px 0 4px 25px;
  background: url(../img/icon/ico_arw01.png) no-repeat left center;
  display: block;
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
  }
  .btnWrap > * + * {
    margin: 0 0 0 20px;
  }
  .btn--red {
    font-size: 2.6rem;
    border-radius: 5px;
    padding: 23px 10px;
    max-width: 94%;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
  }
  .btn--red:hover {
    box-shadow: none;
    transform: translateY(6px);
  }
  .btn--blue {
    font-size: 1.6rem;
    box-shadow: 0 4px 0 #2667a1;
    border-radius: 5px;
    padding: 13px 10px;
    max-width: 94%;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
  }
  .btn--blue:hover {
    box-shadow: none;
    transform: translateY(4px);
  }
  .btn--blue.lg {
    font-size: 2.6rem;
    padding: 30px 0;
    line-height: 1;
    max-width: 1160px;
  }
  .btn--white {
    font-size: 2.4rem;
    box-shadow: 0 6px 0 #555;
    border-radius: 5px;
    padding: 23px 10px;
    max-width: 555px;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
  }
  .btn--white:hover {
    box-shadow: none;
    transform: translateY(6px);
  }
  .btn--border {
    font-size: 1.6rem;
    max-width: 94%;
    padding: 19px 15px;
    transition-property: all;
    transition-duration: 0.1s;
    transition-timing-function: ease-out;
  }
  .btn--border:hover {
    background: #005bac;
    color: #fff;
    text-decoration: underline;
    border-color: #005bac;
  }
  .btn--border:hover::before {
    border-left: 8px solid #fff;
  }
  .btn--grayRound {
    font-size: 1.6rem;
    padding: 8px 25px;
  }
  .btn--grayRound:hover {
    color: #fff;
    background: #337cbd;
  }
  .link--back {
    margin: 0 0 40px;
  }
  .link--back a {
    font-size: 1.6rem;
  }
}

/*=================================
  title
=================================*/
.contTtl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #222;
  text-align: center;
  margin-bottom: 44px;
}

.contTtl.white {
  color: #fff;
}

.mainTtl--col2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.mainTtl--col2 .ttl {
  font-size: 3.4rem;
  font-weight: 500;
  color: #222;
  line-height: 1.3;
}

.pageTtl {
  padding: 40px 12px;
  background: #fff;
}

.pageTtl__inner {
  max-width: 1160px;
  margin: 0 auto;
  padding-left: 30px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.pageTtl__inner::before {
  content: "";
  width: 12px;
  height: 100%;
  display: block;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#005bac+0,005bac+33,337cbd+33,337cbd+66,337cbd+66,dddddd+66,dddddd+100 */
  background: #005bac;
  /* Old browsers */
  background: -moz-linear-gradient(left, #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005bac', endColorstr='#dddddd',GradientType=1 );
  /* IE6-9 */
  position: absolute;
  top: 0;
  left: 0;
}

.pageTtl__inner .ttlWrap {
  max-width: calc(100% - 300px);
}

.pageTtl__inner .ttlWrap .catch {
  font-size: 2.4rem;
  font-weight: 400;
  color: #005bac;
  display: block;
}

.pageTtl__inner .ttlWrap .ttl {
  font-size: 3.6rem;
  font-weight: 400;
}

.pageTtl__inner .field {
  width: 280px;
  position: relative;
}

.pageTtl__inner .fieldTtl {
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  background: #fff;
  padding: 5px;
  display: inline-block;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.pageTtl__inner .fieldList {
  border: 2px solid #d0d0d0;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}

.pageTtl__inner .fieldList__item {
  width: 30px;
  text-align: center;
  position: relative;
}

.pageTtl__inner .fieldList__item img {
  width: 24px;
}

.pageTtl__inner .fieldList__item .balloon {
  font-size: 1.2rem;
  color: #fff;
  background: #005bac;
  border-radius: 50px;
  width: 100px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.pageTtl__inner .fieldList__item .balloon::after {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 4px solid transparent;
  border-top: 8px solid #005bac;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 767px) {
  .pageTtl__inner .fieldList__item:hover .balloon {
    visibility: visible;
    opacity: 1;
    top: -20px;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .contTtl {
    font-size: 2.2rem;
    margin-bottom: 30px;
  }
  .mainTtl--col2 {
    display: block;
    margin-bottom: 20px;
  }
  .mainTtl--col2 .ttl {
    font-size: 2.4rem;
  }
  .mainTtl--col2 .btn--grayRound {
    margin-top: 20px;
  }
  .pageTtl {
    padding: 30px 3%;
    background: #fff;
  }
  .pageTtl__inner {
    padding-left: 20px;
    display: block;
  }
  .pageTtl__inner::before {
    content: "";
    width: 12px;
    height: 100%;
    display: block;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#005bac+0,005bac+33,337cbd+33,337cbd+66,337cbd+66,dddddd+66,dddddd+100 */
    background: #005bac;
    /* Old browsers */
    background: -moz-linear-gradient(left, #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #005bac 0%, #005bac 33%, #337cbd 33%, #337cbd 66%, #337cbd 66%, #dddddd 66%, #dddddd 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005bac', endColorstr='#dddddd',GradientType=1 );
    /* IE6-9 */
    position: absolute;
    top: 0;
    left: 0;
  }
  .pageTtl__inner .ttlWrap {
    max-width: inherit;
  }
  .pageTtl__inner .ttlWrap .catch {
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: 5px;
  }
  .pageTtl__inner .ttlWrap .ttl {
    font-size: 2.4rem;
  }
  .pageTtl__inner .field {
    width: 100%;
    margin-top: 20px;
  }
  .pageTtl__inner .fieldTtl {
    font-size: 1.4rem;
    top: -15px;
  }
  .pageTtl__inner .fieldList {
    border: 1px solid #d0d0d0;
    border-radius: 6px;
    padding: 10px;
  }
  .pageTtl__inner .fieldList__item {
    width: 25px;
  }
  .pageTtl__inner .fieldList__item img {
    width: 20px;
  }
  .pageTtl__inner .fieldList__item .balloon {
    display: none;
  }
}

/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
  position: relative;
}

.tabMenu__item {
  font-size: 1.6rem;
  font-weight: 500;
  width: calc(100% / 6);
  padding: 17px 0;
  text-align: center;
  background: #fff;
  position: relative;
}

.tabMenu__item::before {
  content: "";
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  margin-top: -5px;
  background: url(../img/icon/ico_rank_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.divorce::before {
  background: url(../img/icon/ico_divorce_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.debt::before {
  background: url(../img/icon/ico_debt_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.real-estate::before {
  background: url(../img/icon/ico_real-estate_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.labor-problems::before {
  background: url(../img/icon/ico_labor-problems_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.traffic-accident::before {
  background: url(../img/icon/ico_traffic-accident_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.corporate::before {
  background: url(../img/icon/ico_corporate_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.criminal-case::before {
  background: url(../img/icon/ico_criminal-case_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.debt-collection::before {
  background: url(../img/icon/ico_debt-collection_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.consumer-damage::before {
  background: url(../img/icon/ico_consumer-damage_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.inheritance::before {
  background: url(../img/icon/ico_inheritance_bla.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item:not(.active) {
  cursor: pointer;
}

.tabMenu__item.active {
  color: #fff;
  background: #005bac;
}

.tabMenu__item.active::before {
  background: url(../img/icon/ico_rank_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.divorce::before {
  background: url(../img/icon/ico_divorce_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.debt::before {
  background: url(../img/icon/ico_debt_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.real-estate::before {
  background: url(../img/icon/ico_real-estate_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.labor-problems::before {
  background: url(../img/icon/ico_labor-problems_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.traffic-accident::before {
  background: url(../img/icon/ico_traffic-accident_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.corporate::before {
  background: url(../img/icon/ico_corporate_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.criminal-case::before {
  background: url(../img/icon/ico_criminal-case_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.debt-collection::before {
  background: url(../img/icon/ico_debt-collection_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.consumer-damage::before {
  background: url(../img/icon/ico_consumer-damage_whi.png) no-repeat center center;
  background-size: contain;
}

.tabMenu__item.active.inheritance::before {
  background: url(../img/icon/ico_inheritance_whi.png) no-repeat center center;
  background-size: contain;
}

.tabItem {
  background: #fff;
  min-height: 300px;
}

.tabItem .tabList {
  display: none;
}

.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu {
    flex-wrap: wrap;
  }
  .tabMenu__item {
    font-size: 1.1rem;
    padding: 5px;
    width: calc(100% / 3);
  }
  .tabMenu__item::before {
    display: block;
    margin: 0 auto;
  }
  .tabItem {
    min-height: inherit;
  }
}

/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  margin: 50px 0 30px;
  width: 100%;
}

table caption {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

table thead th {
  font-weight: 700;
  color: #fff;
  background: #005bac;
}

table tr {
  border-bottom: 1px solid #ddd;
}

table th {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
  background: #f6f6f6;
  padding: 20px;
}

table td {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.25;
  padding: 20px;
}

table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}

table.scroll ::-webkit-scrollbar {
  height: 5px;
}

table.scroll ::-webkit-scrollbar-track {
  background: #F1F1F1;
}

table.scroll ::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0;
    border: none;
  }
  table tbody {
    border: 1px solid #ccc;
    min-width: calc(100vw - 12px);
    display: table;
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
    width: 94vw;
  }
  table th {
    font-size: 1.3rem;
    padding: 10px;
  }
  table td {
    font-size: 1.3rem;
    padding: 10px;
  }
}

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  padding: 30px 0;
}

.breadcrumb ul {
  display: flex;
  align-items: center;
}

.breadcrumb ul li {
  font-size: 1.4rem;
  line-height: 1;
  position: relative;
}

.breadcrumb ul li a, .breadcrumb ul li span {
  font-size: 1.4rem;
  line-height: 1;
  display: block;
  padding: 10px 8px 12px;
  background: #fff;
  border: 1px solid #ddd;
}

.breadcrumb ul li + li {
  padding-left: 20px;
}

.breadcrumb ul li + li::before {
  content: "≫";
  font-size: 1rem;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 20px 3%;
  }
  .breadcrumb ul li {
    font-size: 1.2rem;
  }
  .breadcrumb ul li a, .breadcrumb ul li span {
    font-size: 1.2rem;
    padding: 8px 6px 10px;
  }
  .breadcrumb ul li + li::before {
    font-size: 1rem;
  }
}

/*=================================
  search
=================================*/
.searchBox {
  padding: 15px 0;
  background: #333;
}

.searchBox__inner {
  max-width: 1160px;
  margin: 0 auto;
}

.searchBox .officeNum {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  display: none;
  /* top-page only parts */
  margin-bottom: 20px;
  text-align: center;
}

.searchBox .officeNum .num {
  font-size: 3.4rem;
}

.searchBox .inputBox {
  background: #222;
  padding: 10px;
}

.searchBox .inputBox__inner {
  background: #fff;
}

.searchBox .inputBox .formWrap {
  display: flex;
}

.searchBox .inputBox .ken {
  width: calc((115% - 180px) / 2);
  padding-left: 90px;
  position: relative;
}

.searchBox .inputBox .ken::before {
  content: "エリア";
  font-size: 1.4rem;
  font-weight: 300;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 25px;
}

.searchBox .inputBox .ken::after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../img/icon/ico_sea01.png) no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 18px;
  left: 30px;
}

.searchBox .inputBox .ken .area {
  font-size: 1.6rem;
  color: #555;
  text-decoration: underline;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  .searchBox .inputBox .ken .area:hover {
    text-decoration: none;
  }
}

.searchBox .inputBox .naiyo {
  width: calc((85% - 180px) / 2);
  padding-left: 90px;
  position: relative;
  background: #f2f2f2;
  /* Old browsers */
  background: -moz-linear-gradient(left, #f2f2f2 0%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #f2f2f2 0%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #f2f2f2 0%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=1 );
  /* IE6-9 */
}

.searchBox .inputBox .naiyo::before {
  content: "ご相談内容";
  font-size: 1.4rem;
  font-weight: 300;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 14px;
}

.searchBox .inputBox .naiyo::after {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../img/icon/ico_sea02.png) no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 18px;
  left: 36px;
}

.searchBox .inputBox .btn {
  width: 180px;
  cursor: pointer;
  background: #1166b2;
  /* Old browsers */
  background: -moz-linear-gradient(top, #1166b2 0%, #005bac 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #1166b2 0%, #005bac 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #1166b2 0%, #005bac 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1166b2', endColorstr='#005bac',GradientType=0 );
  /* IE6-9 */
}

.searchBox .inputBox .btn a {
  display: block;
  height: 100%;
  position: relative;
}

.searchBox .inputBox .btn a::before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../img/icon/ico_sea03.png) no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 767px) {
  .searchBox .inputBox .btn:hover {
    background: #157ad4;
    background: -moz-linear-gradient(top, #157ad4 0%, #0471d1 100%);
    background: -webkit-linear-gradient(top, #157ad4 0%, #0471d1 100%);
    background: linear-gradient(to bottom, #157ad4 0%, #0471d1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#157ad4', endColorstr='#0471d1',GradientType=0 );
  }
}

.searchBox .inputBox .input {
  font-size: 2.6rem;
  padding: 16px 0;
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.searchBox .kenlist, .searchBox .naiyolist {
  display: none;
}

.searchBox .kenlist {
  width: 100%;
  max-width: 800px;
  padding: 30px 30px 10px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  box-shadow: 0 1px 6px -4px #000;
}

.searchBox .kenlist .kenTtl {
  font-size: 1.5rem;
  font-weight: 500;
  color: #005bac;
  margin-bottom: 5px;
}

.searchBox .kenlist .kenName {
  line-height: 1;
  margin: 0 10px 10px 0;
  float: left;
}

.searchBox .kenlist .kenName a, .searchBox .kenlist .kenName span {
  font-size: 1.4rem;
  text-decoration: underline;
  color: #333;
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  .searchBox .kenlist .kenName a:hover, .searchBox .kenlist .kenName span:hover {
    text-decoration: none;
  }
}

.searchBox .kenlist__block {
  float: left;
  width: 370px;
}

.searchBox .kenlist__item {
  margin-bottom: 10px;
}

.searchBox .naiyolist {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  background: #fff;
  box-shadow: 0 1px 6px -4px #000;
  z-index: 9999;
}

.searchBox .naiyolist__item {
  width: 25%;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 80px;
  float: left;
  text-align: center;
}

.searchBox .naiyolist__item span {
  font-size: 1.4rem;
  color: #333;
  padding: 20px 0;
  display: block;
  cursor: pointer;
}

.searchBox .naiyolist__item span .icon {
  margin-right: 6px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .searchBox .naiyolist__item span:hover {
    background: #ecf8fd;
  }
}

.searchBox .naiyolist .other {
  color: #333;
  padding: 15px 20px;
  background: #fff;
}

/* オーバーレイ */
#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9001;
  cursor: pointer;
}

@media screen and (max-width: 1025px) {
  /*=================================
    search
  =================================*/
  .searchBox {
    padding: 20px 10px;
  }
  .searchBox .officeNum {
    font-size: 1.8rem;
  }
  .searchBox .officeNum .num {
    font-size: 2.4rem;
  }
  .searchBox .inputBox {
    padding: 5px;
  }
  .searchBox .inputBox .formWrap {
    display: block;
  }
  .searchBox .inputBox .area {
    display: none;
    top: 10px;
    right: 10px;
    left: inherit;
    width: 50px;
    text-align: left;
  }
  .searchBox .inputBox .ken {
    width: 100%;
    padding-left: 64px;
    border-bottom: 1px solid #ddd;
    position: relative;
  }
  .searchBox .inputBox .ken::before {
    font-size: 1.1rem;
    top: 7px;
    left: 16px;
  }
  .searchBox .inputBox .ken::after {
    width: 22px;
    height: 22px;
    bottom: 7px;
    left: 20px;
  }
  .searchBox .inputBox .ken .area {
    display: none;
  }
  .searchBox .inputBox .naiyo {
    width: 100%;
    padding-left: 64px;
    background: #fff;
    position: relative;
  }
  .searchBox .inputBox .naiyo::before {
    font-size: 1.1rem;
    top: 7px;
    left: 6px;
  }
  .searchBox .inputBox .naiyo::after {
    width: 22px;
    height: 22px;
    bottom: 7px;
    left: 20px;
  }
  .searchBox .inputBox .btn {
    width: 100%;
    padding: 20px 0;
  }
  .searchBox .inputBox .btn a::before {
    width: 18px;
    height: 18px;
  }
  .searchBox .inputBox .input {
    font-size: 1.6rem;
    padding: 15px 0;
  }
  .searchBox .kenlist {
    max-height: calc(50vh - 55px);
    overflow-y: auto;
    width: calc(100% - 56px);
    padding: 0;
    position: absolute;
    top: 55px;
    right: 0;
    left: inherit;
    transform: inherit;
    border: 1px solid #ddd;
  }
  .searchBox .kenlist .kenTtl {
    font-size: 1.7rem;
    color: #333;
  }
  .searchBox .kenlist .kenName {
    margin: 0 0 0 1.8rem;
    padding: 8px 0;
    float: none;
  }
  .searchBox .kenlist .kenName a, .searchBox .kenlist .kenName span {
    font-size: 1.6rem;
    color: #444;
  }
  .searchBox .kenlist__block {
    float: none;
    width: 100%;
  }
  .searchBox .kenlist__item {
    margin-bottom: 10px;
  }
  .searchBox .naiyolist {
    max-height: calc(50vh - 55px);
    overflow-y: auto;
    width: calc(100% - 56px);
    position: absolute;
    top: 55px;
    right: 0;
    left: inherit;
    transform: inherit;
    border: 1px solid #ddd;
  }
  .searchBox .naiyolist__item {
    width: 100%;
    height: inherit;
    border: none;
    float: none;
    text-align: left;
  }
  .searchBox .naiyolist__item span {
    font-size: 1.6rem;
    color: #444;
    padding: 8px 0;
  }
  .searchBox .naiyolist__item span .icon {
    display: none;
  }
  .searchBox .naiyolist .other {
    display: none;
  }
}

/*=================================
  pager
=================================*/
.pager {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.pagerList {
  display: flex;
}

.pagerList__item {
  margin-right: 10px;
}

.pagerList__item a, .pagerList__item span {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  padding: 15px 12px;
  display: block;
}

.pagerList__item a {
  color: #333;
  border-bottom: 4px solid #ccc;
  text-decoration: none;
}

@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #005bac;
    border-color: #337cbd;
  }
}

.pagerList__item span {
  color: #005bac;
  border-bottom: 4px solid #337cbd;
}

.pager .output {
  font-size: 1.8rem;
}

.pager .output .num {
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item a, .pagerList__item span {
    font-size: 1.8rem;
    padding: 12px 10px;
  }
  .pager .output {
    display: none;
  }
}

/*=================================
  topPage
=================================*/
#topPage {
  /* 都道府県 */
  /* 主要都市 */
  /* 都道府県一覧 */
  /* 厳選事務所 */
  /* 新着 */
}

#topPage .searchBox {
  padding: 30px 3%;
}

#topPage .searchBox__inner {
  margin: 0 auto;
  display: block;
}

#topPage .searchBox .officeNum {
  display: block;
}

#topPage .secWrap {
  padding: 50px 1%;
}

#topPage .secWrap .topTtl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #222;
  text-align: center;
  margin-bottom: 44px;
}

#topPage .secWrap .topTtl.white {
  color: #fff;
}

#topPage .secWrap__inner {
  max-width: 1160px;
  margin: 0 auto;
}

#topPage .secWrap.white {
  background: #fff;
}

#topPage .secWrap.gray {
  background: #eee;
}

#topPage .layout--2col {
  padding-top: 50px;
}

#topPage .meritList {
  display: flex;
}

#topPage .meritList__item {
  background: #fff;
  width: calc((100% - 4px) / 3);
  padding: 138px 2% 60px;
}

#topPage .meritList__item .meritTtl {
  font-size: 2rem;
  font-weight: 500;
  color: #005bac;
  text-align: center;
  margin-bottom: 20px;
}

#topPage .meritList__item .meritText {
  line-height: 1.5;
}

#topPage .meritList__item:not(:last-child) {
  margin-right: 2px;
}

#topPage .meritList__item:nth-child(1) {
  background: #fff url(../img/icon/ico_mer01.png) no-repeat center top 50px;
}

#topPage .meritList__item:nth-child(1) .meritTtl {
  padding-top: 60px;
  position: relative;
}

#topPage .meritList__item:nth-child(1) .meritTtl::before {
  content: "メリット1";
  font-size: 1.4rem;
  color: #333;
  background: #eee;
  border-radius: 50px;
  padding: 8px 23px;
  position: absolute;
  top: 0;
  left: 0;
}

#topPage .meritList__item:nth-child(2) {
  background: #fff url(../img/icon/ico_mer02.png) no-repeat center top 50px;
}

#topPage .meritList__item:nth-child(2) .meritTtl {
  padding-top: 60px;
  position: relative;
}

#topPage .meritList__item:nth-child(2) .meritTtl::before {
  content: "メリット2";
  font-size: 1.4rem;
  color: #333;
  background: #eee;
  border-radius: 50px;
  padding: 8px 23px;
  position: absolute;
  top: 0;
  left: 0;
}

#topPage .meritList__item:nth-child(3) {
  background: #fff url(../img/icon/ico_mer03.png) no-repeat center top 50px;
}

#topPage .meritList__item:nth-child(3) .meritTtl {
  padding-top: 60px;
  position: relative;
}

#topPage .meritList__item:nth-child(3) .meritTtl::before {
  content: "メリット3";
  font-size: 1.4rem;
  color: #333;
  background: #eee;
  border-radius: 50px;
  padding: 8px 23px;
  position: absolute;
  top: 0;
  left: 0;
}

#topPage .prefMajorList {
  display: flex;
}

#topPage .prefMajorList__item {
  text-align: center;
  width: calc((100% - 5.2%) / 5);
  min-width: 120px;
  margin-bottom: 20px;
}

#topPage .prefMajorList__item a {
  font-size: 2.4rem;
  color: #fff;
  text-decoration: none;
  padding: 19% 0;
  display: block;
}

#topPage .prefMajorList__item a .ruby {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  display: block;
}

@media screen and (min-width: 767px) {
  #topPage .prefMajorList__item a:hover {
    opacity: .9;
  }
}

#topPage .prefMajorList__item:not(:nth-child(5n + 1)) {
  margin-left: 1.3%;
}

#topPage .prefMajorList__item.tokyo a {
  background: url(../img/pref_tokyo.png) no-repeat center center;
  background-size: cover;
}

#topPage .prefMajorList__item.osaka a {
  background: url(../img/pref_osaka.png) no-repeat center center;
  background-size: cover;
}

#topPage .prefMajorList__item.aichi a {
  background: url(../img/pref_aichi.png) no-repeat center center;
  background-size: cover;
}

#topPage .prefMajorList__item.hokkaido a {
  background: url(../img/pref_hokkaido.png) no-repeat center center;
  background-size: cover;
}

#topPage .prefMajorList__item.fukuoka a {
  background: url(../img/pref_fukuoka.png) no-repeat center center;
  background-size: cover;
}

#topPage .prefWrap {
  display: flex;
  flex-wrap: wrap;
}

#topPage .prefWrap__block {
  width: calc((100% - 5.2%) / 5);
  margin-bottom: 20px;
}

#topPage .prefWrap__block .prefTtl {
  color: #005bac;
  margin-bottom: 10px;
}

#topPage .prefWrap__block .prefList {
  display: flex;
  flex-wrap: wrap;
}

#topPage .prefWrap__block .prefList__item {
  margin: 0 10px 12px 0;
  line-height: 1;
}

#topPage .prefWrap__block .prefList__item a {
  font-size: 1.4rem;
  color: #222;
}

#topPage .prefWrap__block:not(:nth-child(5n + 1)) {
  margin-left: 1.3%;
}

#topPage .pickUpOffice {
  background: #77b8f2 url(../img/bg_pickup_office.png) no-repeat center top;
}

#topPage .pickUpOffice .tabList__ttl {
  font-size: 2rem;
  font-weight: 500;
  background: #f3f3f3;
  padding: 27px 32px;
  position: relative;
}

#topPage .pickUpOffice .tabList__ttl .date {
  color: #005bac;
  margin-right: 2rem;
}

#topPage .pickUpOffice .tabList__ttl::after {
  content: "";
  width: calc(100% / 6);
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  bottom: 0;
  left: 0;
}

#topPage .pickUpOffice__list {
  border-top: 1px solid #ccc;
  background: #f3f3f3;
  display: flex;
}

#topPage .pickUpOffice__list__item {
  width: calc(100% / 4);
  min-height: 380px;
  padding: 15px 15px 25px;
  position: relative;
  background: #f3f3f3;
}

#topPage .pickUpOffice__list__item .thumb {
  margin-bottom: 20px;
}

#topPage .pickUpOffice__list__item .textWrap .name {
  display: block;
  text-align: center;
}

#topPage .pickUpOffice__list__item .textWrap .text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8571;
  margin-top: 15px;
}

#topPage .pickUpOffice__list__item .areaWrap {
  position: absolute;
  left: 15px;
  bottom: 25px;
}

#topPage .pickUpOffice__list__item .areaWrap::before {
  content: "";
  width: 14px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/ico_taiou.png) no-repeat center center;
  background-size: cover;
  vertical-align: middle;
}

#topPage .pickUpOffice__list__item .areaWrap .lavel, #topPage .pickUpOffice__list__item .areaWrap .area {
  font-size: 1.2rem;
  display: inline-block;
}

#topPage .pickUpOffice__list__item .areaWrap .area::before {
  content: "/";
  display: inline-block;
  margin: 0 .2rem 0 0;
}

#topPage .pickUpOffice__list__item:not(:first-child) {
  border-left: 1px solid #ccc;
}

@media screen and (min-width: 767px) {
  #topPage .pickUpOffice__list__item:hover {
    background: #fff;
    opacity: 1 !important;
  }
}

#topPage .newCont {
  background: linear-gradient(#333 30%, #fff 30%, #fff);
}

#topPage .newCont__wrap {
  display: flex;
  justify-content: space-between;
}

#topPage .newCont__ttl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #fff;
  margin-bottom: 50px;
}

#topPage .newCont__ttl .ruby {
  font-size: 1.4rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  margin-left: 2rem;
  vertical-align: middle;
}

#topPage .newCont__item {
  width: calc((100% - 60px) / 2);
}

#topPage .newCont__list {
  background: #fff;
  padding: 10px;
}

#topPage .newCont__list__item {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

#topPage .newCont__list__item .thumb {
  width: 120px;
  float: left;
  position: relative;
}

#topPage .newCont__list__item .thumb.new::before {
  content: "NEW";
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  background: rgba(232, 48, 33, 0.9);
  padding: 7px 16px 5px;
  position: absolute;
  top: 0;
  left: 0;
}

#topPage .newCont__list__item .textWrap {
  width: calc(100% - 120px);
  padding: 0 0 0 15px;
  float: right;
}

#topPage .newCont__list__item .textWrap__ttl {
  font-size: 1.6rem;
  font-weight: 500;
}

#topPage .newCont__list__item .textWrap__text {
  font-size: 1.4rem;
  line-height: 1.8571;
  color: #222;
}

#topPage .newCont__list__item .textWrap .info__tag {
  display: inline-block;
  margin-right: 5px;
}

#topPage .newCont__list__item .textWrap .info__tag__item {
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  padding: 4px 8px 5px;
  background: #333;
  border-radius: 2px;
  display: inline-block;
}

#topPage .newCont__list__item .textWrap .info__date {
  font-size: 1.2rem;
  display: inline-block;
  background: url(../img/icon/ico_cal.png) no-repeat left center;
  padding: 0 0 0 20px;
}

#topPage .articleCat {
  padding-top: 0;
  padding-bottom: 0;
}

#topPage .articleCat__wrap {
  display: flex;
  flex-wrap: wrap;
}

#topPage .articleCat__item {
  width: calc((100% - 80px) / 3);
  margin-bottom: 35px;
}

#topPage .articleCat__item:not(:nth-child(3n + 1)) {
  margin-left: 40px;
}

#topPage .articleCat__ttl {
  font-size: 2.6rem;
  font-weight: 300;
  padding: 36px 0;
  position: relative;
}

#topPage .articleCat__ttl::before {
  content: "";
  width: 80px;
  height: 4px;
  display: inline-block;
  background: #005bac;
  position: absolute;
  top: 0;
  left: 0;
}

#topPage .articleCat__list__item {
  background: #fff;
  padding: 15px 10px;
}

#topPage .articleCat__list__item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

#topPage .articleCat__list__item .thumb {
  width: 80px;
  float: left;
}

#topPage .articleCat__list__item .textWrap {
  width: calc(100% - 80px);
  padding: 0 0 0 12px;
  float: right;
}

#topPage .articleCat__list__item .textWrap__ttl {
  font-size: 1.4rem;
  font-weight: 500;
}

#topPage .articleCat__list__item .textWrap__view {
  font-size: 1.2rem;
  line-height: 1;
  background: url(../img/icon/ico_eye.png) no-repeat left center;
  padding-left: 22px;
  margin-top: 10px;
}

#topPage .articleCat .moreLink {
  text-align: center;
  margin-top: 30px;
}

#topPage .articleCat .moreLink a {
  font-size: 1.4rem;
  position: relative;
}

#topPage .articleCat .moreLink a::before {
  content: "";
  width: 6px;
  height: 6px;
  box-sizing: border-box;
  display: inline-block;
  margin-right: 4px;
  border: 4px solid transparent;
  border-left: 8px solid #005bac;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  #topPage {
    /* 都道府県 */
    /* 主要都市 */
    /* 都道府県一覧 */
    /* 厳選事務所 */
    /* 新着 */
  }
  #topPage .secWrap {
    padding: 30px 0;
  }
  #topPage .secWrap .topTtl {
    font-size: 2.2rem;
    margin-bottom: 30px;
  }
  #topPage .layout--2col {
    padding-top: 0;
  }
  #topPage .meritList {
    display: block;
    padding: 0 4.3%;
  }
  #topPage .meritList__item {
    width: 100%;
    padding: 50px 4.3% 25px;
  }
  #topPage .meritList__item .meritTtl {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
  #topPage .meritList__item:not(:last-child) {
    margin: 0 0 4.3%;
  }
  #topPage .meritList__item:nth-child(1) {
    background: #fff url(../img/icon/ico_mer01.png) no-repeat center top 30px;
    background-size: 50px;
  }
  #topPage .meritList__item:nth-child(1) .meritTtl {
    padding-top: 50px;
  }
  #topPage .meritList__item:nth-child(1) .meritTtl::before {
    font-size: 1.2rem;
    padding: 6px 18px;
  }
  #topPage .meritList__item:nth-child(2) {
    background: #fff url(../img/icon/ico_mer02.png) no-repeat center top 30px;
    background-size: 50px;
  }
  #topPage .meritList__item:nth-child(2) .meritTtl {
    padding-top: 50px;
  }
  #topPage .meritList__item:nth-child(2) .meritTtl::before {
    font-size: 1.2rem;
    padding: 6px 18px;
  }
  #topPage .meritList__item:nth-child(3) {
    background: #fff url(../img/icon/ico_mer03.png) no-repeat center top 30px;
    background-size: 50px;
  }
  #topPage .meritList__item:nth-child(3) .meritTtl {
    padding-top: 50px;
  }
  #topPage .meritList__item:nth-child(3) .meritTtl::before {
    font-size: 1.2rem;
    padding: 6px 18px;
  }
  #topPage .prefMajor {
    display: none;
  }
  #topPage .prefMajorList {
    flex-wrap: wrap;
    justify-content: center;
  }
  #topPage .prefMajorList__item {
    margin-bottom: 5px;
  }
  #topPage .prefMajorList__item a {
    font-size: 1.8rem;
  }
  #topPage .prefMajorList__item a .ruby {
    font-size: 1.1rem;
  }
  #topPage .prefMajorList__item:not(:nth-child(5n + 1)) {
    margin-left: 5px;
  }
  #topPage .pref {
    padding: 0 4.3%;
  }
  #topPage .prefWrap {
    display: block;
  }
  #topPage .prefWrap__block {
    width: 100%;
    margin-bottom: 18px;
  }
  #topPage .prefWrap__block .prefTtl {
    font-size: 1.6rem;
    font-weight: 500;
  }
  #topPage .prefWrap__block:not(:nth-child(5n + 1)) {
    margin-left: 0;
  }
  #topPage .pickUpOffice {
    padding-left: 3%;
    padding-right: 3%;
  }
  #topPage .pickUpOffice .tabList__ttl {
    font-size: 1.6rem;
    padding: 10px 15px 15px;
  }
  #topPage .pickUpOffice .tabList__ttl .date {
    display: block;
    margin: 0 0 5px 0;
  }
  #topPage .pickUpOffice .tabList__ttl::after {
    width: 100px;
    height: 3px;
  }
  #topPage .pickUpOffice__list {
    display: block;
  }
  #topPage .pickUpOffice__list__item {
    width: 100%;
    min-height: inherit;
    padding: 15px 15px 55px;
  }
  #topPage .pickUpOffice__list__item .thumb {
    margin-bottom: 15px;
    margin: 0 auto;
    text-align: center;
  }
  #topPage .pickUpOffice__list__item .textWrap .text {
    margin-top: 10px;
  }
  #topPage .pickUpOffice__list__item .areaWrap {
    bottom: 15px;
  }
  #topPage .pickUpOffice__list__item:not(:first-child) {
    border-left: none;
    border-top: 1px solid #ccc;
  }
  #topPage .newCont {
    background: #333;
    padding-left: 3%;
    padding-right: 3%;
  }
  #topPage .newCont__wrap {
    display: block;
  }
  #topPage .newCont__ttl {
    font-size: 2.2rem;
    margin-bottom: 30px;
  }
  #topPage .newCont__ttl .ruby {
    font-size: 1.2rem;
    margin-left: 1.5rem;
  }
  #topPage .newCont__item {
    width: 100%;
  }
  #topPage .newCont__item + .newCont__item {
    margin-top: 30px;
  }
  #topPage .newCont__list {
    padding: 0;
  }
  #topPage .newCont__list__item {
    padding: 15px;
    margin-bottom: 0;
  }
  #topPage .newCont__list__item .thumb {
    width: 80px;
  }
  #topPage .newCont__list__item .thumb.new::before {
    font-size: 1.2rem;
    padding: 5px 6px 3px;
  }
  #topPage .newCont__list__item .textWrap {
    width: calc(100% - 80px);
    padding: 0 0 0 10px;
  }
  #topPage .newCont__list__item .textWrap__ttl {
    font-size: 1.4rem;
  }
  #topPage .newCont__list__item .textWrap__text {
    line-height: 1.2142;
    margin-top: 3px;
  }
  #topPage .newCont__list__item .textWrap .info {
    margin-top: 10px;
  }
  #topPage .newCont__list__item .textWrap .info__tag {
    margin-right: 4px;
  }
  #topPage .newCont__list__item .textWrap .info__tag__item {
    font-size: 1.1rem;
    padding: 4px 4px 5px;
  }
  #topPage .newCont__list__item .textWrap .info__date {
    font-size: 1.1rem;
  }
  #topPage .articleCat {
    padding: 30px 3%;
  }
  #topPage .articleCat__wrap {
    display: block;
  }
  #topPage .articleCat__item {
    width: 100%;
    margin-bottom: 30px;
  }
  #topPage .articleCat__item:not(:nth-child(3n + 1)) {
    margin-left: 0;
  }
  #topPage .articleCat__ttl {
    font-size: 2rem;
    padding: 4px 15px 8px;
    margin-bottom: 12px;
  }
  #topPage .articleCat__ttl::before {
    width: 5px;
    height: 100%;
  }
  #topPage .articleCat__list__item {
    background: #fff;
    padding: 15px 10px;
    border-bottom: 1px solid #ddd;
  }
  #topPage .articleCat__list__item .textWrap {
    padding: 0 0 0 10px;
  }
  #topPage .articleCat__list__item .textWrap__view {
    font-size: 1.1rem;
    padding-left: 18px;
  }
  #topPage .articleCat .moreLink {
    margin-top: 25px;
  }
}

/*=================================
  下層ページ共通
=================================*/
/* 記事一覧 */
.articleList--simple {
  display: flex;
  flex-wrap: wrap;
}

.articleList--simple .articleList__item {
  width: calc((100% - 80px) / 3);
  margin-bottom: 40px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .articleList--simple .articleList__item {
    width: calc((100% - 20px) / 2);
  }
  .articleList--simple .articleList__item:nth-child(2n) {
    margin-left: 20px !important;
  }
}

.articleList--simple .articleList__item:not(:nth-child(3n + 1)) {
  margin-left: 40px;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .articleList--simple .articleList__item:not(:nth-child(3n + 1)):not(:nth-child(3n + 1)) {
    margin-left: inherit;
  }
}

.articleList--simple .articleList__item .itemWrap {
  position: relative;
}

.articleList--simple .articleList__item .itemWrap .thumb img {
  width: 100%;
}

.articleList--simple .articleList__item .itemWrap .textWrap {
  color: #fff;
  padding: 15px;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.articleList--simple .articleList__item .itemWrap .textWrap__ttl {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  color: #fff;
  display: block;
}

.articleList--simple .articleList__item .itemWrap .textWrap .info {
  font-size: 1.2rem;
  margin-top: 16px;
  overflow: hidden;
}

.articleList--simple .articleList__item .itemWrap .textWrap .info__date {
  float: left;
  padding: 3px 0 3px 20px;
  background: url(../img/icon/ico_cal_w.png) no-repeat left center;
}

.articleList--simple .articleList__item .itemWrap .textWrap .info__view {
  float: right;
  padding: 3px 0 3px 20px;
  background: url(../img/icon/ico_eye_w.png) no-repeat left center;
}

@media screen and (min-width: 767px) {
  .articleList--simple .articleList__item.widelink:hover .textWrap {
    background: rgba(0, 91, 172, 0.8);
  }
}

.articleList--col2 .articleList__item {
  width: 100%;
  margin-bottom: 30px;
}

.articleList--col2 .articleList__item .itemWrap {
  display: flex;
}

.articleList--col2 .articleList__item .itemWrap .thumb {
  width: 360px;
}

.articleList--col2 .articleList__item .itemWrap .thumb img {
  width: 100%;
}

.articleList--col2 .articleList__item .itemWrap .textWrap {
  width: calc(100% - 360px);
  color: #fff;
  padding: 15px 25px;
  background: #333;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.articleList--col2 .articleList__item .itemWrap .textWrap__ttl {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.625;
  color: #fff;
  display: block;
}

.articleList--col2 .articleList__item .itemWrap .textWrap__text {
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 10px;
}

.articleList--col2 .articleList__item .itemWrap .textWrap .tagList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.articleList--col2 .articleList__item .itemWrap .textWrap .tagList__item {
  font-size: 1.2rem;
  color: #fff;
  border: 1px solid #fff;
  padding: 3px 4px;
}

.articleList--col2 .articleList__item .itemWrap .textWrap .tagList__item + .tagList__item {
  margin-left: 6px;
}

.articleList--col2 .articleList__item .itemWrap .textWrap .info {
  font-size: 1.2rem;
  margin-top: 10px;
  overflow: hidden;
}

.articleList--col2 .articleList__item .itemWrap .textWrap .info__date {
  float: left;
  padding: 3px 0 3px 20px;
  background: url(../img/icon/ico_cal_w.png) no-repeat left center;
}

.articleList--col2 .articleList__item .itemWrap .textWrap .info__view {
  float: right;
  padding: 3px 0 3px 20px;
  background: url(../img/icon/ico_eye_w.png) no-repeat left center;
}

@media screen and (min-width: 767px) {
  .articleList--col2 .articleList__item.widelink:hover .textWrap {
    background: #005bac;
  }
}

/* 弁護士を探す */
.pushBox {
  margin: 60px 0 0;
}

.pushBox__ttl {
  font-size: 2.8rem;
  line-height: 1.4;
  color: #fff;
  padding: 68px 30px;
  background: url(../img/img_sec02.png) no-repeat;
  background-size: cover;
}

.pushBox__list {
  padding: 30px 30px 10px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  background: #eee;
}

.pushBox__list__item {
  font-size: 1.8rem;
  font-weight: 500;
  color: #e50000;
  margin-bottom: 20px;
  padding: 0 0 0 30px;
  background: url(../img/icon/ico_chk02.png) no-repeat left center;
}

.pushBox .band {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  padding: 25px 0;
  background: #f14537;
}

.pushBox .pref {
  color: #fff;
  background: #333;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pushBox .pref a {
  color: #fff;
}

.pushBox .pref__block {
  width: calc((100% - 40px) / 2);
}

.pushBox .prefTtl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 7px;
}

.pushBox .prefWrap {
  margin-bottom: 10px;
}

.pushBox .prefList__item {
  display: inline-block;
  margin: 0 10px 10px 0;
}

.pushBox .pref .pushBtn {
  margin-top: 20px;
  width: 100%;
}

.pushBox .pref .pushBtn a {
  display: block;
  padding: 15px 0;
  border-radius: 6px;
  border: 3px solid #fff;
  color: #fff;
  text-align: center;
}

.pushBox .pref .pushBtn a::before {
  display: inline-block;
  content: "";
  margin-right: 4px;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 4px solid transparent;
  border-left: 8px solid #fff;
}

@media screen and (min-width: 767px) {
  .pushBox .pref .pushBtn a:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

/* 関連記事 */
.relatedBox__ttl {
  font-size: 2.4rem;
  margin-top: 54px;
  padding: 40px 20px;
  border-top: 4px solid #999;
  font-weight: 500;
  line-height: 1.2272;
  position: relative;
}

.relatedBox__ttl::before {
  content: "";
  width: 28%;
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  top: -4px;
  left: 0;
}

.relatedBox .articleList__item {
  width: calc((100% - 40px) / 2);
}

.relatedBox .articleList__item:not(:nth-child(3n + 1)) {
  margin-left: inherit;
}

.relatedBox .articleList__item:nth-child(2n) {
  margin-left: 40px;
}

/* 事案絞り込み */
.caseSearch {
  text-align: center;
  margin-bottom: 45px;
}

.caseSearch .caseSwitch {
  margin: 20px auto;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  display: inline-block;
  border-radius: 50px;
  background: #337cbd;
  padding: 1px 10px;
  position: relative;
}

.caseSearch .caseSwitch::before {
  content: "";
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 4px solid transparent;
  border-left: 8px solid #fff;
  display: inline-block;
  vertical-align: middle;
  margin: -3px 1px 0 0;
}

.caseSearch .caseSwitch.open::before {
  transform: rotate(-90deg);
  margin-top: -5px;
}

.caseSearch .caseList {
  padding: 30px 15px;
  background: #f6f6f6;
  border: 1px solid #ddd;
  text-align: left;
}

.caseSearch .caseList__item {
  display: inline-block;
  margin: 0 15px;
}

.caseSearch .caseList__item a {
  font-size: 1.6rem;
  color: #333;
  text-decoration: none;
  padding: 4px 0 5px 15px;
  position: relative;
}

.caseSearch .caseList__item a::before {
  content: "";
  display: inline-block;
  border-width: 5px 0 5px 8px;
  border-style: solid;
  border-color: transparent transparent transparent #005bac;
  position: absolute;
  top: 12px;
  left: 0;
}

@media screen and (max-width: 1024px) {
  .articleList--col2 .articleList__item {
    margin-bottom: 15px;
  }
  .articleList--col2 .articleList__item .itemWrap {
    display: block;
    position: relative;
  }
  .articleList--col2 .articleList__item .itemWrap .thumb {
    width: 100%;
  }
  .articleList--col2 .articleList__item .itemWrap .textWrap {
    width: 100%;
    height: 100%;
    padding: 20px 5%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .articleList--col2 .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .articleList--col2 .articleList__item .itemWrap .textWrap__text {
    font-size: 1.3rem;
    margin-top: 8px;
  }
  .articleList--col2 .articleList__item .itemWrap .textWrap .tagList {
    margin-top: 10px;
  }
  .articleList--col2 .articleList__item .itemWrap .textWrap .tagList__item + .tagList__item {
    margin-left: 4px;
  }
  .articleList--col2 .articleList__item .itemWrap .textWrap .info {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    下層ページ共通
  =================================*/
  .articleList--simple {
    display: block;
  }
  .articleList--simple .articleList__item {
    width: 100%;
    margin-bottom: 15px;
  }
  .articleList--simple .articleList__item:not(:nth-child(3n + 1)) {
    margin-left: 0;
  }
  .articleList--simple .articleList__item .itemWrap .textWrap .info {
    margin-top: 5px;
  }
  /* 弁護士を探す */
  .pushBox {
    margin: 40px 0 0;
  }
  .pushBox__ttl {
    font-size: 1.8rem;
    padding: 12px 3%;
  }
  .pushBox__list {
    padding: 15px 15px 5px;
  }
  .pushBox__list__item {
    font-size: 1.4rem;
    margin-bottom: 15px;
    padding: 3px 0 3px 30px;
  }
  .pushBox .band {
    font-size: 1.6rem;
    padding: 20px 10px;
  }
  .pushBox .pref {
    padding: 15px 15px 25px;
    display: block;
  }
  .pushBox .pref__block {
    width: 100%;
  }
  .pushBox .prefTtl {
    font-size: 1.4rem;
  }
  .pushBox .prefWrap {
    margin-bottom: 15px;
  }
  .pushBox .prefList__item {
    display: inline-block;
    margin: 0 8px 5px 0;
  }
  .pushBox .pref .pushBtn {
    margin-top: 25px;
  }
  /* 関連記事 */
  .relatedBox {
    padding: 0 3%;
  }
  .relatedBox__ttl {
    font-size: 1.8rem;
    margin-top: 40px;
    padding: 20px 3% 20px;
  }
  .relatedBox__ttl::before {
    width: 130px;
  }
  .relatedBox .articleList__item {
    width: 100%;
  }
  .relatedBox .articleList__item:nth-child(2n) {
    margin-left: inherit;
  }
  /* 事案絞り込み */
  .caseSearch {
    margin-bottom: 30px;
  }
  .caseSearch .caseSwitch {
    margin: 15px auto;
    font-size: 1.5rem;
    padding: 2px 8px;
  }
  .caseSearch .caseSwitch::before {
    width: 5px;
    height: 5px;
    border: 3px solid transparent;
    border-left: 7px solid #fff;
  }
  .caseSearch .caseList {
    padding: 15px 3%;
  }
  .caseSearch .caseList__item {
    margin: 0 5px;
  }
  .caseSearch .caseList__item a {
    font-size: 1.4rem;
    padding: 2px 0 3px 12px;
  }
  .caseSearch .caseList__item a::before {
    border-width: 4px 0 4px 6px;
    top: 8px;
  }
}

/*=================================
  page--article
=================================*/
/* singlePage */
.singlePage__in > p {
  font-size: 1.6rem;
  line-height: 1.875;
  margin-top: 30px;
}

.singlePage__in > h1 {
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.singlePage__in > h2 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2272;
  padding: 40px 16px;
  background: #f6f6f6;
  margin-top: 54px;
  margin-bottom: 30px;
  border-top: 4px solid #999;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.singlePage__in > h2::before {
  content: "";
  width: 28%;
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  top: -4px;
  left: 0;
}

.singlePage__in > h3 {
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  background: #f6f6f6;
  padding: 18px;
  border: 1px solid #ddd;
  margin-top: 35px;
  margin-bottom: 24px;
  position: relative;
}

.singlePage__in > h3::before {
  content: "";
  width: 4px;
  height: calc(100% + 2px);
  display: block;
  background: #005bac;
  position: absolute;
  top: -1px;
  left: -1px;
}

.singlePage__in > h4 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #333;
  padding: 0 0 26px;
  margin-top: 35px;
  margin-bottom: 25px;
  border-bottom: 2px solid #ddd;
  position: relative;
}

.singlePage__in > h4::before {
  content: "";
  width: 28%;
  height: 2px;
  display: block;
  background: #005bac;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.singlePage__in > h5 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  background: #fff;
  padding: 8px 4px 12px;
  border-bottom: 1px solid #ddd;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}

.singlePage__in > h5::before {
  content: "";
  width: 4px;
  height: calc(100% + 2px);
  display: block;
  background: #005bac;
  position: absolute;
  top: -1px;
  left: -1px;
}

.singlePage__in > h6 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.singlePage__in > h6::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #005bac;
  vertical-align: middle;
  margin-right: .8rem;
  margin-top: -2px;
}

.singlePage__in > blockquote {
  position: relative;
  padding: 40px 35px;
  margin: 30px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 3px solid #ddd;
}

.singlePage__in > blockquote::before {
  content: "“";
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  color: #337cbd;
  display: inline-block;
  font-style: normal;
  width: 80px;
  height: 50px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 0;
}

.singlePage__in > blockquote h4, .singlePage__in > blockquote h5, .singlePage__in > blockquote h6 {
  margin: 0 0 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: .06em;
  position: relative;
}

.singlePage__in > blockquote h4::after, .singlePage__in > blockquote h5::after, .singlePage__in > blockquote h6::after {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 10px;
  content: '';
  background-image: repeating-linear-gradient(-45deg, #337cbd, #337cbd 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.singlePage__in > blockquote p {
  font-style: normal;
}

.singlePage__in > blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.singlePage__in > img {
  display: block;
  margin: 15px auto 20px;
}

.singlePage__in > ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 54px;
  padding: 20px 30px;
  background: #f6f6f6;
  border: 1px solid #ddd;
}

.singlePage__in > ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 22px;
  position: relative;
}

.singlePage__in > ul:not([class]) li::before {
  content: "";
  display: inline-block;
  border-width: 5px 0 5px 8px;
  border-style: solid;
  border-color: transparent transparent transparent #005bac;
  position: absolute;
  top: 12px;
  left: 0;
}

.singlePage__in > ul:not([class]) li + li {
  margin-top: 15px;
}

.singlePage__in > ol:not([class]) {
  margin-top: 30px;
  margin-bottom: 54px;
  padding: 20px 25px;
  background: #f6f6f6;
  border: 1px solid #ddd;
  counter-reset: listNum;
}

.singlePage__in > ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 34px;
  position: relative;
}

.singlePage__in > ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  background: #337cbd;
  border-radius: 50%;
  padding: 3px 8px 4px 8px;
  position: absolute;
  top: 2px;
  left: 0;
}

.singlePage__in > ol:not([class]) li + li {
  margin-top: 15px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage__in > p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 15px;
  }
  .singlePage__in > h1 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .singlePage__in > h2 {
    font-size: 1.8rem;
    line-height: 1.38888;
    padding: 15px 10px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .singlePage__in > h3 {
    font-size: 1.6rem;
    padding: 15px 10px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .singlePage__in > h4 {
    font-size: 1.5rem;
    padding: 0 0 12px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .singlePage__in > h5 {
    padding: 6px 12px 8px;
    margin-bottom: 15px;
  }
  .singlePage__in > h6 {
    margin-bottom: 15px;
  }
  .singlePage__in > h6::before {
    width: 8px;
    height: 8px;
    margin-right: .6rem;
  }
  .singlePage__in > blockquote {
    padding: 25px 3% 20px;
    margin: 20px 0;
  }
  .singlePage__in > blockquote::before {
    font-size: 6rem;
    width: 50px;
    top: -22px;
  }
  .singlePage__in > blockquote h4, .singlePage__in > blockquote h5, .singlePage__in > blockquote h6 {
    margin: 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .singlePage__in > blockquote h4::after, .singlePage__in > blockquote h5::after, .singlePage__in > blockquote h6::after {
    height: 7px;
  }
  .singlePage__in > img {
    display: block;
    margin: 15px auto;
  }
  .singlePage__in > ul:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .singlePage__in > ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 15px;
  }
  .singlePage__in > ul:not([class]) li::before {
    border-width: 5px 0 5px 6px;
    top: 7px;
  }
  .singlePage__in > ul:not([class]) li + li {
    margin-top: 10px;
  }
  .singlePage__in > ol:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .singlePage__in > ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .singlePage__in > ol:not([class]) li::before {
    font-size: 1.4rem;
    padding: 3px 6px 3px 6px;
  }
  .singlePage__in > ol:not([class]) li + li {
    margin-top: 10px;
  }
}

.singlePage .tocBox {
  margin: 40px 0 20px;
}

.singlePage .tocBox__ttl {
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  padding: 12px 0;
}

.singlePage .tocBox__ttl .toc_toggle {
  font-size: 1.2rem;
  font-weight: normal;
  color: #999;
  margin: 0 0 0 18px;
  vertical-align: middle;
  cursor: pointer;
}

.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}

.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
  padding: 18px 16px 18px 60px;
  position: relative;
  border-bottom: 1px solid #e2e2e2;
}

.singlePage .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: counter(tocNum);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4rem;
  color: #fff;
  line-height: 22px;
  width: 22px;
  text-align: center;
  text-decoration: none;
  background: #337cbd;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 23px;
}

.singlePage .infoWrap {
  margin-bottom: 30px;
}

.singlePage .infoWrap .info--left {
  float: left;
}

.singlePage .infoWrap .info--left .data {
  display: flex;
}

.singlePage .infoWrap .info--left .data .date {
  font-size: 1.8rem;
  font-weight: 500;
  color: #005bac;
  padding-left: 20px;
  background: url(../img/icon/ico_cal.png) no-repeat left center;
}

.singlePage .infoWrap .info--left .data .date + .date {
  margin-left: 1rem;
  background: url(../img/icon/ico_sea02.png) no-repeat left center;
  background-size: 16px auto;
}

.singlePage .infoWrap .info--left .data .view {
  font-size: 1.4rem;
  background: url(../img/icon/ico_eye.png) no-repeat left center;
  padding-left: 18px;
  margin-left: 2rem;
}

.singlePage .infoWrap .info--left .data .view .num {
  font-size: 1.8rem;
  font-weight: 500;
  color: #005bac;
  margin-right: 3px;
}

.singlePage .infoWrap .info--right {
  float: right;
  display: flex;
}

.singlePage .infoWrap .info--right .list--cat {
  display: flex;
  background: url(../img/icon/ico_fol.png) no-repeat left center;
  padding-left: 22px;
}

.singlePage .infoWrap .info--right .list--tag {
  display: flex;
  background: url(../img/icon/ico_tag.png) no-repeat left center;
  padding-left: 22px;
  margin-left: 1rem;
}

.singlePage .infoWrap .info--right a {
  font-size: 1.4rem;
  color: #333;
}

.singlePage .articleMv {
  margin: 20px auto;
  text-align: center;
}

.singlePage .snsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.singlePage .snsWrap::before {
  content: "この記事をシェアする";
  font-size: 1.4rem;
  line-height: 1;
  margin: -10px 1rem 0 0;
}

.singlePage .snsList {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.singlePage .snsList li {
  line-height: 1;
  margin-left: 5px;
}

.singlePage .snsList li.fb {
  margin-right: -5px;
}

.singlePage .understand {
  margin: 60px auto 30px;
}

.singlePage .understand__ttl {
  font-weight: 500;
  color: #fff;
  text-align: center;
  background: #337cbd;
  padding: 12px 15px;
  position: relative;
}

.singlePage .understand__ttl::before {
  content: "";
  width: 25px;
  height: 22px;
  margin-right: 6px;
  display: inline-block;
  background: url(../img/icon/ico_kiji.png) no-repeat;
  vertical-align: middle;
}

.singlePage .understand__cont {
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-top: none;
  padding: 25px;
}

.singlePage .understand__list__item {
  font-size: 1.8rem;
  padding: 8px 0;
  position: relative;
}

.singlePage .understand__list__item::before {
  content: "";
  width: 21px;
  height: 21px;
  margin-right: 10px;
  display: inline-block;
  background: url(../img/icon/ico_chk01.png) no-repeat;
  vertical-align: middle;
}

.singlePage .understand__text {
  margin-top: 20px;
}

.singlePage .link-bx {
  border: 1px solid #aecbe5;
  margin: 40px auto 20px;
  position: relative;
  z-index: 0;
}

.singlePage .link-bx a {
  padding: 16px 20px;
  background: #e9f5ff;
  display: block;
}

@media screen and (min-width: 767px) {
  .singlePage .link-bx a:hover {
    background: #fff;
  }
}

.singlePage .link-bx::before {
  content: "関連記事";
  color: #fff;
  padding: 5px 10px;
  background: #F30;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

.singlePage .point {
  color: #fff;
  text-align: center;
  background: #337cbd;
  margin: 40px auto 20px;
}

.singlePage .point-ttl {
  font-size: 2rem;
  padding: 20px 0;
  border-bottom: 1px solid #71a4d1;
}

.singlePage .point-ttl::before {
  content: "";
  width: 19px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
  background: url(../img/icon/ico_pnt.png) no-repeat;
  vertical-align: middle;
}

.singlePage .point-bx {
  padding: 20px;
  text-align: center;
}

.singlePage .check-bx {
  background: #fff5f5;
  border: 3px solid #ff8d8d;
  padding: 15px 30px;
  margin-top: 35px;
  margin-bottom: 54px;
}

.singlePage .check-bx li {
  font-weight: 600;
  color: #e50000;
  background: url(../img/icon/ico_chk02.png) no-repeat left center;
  padding: 8px 0 8px 34px;
}

.singlePage .related--simple {
  margin: 50px auto;
  border: 1px solid #ddd;
  position: relative;
}

.singlePage .related--simple__item a {
  padding: 20px 15px;
  display: block;
}

@media screen and (min-width: 767px) {
  .singlePage .related--simple__item a:hover {
    background: #337cbd;
    color: #fff;
  }
}

.singlePage .related--simple__item + .related--simple__item {
  border-top: 1px dotted #ddd;
}

.singlePage .related--simple::before {
  content: "一緒に読まれている記事";
  line-height: 1;
  color: #fff;
  background: #005bac;
  padding: 8px 10px;
  position: absolute;
  top: -15px;
  right: -1px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage .tocBox {
    margin: 30px 0 20px;
  }
  .singlePage .tocBox__ttl {
    font-size: 2rem;
    padding: 10px 0;
  }
  .singlePage .tocBox__ttl .toc_toggle {
    margin: 0 0 0 10px;
  }
  .singlePage .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 15px 3% 15px 40px;
  }
  .singlePage .tocBox .tocList__item a::before {
    top: 15px;
    left: 10px;
  }
  .singlePage .infoWrap {
    margin-bottom: 20px;
  }
  .singlePage .infoWrap .info--left {
    float: none;
  }
  .singlePage .infoWrap .info--left .data .date {
    font-size: 1.4rem;
  }
  .singlePage .infoWrap .info--left .data .date + .date {
    margin-left: .8rem;
  }
  .singlePage .infoWrap .info--left .data .view {
    font-size: 1.2rem;
    padding-left: 20px;
    margin-left: 1.5rem;
  }
  .singlePage .infoWrap .info--left .data .view .num {
    font-size: 1.4rem;
  }
  .singlePage .infoWrap .info--right {
    float: none;
    display: block;
    margin-top: 5px;
  }
  .singlePage .infoWrap .info--right .list--tag {
    margin-left: 0;
  }
  .singlePage .infoWrap .info--right a {
    font-size: 1.2rem;
  }
  .singlePage .snsWrap::before {
    content: none;
  }
  .singlePage .snsList li {
    margin-left: 3px;
  }
  .singlePage .understand {
    margin: 40px auto 20px;
  }
  .singlePage .understand__ttl {
    padding: 10px 3%;
  }
  .singlePage .understand__cont {
    padding: 15px;
  }
  .singlePage .understand__list__item {
    font-size: 1.4rem;
    padding: 5px 0 5px 30px;
    position: relative;
  }
  .singlePage .understand__list__item::before {
    margin-right: 0;
    position: absolute;
    top: 5px;
    left: 0;
  }
  .singlePage .understand__text {
    margin-top: 15px;
    padding: 0 3%;
  }
  .singlePage .link-bx {
    margin: 30px auto 15px;
  }
  .singlePage .link-bx a {
    padding: 20px 3% 15px;
  }
  .singlePage .link-bx::before {
    padding: 3px 5px;
    top: -10px;
    right: -5px;
  }
  .singlePage .point {
    margin: 30px auto 15px;
  }
  .singlePage .point-ttl {
    font-size: 1.4rem;
    padding: 15px 0 10px;
  }
  .singlePage .point-bx {
    padding: 15px;
    text-align: left;
  }
  .singlePage .check-bx {
    padding: 10px 3%;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .singlePage .check-bx li {
    padding: 5px 0 5px 32px;
  }
  .singlePage .related--simple {
    margin: 40px auto;
  }
  .singlePage .related--simple__item a {
    padding: 15px 3%;
  }
  .singlePage .related--simple::before {
    padding: 8px 6px;
    top: -20px;
  }
}

/* 記事詳細ページ */
.articleDetail__in > p {
  font-size: 1.6rem;
  line-height: 1.875;
  margin-top: 30px;
}

.articleDetail__in > h1 {
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.articleDetail__in > h2 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2272;
  padding: 40px 16px;
  background: #f6f6f6;
  margin-top: 54px;
  margin-bottom: 30px;
  border-top: 4px solid #999;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.articleDetail__in > h2::before {
  content: "";
  width: 28%;
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  top: -4px;
  left: 0;
}

.articleDetail__in > h3 {
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  background: #f6f6f6;
  padding: 18px;
  border: 1px solid #ddd;
  margin-top: 35px;
  margin-bottom: 24px;
  position: relative;
}

.articleDetail__in > h3::before {
  content: "";
  width: 4px;
  height: calc(100% + 2px);
  display: block;
  background: #005bac;
  position: absolute;
  top: -1px;
  left: -1px;
}

.articleDetail__in > h4 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #333;
  padding: 0 0 26px;
  margin-top: 35px;
  margin-bottom: 25px;
  border-bottom: 2px solid #ddd;
  position: relative;
}

.articleDetail__in > h4::before {
  content: "";
  width: 28%;
  height: 2px;
  display: block;
  background: #005bac;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.articleDetail__in > h5 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  background: #fff;
  padding: 8px 4px 12px;
  border-bottom: 1px solid #ddd;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}

.articleDetail__in > h5::before {
  content: "";
  width: 4px;
  height: calc(100% + 2px);
  display: block;
  background: #005bac;
  position: absolute;
  top: -1px;
  left: -1px;
}

.articleDetail__in > h6 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.articleDetail__in > h6::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #005bac;
  vertical-align: middle;
  margin-right: .8rem;
  margin-top: -2px;
}

.articleDetail__in > blockquote {
  position: relative;
  padding: 40px 35px;
  margin: 30px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 3px solid #ddd;
}

.articleDetail__in > blockquote::before {
  content: "“";
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  color: #337cbd;
  display: inline-block;
  font-style: normal;
  width: 80px;
  height: 50px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 0;
}

.articleDetail__in > blockquote h4, .articleDetail__in > blockquote h5, .articleDetail__in > blockquote h6 {
  margin: 0 0 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: .06em;
  position: relative;
}

.articleDetail__in > blockquote h4::after, .articleDetail__in > blockquote h5::after, .articleDetail__in > blockquote h6::after {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 10px;
  content: '';
  background-image: repeating-linear-gradient(-45deg, #337cbd, #337cbd 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.articleDetail__in > blockquote p {
  font-style: normal;
}

.articleDetail__in > blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.articleDetail__in > img {
  display: block;
  margin: 15px auto 20px;
}

.articleDetail__in > ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 54px;
  padding: 20px 30px;
  background: #f6f6f6;
  border: 1px solid #ddd;
}

.articleDetail__in > ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 22px;
  position: relative;
}

.articleDetail__in > ul:not([class]) li::before {
  content: "";
  display: inline-block;
  border-width: 5px 0 5px 8px;
  border-style: solid;
  border-color: transparent transparent transparent #005bac;
  position: absolute;
  top: 12px;
  left: 0;
}

.articleDetail__in > ul:not([class]) li + li {
  margin-top: 15px;
}

.articleDetail__in > ol:not([class]) {
  margin-top: 30px;
  margin-bottom: 54px;
  padding: 20px 25px;
  background: #f6f6f6;
  border: 1px solid #ddd;
  counter-reset: listNum;
}

.articleDetail__in > ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 34px;
  position: relative;
}

.articleDetail__in > ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  background: #337cbd;
  border-radius: 50%;
  padding: 3px 8px 4px 8px;
  position: absolute;
  top: 2px;
  left: 0;
}

.articleDetail__in > ol:not([class]) li + li {
  margin-top: 15px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail__in > p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 15px;
  }
  .articleDetail__in > h1 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .articleDetail__in > h2 {
    font-size: 1.8rem;
    line-height: 1.38888;
    padding: 15px 10px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .articleDetail__in > h3 {
    font-size: 1.6rem;
    padding: 15px 10px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .articleDetail__in > h4 {
    font-size: 1.5rem;
    padding: 0 0 12px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .articleDetail__in > h5 {
    padding: 6px 12px 8px;
    margin-bottom: 15px;
  }
  .articleDetail__in > h6 {
    margin-bottom: 15px;
  }
  .articleDetail__in > h6::before {
    width: 8px;
    height: 8px;
    margin-right: .6rem;
  }
  .articleDetail__in > blockquote {
    padding: 25px 3% 20px;
    margin: 20px 0;
  }
  .articleDetail__in > blockquote::before {
    font-size: 6rem;
    width: 50px;
    top: -22px;
  }
  .articleDetail__in > blockquote h4, .articleDetail__in > blockquote h5, .articleDetail__in > blockquote h6 {
    margin: 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .articleDetail__in > blockquote h4::after, .articleDetail__in > blockquote h5::after, .articleDetail__in > blockquote h6::after {
    height: 7px;
  }
  .articleDetail__in > img {
    display: block;
    margin: 15px auto;
  }
  .articleDetail__in > ul:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .articleDetail__in > ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 15px;
  }
  .articleDetail__in > ul:not([class]) li::before {
    border-width: 5px 0 5px 6px;
    top: 7px;
  }
  .articleDetail__in > ul:not([class]) li + li {
    margin-top: 10px;
  }
  .articleDetail__in > ol:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .articleDetail__in > ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .articleDetail__in > ol:not([class]) li::before {
    font-size: 1.4rem;
    padding: 3px 6px 3px 6px;
  }
  .articleDetail__in > ol:not([class]) li + li {
    margin-top: 10px;
  }
}

.articleDetail .tocBox {
  margin: 40px 0 20px;
}

.articleDetail .tocBox__ttl {
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  padding: 12px 0;
}

.articleDetail .tocBox__ttl .toc_toggle {
  font-size: 1.2rem;
  font-weight: normal;
  color: #999;
  margin: 0 0 0 18px;
  vertical-align: middle;
  cursor: pointer;
}

.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}

.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
  padding: 18px 16px 18px 60px;
  position: relative;
  border-bottom: 1px solid #e2e2e2;
}

.articleDetail .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: counter(tocNum);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4rem;
  color: #fff;
  line-height: 22px;
  width: 22px;
  text-align: center;
  text-decoration: none;
  background: #337cbd;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 23px;
}

.articleDetail .infoWrap {
  margin-bottom: 30px;
}

.articleDetail .infoWrap .info--left {
  float: left;
}

.articleDetail .infoWrap .info--left .data {
  display: flex;
}

.articleDetail .infoWrap .info--left .data .date {
  font-size: 1.8rem;
  font-weight: 500;
  color: #005bac;
  padding-left: 20px;
  background: url(../img/icon/ico_cal.png) no-repeat left center;
}

.articleDetail .infoWrap .info--left .data .date + .date {
  margin-left: 1rem;
  background: url(../img/icon/ico_sea02.png) no-repeat left center;
  background-size: 16px auto;
}

.articleDetail .infoWrap .info--left .data .view {
  font-size: 1.4rem;
  background: url(../img/icon/ico_eye.png) no-repeat left center;
  padding-left: 18px;
  margin-left: 2rem;
}

.articleDetail .infoWrap .info--left .data .view .num {
  font-size: 1.8rem;
  font-weight: 500;
  color: #005bac;
  margin-right: 3px;
}

.articleDetail .infoWrap .info--right {
  float: right;
  display: flex;
}

.articleDetail .infoWrap .info--right .list--cat {
  display: flex;
  background: url(../img/icon/ico_fol.png) no-repeat left center;
  padding-left: 22px;
}

.articleDetail .infoWrap .info--right .list--tag {
  display: flex;
  background: url(../img/icon/ico_tag.png) no-repeat left center;
  padding-left: 22px;
  margin-left: 1rem;
}

.articleDetail .infoWrap .info--right a {
  font-size: 1.4rem;
  color: #333;
}

.articleDetail .articleMv {
  margin: 20px auto;
  text-align: center;
}

.articleDetail .snsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.articleDetail .snsWrap::before {
  content: "この記事をシェアする";
  font-size: 1.4rem;
  line-height: 1;
  margin: -10px 1rem 0 0;
}

.articleDetail .snsList {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.articleDetail .snsList li {
  line-height: 1;
  margin-left: 5px;
}

.articleDetail .snsList li.fb {
  margin-right: -5px;
}

.articleDetail .understand {
  margin: 60px auto 30px;
}

.articleDetail .understand__ttl {
  font-weight: 500;
  color: #fff;
  text-align: center;
  background: #337cbd;
  padding: 12px 15px;
  position: relative;
}

.articleDetail .understand__ttl::before {
  content: "";
  width: 25px;
  height: 22px;
  margin-right: 6px;
  display: inline-block;
  background: url(../img/icon/ico_kiji.png) no-repeat;
  vertical-align: middle;
}

.articleDetail .understand__cont {
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-top: none;
  padding: 25px;
}

.articleDetail .understand__list__item {
  font-size: 1.8rem;
  padding: 8px 0;
  position: relative;
}

.articleDetail .understand__list__item::before {
  content: "";
  width: 21px;
  height: 21px;
  margin-right: 10px;
  display: inline-block;
  background: url(../img/icon/ico_chk01.png) no-repeat;
  vertical-align: middle;
}

.articleDetail .understand__text {
  margin-top: 20px;
}

.articleDetail .link-bx {
  border: 1px solid #aecbe5;
  margin: 40px auto 20px;
  position: relative;
  z-index: 0;
}

.articleDetail .link-bx a {
  padding: 16px 20px;
  background: #e9f5ff;
  display: block;
}

@media screen and (min-width: 767px) {
  .articleDetail .link-bx a:hover {
    background: #fff;
  }
}

.articleDetail .link-bx::before {
  content: "関連記事";
  color: #fff;
  padding: 5px 10px;
  background: #F30;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

.articleDetail .point {
  color: #fff;
  text-align: center;
  background: #337cbd;
  margin: 40px auto 20px;
}

.articleDetail .point-ttl {
  font-size: 2rem;
  padding: 20px 0;
  border-bottom: 1px solid #71a4d1;
}

.articleDetail .point-ttl::before {
  content: "";
  width: 19px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
  background: url(../img/icon/ico_pnt.png) no-repeat;
  vertical-align: middle;
}

.articleDetail .point-bx {
  padding: 20px;
  text-align: center;
}

.articleDetail .check-bx {
  background: #fff5f5;
  border: 3px solid #ff8d8d;
  padding: 15px 30px;
  margin-top: 35px;
  margin-bottom: 54px;
}

.articleDetail .check-bx li {
  font-weight: 600;
  color: #e50000;
  background: url(../img/icon/ico_chk02.png) no-repeat left center;
  padding: 8px 0 8px 34px;
}

.articleDetail .related--simple {
  margin: 50px auto;
  border: 1px solid #ddd;
  position: relative;
}

.articleDetail .related--simple__item a {
  padding: 20px 15px;
  display: block;
}

@media screen and (min-width: 767px) {
  .articleDetail .related--simple__item a:hover {
    background: #337cbd;
    color: #fff;
  }
}

.articleDetail .related--simple__item + .related--simple__item {
  border-top: 1px dotted #ddd;
}

.articleDetail .related--simple::before {
  content: "一緒に読まれている記事";
  line-height: 1;
  color: #fff;
  background: #005bac;
  padding: 8px 10px;
  position: absolute;
  top: -15px;
  right: -1px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail .tocBox {
    margin: 30px 0 20px;
  }
  .articleDetail .tocBox__ttl {
    font-size: 2rem;
    padding: 10px 0;
  }
  .articleDetail .tocBox__ttl .toc_toggle {
    margin: 0 0 0 10px;
  }
  .articleDetail .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 15px 3% 15px 40px;
  }
  .articleDetail .tocBox .tocList__item a::before {
    top: 15px;
    left: 10px;
  }
  .articleDetail .infoWrap {
    margin-bottom: 20px;
  }
  .articleDetail .infoWrap .info--left {
    float: none;
  }
  .articleDetail .infoWrap .info--left .data .date {
    font-size: 1.4rem;
  }
  .articleDetail .infoWrap .info--left .data .date + .date {
    margin-left: .8rem;
  }
  .articleDetail .infoWrap .info--left .data .view {
    font-size: 1.2rem;
    padding-left: 20px;
    margin-left: 1.5rem;
  }
  .articleDetail .infoWrap .info--left .data .view .num {
    font-size: 1.4rem;
  }
  .articleDetail .infoWrap .info--right {
    float: none;
    display: block;
    margin-top: 5px;
  }
  .articleDetail .infoWrap .info--right .list--tag {
    margin-left: 0;
  }
  .articleDetail .infoWrap .info--right a {
    font-size: 1.2rem;
  }
  .articleDetail .snsWrap::before {
    content: none;
  }
  .articleDetail .snsList li {
    margin-left: 3px;
  }
  .articleDetail .understand {
    margin: 40px auto 20px;
  }
  .articleDetail .understand__ttl {
    padding: 10px 3%;
  }
  .articleDetail .understand__cont {
    padding: 15px;
  }
  .articleDetail .understand__list__item {
    font-size: 1.4rem;
    padding: 5px 0 5px 30px;
    position: relative;
  }
  .articleDetail .understand__list__item::before {
    margin-right: 0;
    position: absolute;
    top: 5px;
    left: 0;
  }
  .articleDetail .understand__text {
    margin-top: 15px;
    padding: 0 3%;
  }
  .articleDetail .link-bx {
    margin: 30px auto 15px;
  }
  .articleDetail .link-bx a {
    padding: 20px 3% 15px;
  }
  .articleDetail .link-bx::before {
    padding: 3px 5px;
    top: -10px;
    right: -5px;
  }
  .articleDetail .point {
    margin: 30px auto 15px;
  }
  .articleDetail .point-ttl {
    font-size: 1.4rem;
    padding: 15px 0 10px;
  }
  .articleDetail .point-bx {
    padding: 15px;
    text-align: left;
  }
  .articleDetail .check-bx {
    padding: 10px 3%;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .articleDetail .check-bx li {
    padding: 5px 0 5px 32px;
  }
  .articleDetail .related--simple {
    margin: 40px auto;
  }
  .articleDetail .related--simple__item a {
    padding: 15px 3%;
  }
  .articleDetail .related--simple::before {
    padding: 8px 6px;
    top: -20px;
  }
}

/*=================================
  カテゴリーページ
=================================*/
.blackBox {
  background: #333;
  padding: 27px 1%;
}

.blackBox .btnWrap {
  margin: 0 auto;
}

.blackBox .btnWrap a {
  font-size: 2.6rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: #337cbd;
  box-shadow: 0 4px 0 #2667a1;
  border-radius: 5px;
  width: 100%;
  padding: 30px 0;
  line-height: 1;
  max-width: 1160px;
  text-decoration: none;
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;
}

.blackBox .btnWrap a:hover {
  box-shadow: none;
  transform: translateY(4px);
}

.catContWrap {
  background: #fff;
  padding: 60px 1%;
}

.catContWrap__inner {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.catContWrap .catContTtl {
  font-size: 2.6rem;
  font-weight: 500;
  color: #222;
  margin-bottom: 50px;
}

.catContWrap .merit {
  width: calc((100% - 60px) / 2);
}

.catContWrap .meritList__item {
  background: #fff;
  width: 100%;
  border-top: 1px solid #ccc;
  padding: 30px 5px 30px 50px;
}

.catContWrap .meritList__item:last-child {
  border-bottom: 1px solid #ccc;
}

.catContWrap .meritList__item .meritTtl {
  font-size: 2rem;
  font-weight: 500;
  color: #005bac;
  margin-bottom: 20px;
  position: relative;
}

.catContWrap .meritList__item .meritText {
  line-height: 1.5;
}

.catContWrap .meritList__item:not(:last-child) {
  margin-right: 2px;
}

.catContWrap .meritList__item:nth-child(1) .meritTtl::before {
  content: "";
  width: 35px;
  height: 35px;
  display: inline-block;
  background: #fff url(../img/icon/ico_mer01.png) no-repeat left top;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -50px;
}

.catContWrap .meritList__item:nth-child(2) .meritTtl::before {
  content: "";
  width: 35px;
  height: 35px;
  display: inline-block;
  background: #fff url(../img/icon/ico_mer02.png) no-repeat left top;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -50px;
}

.catContWrap .meritList__item:nth-child(3) .meritTtl::before {
  content: "";
  width: 35px;
  height: 35px;
  display: inline-block;
  background: #fff url(../img/icon/ico_mer03.png) no-repeat left top;
  background-size: contain;
  position: absolute;
  top: 0;
  left: -50px;
}

.catContWrap .pref {
  width: calc((100% - 60px) / 2);
}

.catContWrap .prefWrap__block {
  display: flex;
  align-items: center;
}

.catContWrap .prefWrap__block:nth-child(2n - 1) {
  background: #f4f4f4;
}

.catContWrap .prefTtl {
  font-size: 1.6rem;
  font-weight: 500;
  color: #005bac;
  width: 190px;
  padding: 20px 15px;
}

.catContWrap .prefList {
  width: calc(100% - 190px);
  padding: 20px 15px;
  line-height: 1.2;
}

.catContWrap .prefList__item {
  line-height: 1;
  display: inline-block;
  margin-right: 5px;
}

.catContWrap .prefList__item a {
  font-size: 1.4rem;
  font-weight: 400;
  color: #222;
  display: block;
}

.catContOffice {
  background: #77b8f2 url(../img/bg_pickup_office.png) no-repeat center top;
  padding: 50px 1%;
}

.catContOffice__inner {
  max-width: 1160px;
  margin: 0 auto;
}

.catContOffice .pickOfficeWrap__ttl {
  font-size: 2rem;
  font-weight: 500;
  background: #f3f3f3;
  padding: 27px 32px;
  position: relative;
}

.catContOffice .pickOfficeWrap__ttl .date {
  color: #005bac;
  margin-right: 2rem;
}

.catContOffice .pickOfficeWrap__ttl::after {
  content: "";
  width: calc(100% / 6);
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  bottom: 0;
  left: 0;
}

.catContOffice .pickOfficeWrap__list {
  border-top: 1px solid #ccc;
  background: #f3f3f3;
  display: flex;
}

.catContOffice .pickOfficeWrap__list__item {
  width: calc(100% / 4);
  min-height: 380px;
  padding: 15px 15px 25px;
  position: relative;
  background: #f3f3f3;
}

.catContOffice .pickOfficeWrap__list__item .thumb {
  margin-bottom: 20px;
}

.catContOffice .pickOfficeWrap__list__item .textWrap .name {
  display: block;
  text-align: center;
}

.catContOffice .pickOfficeWrap__list__item .textWrap .text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8571;
  margin-top: 15px;
}

.catContOffice .pickOfficeWrap__list__item .areaWrap {
  position: absolute;
  left: 15px;
  bottom: 25px;
}

.catContOffice .pickOfficeWrap__list__item .areaWrap::before {
  content: "";
  width: 14px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/ico_taiou.png) no-repeat center center;
  background-size: cover;
  vertical-align: middle;
}

.catContOffice .pickOfficeWrap__list__item .areaWrap .lavel, .catContOffice .pickOfficeWrap__list__item .areaWrap .area {
  font-size: 1.2rem;
  display: inline-block;
}

.catContOffice .pickOfficeWrap__list__item .areaWrap .area::before {
  content: "/";
  display: inline-block;
  margin: 0 .2rem 0 0;
}

.catContOffice .pickOfficeWrap__list__item:not(:first-child) {
  border-left: 1px solid #ccc;
}

@media screen and (min-width: 767px) {
  .catContOffice .pickOfficeWrap__list__item:hover {
    background: #fff;
    opacity: 1 !important;
  }
}

.catConArticle {
  background: #eee;
  padding: 50px 1% 130px;
}

.catConArticle__inner {
  max-width: 1160px;
  margin: 0 auto;
}

.catConArticle .moreBtn {
  margin-top: 30px;
}

.catConArticle .moreBtn .btn--border {
  max-width: 720px;
}

@media screen and (max-width: 767px) {
  /*=================================
    カテゴリーページ
  =================================*/
  .blackBox {
    padding: 15px 3%;
  }
  .blackBox .btnWrap a {
    font-size: 1.8rem;
    padding: 17px 0;
    line-height: 1.3;
  }
  .catContWrap {
    padding: 30px 0;
  }
  .catContWrap__inner {
    display: block;
  }
  .catContWrap .catContTtl {
    font-size: 2.2rem;
    margin-bottom: 25px;
    text-align: center;
  }
  .catContWrap .merit {
    width: 100%;
  }
  .catContWrap .meritList__item {
    padding: 20px 5% 20px 60px;
  }
  .catContWrap .meritList__item .meritTtl {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }
  .catContWrap .meritList__item:not(:last-child) {
    margin-right: 0;
  }
  .catContWrap .pref {
    width: 100%;
    padding-top: 30px;
  }
  .catContWrap .prefWrap__block {
    display: block;
    align-items: center;
  }
  .catContWrap .prefTtl {
    width: 100%;
    padding: 25px 15px 0;
  }
  .catContWrap .prefList {
    width: 100%;
    padding: 10px 15px 25px;
    line-height: 1.4;
  }
  .catContWrap .prefList__item a {
    font-size: 1.4rem;
    font-weight: 400;
    color: #222;
    display: block;
  }
  .catContOffice {
    padding: 30px 3%;
  }
  .catContOffice .pickOfficeWrap__ttl {
    font-size: 1.6rem;
    padding: 10px 15px 15px;
  }
  .catContOffice .pickOfficeWrap__ttl .date {
    display: block;
    margin: 0 0 .5rem;
  }
  .catContOffice .pickOfficeWrap__ttl::after {
    width: 100px;
    height: 3px;
  }
  .catContOffice .pickOfficeWrap__list {
    display: block;
  }
  .catContOffice .pickOfficeWrap__list__item {
    width: 100%;
    min-height: inherit;
    padding: 15px 15px 55px;
  }
  .catContOffice .pickOfficeWrap__list__item .thumb {
    margin-bottom: 15px;
    margin: 0 auto;
    text-align: center;
  }
  .catContOffice .pickOfficeWrap__list__item .textWrap .text {
    margin-top: 10px;
  }
  .catContOffice .pickOfficeWrap__list__item .areaWrap {
    bottom: 15px;
  }
  .catContOffice .pickOfficeWrap__list__item:not(:first-child) {
    border-left: none;
    border-top: 1px solid #ccc;
  }
  .catConArticle {
    padding: 30px 3% 60px;
  }
  .catConArticle .moreBtn {
    margin-top: 10px;
  }
}

/*=================================
  事務所一覧
=================================*/
.catSelectWrap {
  padding: 20px 1%;
  background: #333;
}

.catSelectList {
  max-width: 1160px;
  margin: 0 auto;
  border: 1px solid #515151;
  border-radius: 4px;
  background: #3d3d3d;
  position: relative;
}

.catSelectList__item {
  display: inline-block;
}

.catSelectList__item a {
  font-size: 1.6rem;
  color: #fff;
  display: block;
  padding: 20px 35px 20px;
  background: #3d3d3d;
  text-decoration: none;
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
  .catSelectList__item a {
    padding: 15px 10px 15px;
  }
}

.catSelectList__item a .icon {
  width: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

.catSelectList__item:last-child::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #515151;
  position: absolute;
  top: 50%;
  left: 0;
}

.catSelectList__item.on a {
  background: #777;
}

@media screen and (min-width: 767px) {
  .catSelectList__item:hover a {
    text-decoration: underline;
  }
}

.bengoList {
  background: #eee;
}

.bengoList .layout--2col {
  padding: 40px 0 100px;
}

.bengoList .layout--2col__cont {
  width: calc(100% - 300px);
  padding: 0;
  border-top: none;
}

.bengoList .layout--2col__cont::before {
  content: none;
}

.officeList__item {
  background: #fff;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.1);
  margin: 30px 0;
}

.officeList__item .thumb {
  width: 270px;
  float: left;
}

.officeList__item .thumb + .contWrap {
  padding: 30px 30px 0 310px;
}

.officeList__item .contWrap {
  padding: 20px;
}

.officeList__item .contWrap .tag {
  margin-bottom: 15px;
}

.officeList__item .contWrap .tag__item {
  display: inline-block;
  font-size: 1.1rem;
  line-height: 1;
  color: #fff;
  margin: 0 3px 3px 0;
  padding: 4px 8px;
  border-radius: 3px;
  background: #337cbd;
}

.officeList__item .contWrap .name {
  font-size: 2.4rem;
  margin-bottom: 10px;
}

.officeList__item .contWrap .add {
  font-size: 1.4rem;
}

.officeList__item .contWrap .tableWrap {
  display: flex;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.officeList__item .contWrap .tableWrap .tableBlock {
  width: 50%;
  text-align: center;
}

.officeList__item .contWrap .tableWrap .tableBlock:not(:first-child) {
  border-left: 1px solid #d6d6d6;
}

.officeList__item .contWrap .tableWrap .tableBlock .ttl {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 3px;
  background: #f3f3f3;
}

.officeList__item .contWrap .tableWrap .tableBlock .cont {
  padding: 10px;
}

.officeList__item .contWrap .tableWrap .tableBlock .cont .text {
  display: inline-block;
  vertical-align: middle;
}

.officeList__item .contWrap .tableWrap .tableBlock .cont .fieldList__item {
  display: inline-block;
  width: 24px;
  margin: 0 4px;
}

.officeList__item .contWrap .text {
  font-size: 1.4rem;
  margin-top: 30px;
}

.officeList__item .btnWrap {
  margin: 0;
  padding: 40px 20px;
  background: #e9f5ff;
}

.officeList__item .btnWrap .telBtn {
  font-size: 3.4rem;
  font-weight: 500;
  color: #fff;
  background: #338d3d;
  box-shadow: 0 5px 0 #1b7025;
  text-decoration: none;
  border-radius: 5px;
  padding: 15px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 767px) {
  .officeList__item .btnWrap .telBtn:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

.officeList__item .btnWrap .mailBtn {
  font-size: 2.8rem;
  font-weight: 500;
  color: #fff;
  background: #337cbd;
  box-shadow: 0 5px 0 #2667a1;
  text-decoration: none;
  border-radius: 5px;
  padding: 15px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 767px) {
  .officeList__item .btnWrap .mailBtn:hover {
    box-shadow: none;
    transform: translateY(5px);
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    事務所一覧
  =================================*/
  .catSelectWrap {
    padding: 20px 3%;
  }
  .catSelectList {
    border: none;
    border-radius: 0;
    display: flex;
    flex-wrap: wrap;
    background: none;
  }
  .catSelectList__item {
    display: block;
    width: calc(100% / 3);
    text-align: center;
  }
  .catSelectList__item a {
    font-size: 1.4rem;
    padding: 8px 0;
    background: none;
  }
  .catSelectList__item a .icon {
    width: 20px;
    margin-right: 3px;
    margin-top: -2px;
  }
  .catSelectList__item:last-child::after {
    content: none;
  }
  .bengoList .layout--2col {
    padding: 30px 3% 50px;
  }
  .bengoList .layout--2col__cont {
    width: 100%;
  }
  .bengoList .layout--2col__cont::before {
    content: none;
  }
  .officeList__item {
    margin: 30px 0;
  }
  .officeList__item .thumb {
    width: 30%;
    margin: 20px 15px 0 15px;
  }
  .officeList__item .thumb + .contWrap {
    padding: 20px 15px;
  }
  .officeList__item .contWrap {
    padding: 20px 15px;
  }
  .officeList__item .contWrap .name {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 500;
  }
  .officeList__item .contWrap .tableWrap {
    width: 100%;
    margin: 20px 0;
  }
  .officeList__item .contWrap .tableWrap .tableBlock .ttl {
    font-size: 1.2rem;
  }
  .officeList__item .contWrap .tableWrap .tableBlock .cont {
    padding: 5px;
  }
  .officeList__item .contWrap .tableWrap .tableBlock .cont .fieldList__item {
    width: 20px;
    margin: 0 2px;
  }
  .officeList__item .contWrap .text {
    margin-top: 20px;
  }
  .officeList__item .btnWrap {
    padding: 20px 5%;
  }
  .officeList__item .btnWrap .telBtn {
    font-size: 2.4rem;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
  }
  .officeList__item .btnWrap .mailBtn {
    font-size: 2.4rem;
    border-radius: 3px;
    padding: 10px;
    width: 100%;
  }
}

/*=================================
  事務所詳細
=================================*/
.pageNav {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.pageNav__list {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
}

.pageNav__list a {
  font-size: 1.6rem;
  text-decoration: none;
  padding: 18px 5px;
  display: block;
  text-align: center;
}

@media screen and (min-width: 767px) {
  .pageNav__list a:hover {
    text-decoration: underline;
  }
}

.pageNav__list__item {
  width: calc(100% / 6);
  position: relative;
  border-right: 1px solid #ddd;
  z-index: 100;
}

.pageNav__list__item a {
  color: #333;
}

@media screen and (min-width: 767px) {
  .pageNav__list__item a:hover {
    color: #fff;
    background: #005bac;
  }
}

.pageNav__list__item:first-child {
  border-left: 1px solid #ddd;
}

.pageNav__list__item:hover .pageNav__listChild {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.pageNav__listChild {
  width: 100%;
  position: absolute;
  top: 70%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.pageNav__listChild__item a {
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
}

@media screen and (min-width: 767px) {
  .pageNav__listChild__item a:hover {
    background: rgba(0, 0, 0, 0.8);
  }
}

.pageNav__listChild__item:not(:last-child) {
  border-bottom: 1px solid #6a6a6a;
}

.bengoDetail {
  background: #eee;
  padding: 40px 1% 100px;
}

.bengoDetail--2col {
  max-width: 1160px;
  margin: 0 auto;
  position: relative;
}

.bengoDetail .bengoInfo {
  width: 480px;
  position: absolute;
  top: 0;
  right: 0;
}

.bengoDetail .bengoInfo__inner {
  width: 480px;
  background: #fff;
  border-bottom: 1px solid #ddd;
}

.bengoDetail .bengoInfo__inner .contactItem .ttl {
  font-size: 1.8rem;
  color: #fff;
  width: 20%;
  height: 100px;
  min-width: 100px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.bengoDetail .bengoInfo__inner .contactItem .text {
  width: 80%;
  padding: 16px 18px;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: table-cell;
  vertical-align: middle;
}

.bengoDetail .bengoInfo__inner .contactItem.tel .ttl {
  background: #338d3d;
}

.bengoDetail .bengoInfo__inner .contactItem.tel .text a {
  font-size: 3.6rem;
  font-weight: 500;
  color: #338d3d;
}

.bengoDetail .bengoInfo__inner .contactItem.tel .text a::before {
  content: "";
  width: 23px;
  height: 30px;
  display: inline-block;
  margin-right: 7px;
  background: url(../img/icon/ico_tel_g.png) no-repeat center center;
  background-size: contain;
}

.bengoDetail .bengoInfo__inner .contactItem.status .ttl {
  background: #337cbd;
}

.bengoDetail .bengoInfo__inner .contactItem.status .text {
  font-size: 1.8rem;
}

.bengoDetail .bengoInfo__inner .contactItem.status .text .time {
  margin-right: 3rem;
  display: inline-block;
}

.bengoDetail .bengoInfo__inner .contactItem.status .text .holiday {
  display: inline-block;
}

.bengoDetail .bengoInfo__inner .contactItem.status .text .holiday .label {
  font-size: 1.4rem;
  font-weight: 600;
  background: #eee;
  padding: 5px 19px;
  border-radius: 50px;
  display: inline;
  margin-right: 1rem;
}

.bengoDetail .bengoInfo__inner .contactItem.status .text .holiday .day {
  display: inline;
}

.bengoDetail .bengoInfo__inner .contactItem:last-of-type .text {
  border-bottom: 1px solid #ddd;
}

.bengoDetail .bengoInfo__inner .contact .mailBtn {
  padding: 20px 25px 25px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.bengoDetail .bengoInfo__inner .contact .mailBtn .btn--blue {
  font-size: 1.8rem;
  max-width: 100%;
  padding-top: 22px;
  padding-bottom: 22px;
  text-decoration: none;
}

.bengoDetail .bengoInfo__inner .contact .mailBtn .btn--blue::before {
  content: "";
  width: 21px;
  height: 17px;
  display: inline-block;
  background: url(../img/icon/ico_mail.png) no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}

.bengoDetail .bengoInfo__inner .moreInfo__item .ttl {
  font-size: 1.8rem;
  color: #fff;
  background: #333;
  padding: 20px;
}

.bengoDetail .bengoInfo__inner .moreInfo__item .cont {
  padding: 20px 28px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.bengoDetail .bengoInfo__inner .moreInfo__item .cont .areaList__item {
  font-size: 1.6rem;
  color: #222;
  display: inline-block;
  margin-right: 1.5rem;
}

.bengoDetail .bengoInfo__inner .moreInfo__item .cont .areaList__item a {
  font-size: 1.6rem;
}

.bengoDetail .bengoInfo__inner .moreInfo__item .cont .list__item {
  font-size: 1.6rem;
  color: #222;
  display: inline-block;
  padding-left: 34px;
  background: url(../img/icon/ico_arw04.png) no-repeat left center;
  background-size: contain;
  margin: 0 1.5rem 1rem 0;
}

.bengoDetail__cont {
  padding-right: 520px;
}

.bengoDetail .bengoMv {
  margin: 0 auto 20px;
  display: flex;
  flex-wrap: wrap;
}

.bengoDetail .bengoMv__item:first-child {
  width: 100%;
  margin: 0 auto;
}

.bengoDetail .bengoMv__item:nth-child(n + 2) {
  width: calc((100% - 40px) / 3);
  margin: 20px 0 0 20px;
}

.bengoDetail .bengoMv__item:nth-child(n + 2):nth-child(3n - 1) {
  margin-left: 0;
}

.bengoDetail.form {
  background: #fff;
}

.bengoSec {
  padding: 0 0 80px;
}

.bengoSec__inner {
  max-width: calc(1160px + 2%);
  margin: 0 auto;
  padding: 0 1%;
}

.bengoSecTtl {
  font-size: 3.6rem;
  font-weight: 500;
  color: #222;
  padding: 50px 0;
  text-align: center;
  background: #fff;
}

.strongList__item__inner {
  max-width: 1160px;
  margin: 0 auto;
  padding: 120px 0 100px;
  position: relative;
}

.strongList__item__inner .strongTtl {
  width: 160px;
  height: 160px;
  text-align: center;
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
}

.strongList__item__inner .strongTtl__inner {
  font-size: 2.6rem;
  font-weight: 500;
  color: #fff;
  width: 160px;
  height: 160px;
  background: #337cbd;
  display: table-cell;
  vertical-align: middle;
}

.strongList__item__inner .strongCont > p {
  font-size: 1.6rem;
  line-height: 1.875;
  margin-top: 30px;
}

.strongList__item__inner .strongCont > h1 {
  font-size: 3.6rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 20px;
}

.strongList__item__inner .strongCont > h2 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.2272;
  padding: 40px 16px;
  background: #f6f6f6;
  margin-top: 54px;
  margin-bottom: 30px;
  border-top: 4px solid #999;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.strongList__item__inner .strongCont > h2::before {
  content: "";
  width: 28%;
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  top: -4px;
  left: 0;
}

.strongList__item__inner .strongCont > h3 {
  font-size: 2rem;
  font-weight: 500;
  color: #333;
  background: #f6f6f6;
  padding: 18px;
  border: 1px solid #ddd;
  margin-top: 35px;
  margin-bottom: 24px;
  position: relative;
}

.strongList__item__inner .strongCont > h3::before {
  content: "";
  width: 4px;
  height: calc(100% + 2px);
  display: block;
  background: #005bac;
  position: absolute;
  top: -1px;
  left: -1px;
}

.strongList__item__inner .strongCont > h4 {
  font-size: 1.8rem;
  font-weight: 500;
  color: #333;
  padding: 0 0 26px;
  margin-top: 35px;
  margin-bottom: 25px;
  border-bottom: 2px solid #ddd;
  position: relative;
}

.strongList__item__inner .strongCont > h4::before {
  content: "";
  width: 28%;
  height: 2px;
  display: block;
  background: #005bac;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.strongList__item__inner .strongCont > h5 {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  background: #fff;
  padding: 8px 4px 12px;
  border-bottom: 1px solid #ddd;
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
}

.strongList__item__inner .strongCont > h5::before {
  content: "";
  width: 4px;
  height: calc(100% + 2px);
  display: block;
  background: #005bac;
  position: absolute;
  top: -1px;
  left: -1px;
}

.strongList__item__inner .strongCont > h6 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 20px;
}

.strongList__item__inner .strongCont > h6::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #005bac;
  vertical-align: middle;
  margin-right: .8rem;
  margin-top: -2px;
}

.strongList__item__inner .strongCont > blockquote {
  position: relative;
  padding: 40px 35px;
  margin: 30px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  border: 3px solid #ddd;
}

.strongList__item__inner .strongCont > blockquote::before {
  content: "“";
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  color: #337cbd;
  display: inline-block;
  font-style: normal;
  width: 80px;
  height: 50px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: -28px;
  left: 0;
}

.strongList__item__inner .strongCont > blockquote h4, .strongList__item__inner .strongCont > blockquote h5, .strongList__item__inner .strongCont > blockquote h6 {
  margin: 0 0 20px;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: .06em;
  position: relative;
}

.strongList__item__inner .strongCont > blockquote h4::after, .strongList__item__inner .strongCont > blockquote h5::after, .strongList__item__inner .strongCont > blockquote h6::after {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  display: inline-block;
  height: 10px;
  content: '';
  background-image: repeating-linear-gradient(-45deg, #337cbd, #337cbd 1px, transparent 2px, transparent 5px);
  background-size: 7px 7px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.strongList__item__inner .strongCont > blockquote p {
  font-style: normal;
}

.strongList__item__inner .strongCont > blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.strongList__item__inner .strongCont > img {
  display: block;
  margin: 15px auto 20px;
}

.strongList__item__inner .strongCont > ul:not([class]) {
  margin-top: 30px;
  margin-bottom: 54px;
  padding: 20px 30px;
  background: #f6f6f6;
  border: 1px solid #ddd;
}

.strongList__item__inner .strongCont > ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 22px;
  position: relative;
}

.strongList__item__inner .strongCont > ul:not([class]) li::before {
  content: "";
  display: inline-block;
  border-width: 5px 0 5px 8px;
  border-style: solid;
  border-color: transparent transparent transparent #005bac;
  position: absolute;
  top: 12px;
  left: 0;
}

.strongList__item__inner .strongCont > ul:not([class]) li + li {
  margin-top: 15px;
}

.strongList__item__inner .strongCont > ol:not([class]) {
  margin-top: 30px;
  margin-bottom: 54px;
  padding: 20px 25px;
  background: #f6f6f6;
  border: 1px solid #ddd;
  counter-reset: listNum;
}

.strongList__item__inner .strongCont > ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 34px;
  position: relative;
}

.strongList__item__inner .strongCont > ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  background: #337cbd;
  border-radius: 50%;
  padding: 3px 8px 4px 8px;
  position: absolute;
  top: 2px;
  left: 0;
}

.strongList__item__inner .strongCont > ol:not([class]) li + li {
  margin-top: 15px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .strongList__item__inner .strongCont > p {
    font-size: 1.4rem;
    line-height: 1.5;
    margin-top: 15px;
  }
  .strongList__item__inner .strongCont > h1 {
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .strongList__item__inner .strongCont > h2 {
    font-size: 1.8rem;
    line-height: 1.38888;
    padding: 15px 10px;
    margin-top: 40px;
    margin-bottom: 15px;
  }
  .strongList__item__inner .strongCont > h3 {
    font-size: 1.6rem;
    padding: 15px 10px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .strongList__item__inner .strongCont > h4 {
    font-size: 1.5rem;
    padding: 0 0 12px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .strongList__item__inner .strongCont > h5 {
    padding: 6px 12px 8px;
    margin-bottom: 15px;
  }
  .strongList__item__inner .strongCont > h6 {
    margin-bottom: 15px;
  }
  .strongList__item__inner .strongCont > h6::before {
    width: 8px;
    height: 8px;
    margin-right: .6rem;
  }
  .strongList__item__inner .strongCont > blockquote {
    padding: 25px 3% 20px;
    margin: 20px 0;
  }
  .strongList__item__inner .strongCont > blockquote::before {
    font-size: 6rem;
    width: 50px;
    top: -22px;
  }
  .strongList__item__inner .strongCont > blockquote h4, .strongList__item__inner .strongCont > blockquote h5, .strongList__item__inner .strongCont > blockquote h6 {
    margin: 0 0 15px;
    font-size: 1.4rem;
    line-height: 1.2;
  }
  .strongList__item__inner .strongCont > blockquote h4::after, .strongList__item__inner .strongCont > blockquote h5::after, .strongList__item__inner .strongCont > blockquote h6::after {
    height: 7px;
  }
  .strongList__item__inner .strongCont > img {
    display: block;
    margin: 15px auto;
  }
  .strongList__item__inner .strongCont > ul:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .strongList__item__inner .strongCont > ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 15px;
  }
  .strongList__item__inner .strongCont > ul:not([class]) li::before {
    border-width: 5px 0 5px 6px;
    top: 7px;
  }
  .strongList__item__inner .strongCont > ul:not([class]) li + li {
    margin-top: 10px;
  }
  .strongList__item__inner .strongCont > ol:not([class]) {
    margin-bottom: 15px;
    padding: 15px 3%;
  }
  .strongList__item__inner .strongCont > ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .strongList__item__inner .strongCont > ol:not([class]) li::before {
    font-size: 1.4rem;
    padding: 3px 6px 3px 6px;
  }
  .strongList__item__inner .strongCont > ol:not([class]) li + li {
    margin-top: 10px;
  }
}

.strongList__item__inner .strongCont .tocBox {
  margin: 40px 0 20px;
}

.strongList__item__inner .strongCont .tocBox__ttl {
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  padding: 12px 0;
}

.strongList__item__inner .strongCont .tocBox__ttl .toc_toggle {
  font-size: 1.2rem;
  font-weight: normal;
  color: #999;
  margin: 0 0 0 18px;
  vertical-align: middle;
  cursor: pointer;
}

.strongList__item__inner .strongCont .tocBox .tocList {
  counter-reset: tocNum;
}

.strongList__item__inner .strongCont .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 500;
  display: block;
  padding: 18px 16px 18px 60px;
  position: relative;
  border-bottom: 1px solid #e2e2e2;
}

.strongList__item__inner .strongCont .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: counter(tocNum);
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.4rem;
  color: #fff;
  line-height: 22px;
  width: 22px;
  text-align: center;
  text-decoration: none;
  background: #337cbd;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 23px;
}

.strongList__item__inner .strongCont .infoWrap {
  margin-bottom: 30px;
}

.strongList__item__inner .strongCont .infoWrap .info--left {
  float: left;
}

.strongList__item__inner .strongCont .infoWrap .info--left .data {
  display: flex;
}

.strongList__item__inner .strongCont .infoWrap .info--left .data .date {
  font-size: 1.8rem;
  font-weight: 500;
  color: #005bac;
  padding-left: 20px;
  background: url(../img/icon/ico_cal.png) no-repeat left center;
}

.strongList__item__inner .strongCont .infoWrap .info--left .data .date + .date {
  margin-left: 1rem;
  background: url(../img/icon/ico_sea02.png) no-repeat left center;
  background-size: 16px auto;
}

.strongList__item__inner .strongCont .infoWrap .info--left .data .view {
  font-size: 1.4rem;
  background: url(../img/icon/ico_eye.png) no-repeat left center;
  padding-left: 18px;
  margin-left: 2rem;
}

.strongList__item__inner .strongCont .infoWrap .info--left .data .view .num {
  font-size: 1.8rem;
  font-weight: 500;
  color: #005bac;
  margin-right: 3px;
}

.strongList__item__inner .strongCont .infoWrap .info--right {
  float: right;
  display: flex;
}

.strongList__item__inner .strongCont .infoWrap .info--right .list--cat {
  display: flex;
  background: url(../img/icon/ico_fol.png) no-repeat left center;
  padding-left: 22px;
}

.strongList__item__inner .strongCont .infoWrap .info--right .list--tag {
  display: flex;
  background: url(../img/icon/ico_tag.png) no-repeat left center;
  padding-left: 22px;
  margin-left: 1rem;
}

.strongList__item__inner .strongCont .infoWrap .info--right a {
  font-size: 1.4rem;
  color: #333;
}

.strongList__item__inner .strongCont .articleMv {
  margin: 20px auto;
  text-align: center;
}

.strongList__item__inner .strongCont .snsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.strongList__item__inner .strongCont .snsWrap::before {
  content: "この記事をシェアする";
  font-size: 1.4rem;
  line-height: 1;
  margin: -10px 1rem 0 0;
}

.strongList__item__inner .strongCont .snsList {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.strongList__item__inner .strongCont .snsList li {
  line-height: 1;
  margin-left: 5px;
}

.strongList__item__inner .strongCont .snsList li.fb {
  margin-right: -5px;
}

.strongList__item__inner .strongCont .understand {
  margin: 60px auto 30px;
}

.strongList__item__inner .strongCont .understand__ttl {
  font-weight: 500;
  color: #fff;
  text-align: center;
  background: #337cbd;
  padding: 12px 15px;
  position: relative;
}

.strongList__item__inner .strongCont .understand__ttl::before {
  content: "";
  width: 25px;
  height: 22px;
  margin-right: 6px;
  display: inline-block;
  background: url(../img/icon/ico_kiji.png) no-repeat;
  vertical-align: middle;
}

.strongList__item__inner .strongCont .understand__cont {
  background: #f8f8f8;
  border: 1px solid #ddd;
  border-top: none;
  padding: 25px;
}

.strongList__item__inner .strongCont .understand__list__item {
  font-size: 1.8rem;
  padding: 8px 0;
  position: relative;
}

.strongList__item__inner .strongCont .understand__list__item::before {
  content: "";
  width: 21px;
  height: 21px;
  margin-right: 10px;
  display: inline-block;
  background: url(../img/icon/ico_chk01.png) no-repeat;
  vertical-align: middle;
}

.strongList__item__inner .strongCont .understand__text {
  margin-top: 20px;
}

.strongList__item__inner .strongCont .link-bx {
  border: 1px solid #aecbe5;
  margin: 40px auto 20px;
  position: relative;
  z-index: 0;
}

.strongList__item__inner .strongCont .link-bx a {
  padding: 16px 20px;
  background: #e9f5ff;
  display: block;
}

@media screen and (min-width: 767px) {
  .strongList__item__inner .strongCont .link-bx a:hover {
    background: #fff;
  }
}

.strongList__item__inner .strongCont .link-bx::before {
  content: "関連記事";
  color: #fff;
  padding: 5px 10px;
  background: #F30;
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
}

.strongList__item__inner .strongCont .point {
  color: #fff;
  text-align: center;
  background: #337cbd;
  margin: 40px auto 20px;
}

.strongList__item__inner .strongCont .point-ttl {
  font-size: 2rem;
  padding: 20px 0;
  border-bottom: 1px solid #71a4d1;
}

.strongList__item__inner .strongCont .point-ttl::before {
  content: "";
  width: 19px;
  height: 25px;
  display: inline-block;
  margin-right: 10px;
  background: url(../img/icon/ico_pnt.png) no-repeat;
  vertical-align: middle;
}

.strongList__item__inner .strongCont .point-bx {
  padding: 20px;
  text-align: center;
}

.strongList__item__inner .strongCont .check-bx {
  background: #fff5f5;
  border: 3px solid #ff8d8d;
  padding: 15px 30px;
  margin-top: 35px;
  margin-bottom: 54px;
}

.strongList__item__inner .strongCont .check-bx li {
  font-weight: 600;
  color: #e50000;
  background: url(../img/icon/ico_chk02.png) no-repeat left center;
  padding: 8px 0 8px 34px;
}

.strongList__item__inner .strongCont .related--simple {
  margin: 50px auto;
  border: 1px solid #ddd;
  position: relative;
}

.strongList__item__inner .strongCont .related--simple__item a {
  padding: 20px 15px;
  display: block;
}

@media screen and (min-width: 767px) {
  .strongList__item__inner .strongCont .related--simple__item a:hover {
    background: #337cbd;
    color: #fff;
  }
}

.strongList__item__inner .strongCont .related--simple__item + .related--simple__item {
  border-top: 1px dotted #ddd;
}

.strongList__item__inner .strongCont .related--simple::before {
  content: "一緒に読まれている記事";
  line-height: 1;
  color: #fff;
  background: #005bac;
  padding: 8px 10px;
  position: absolute;
  top: -15px;
  right: -1px;
}

@media screen and (min-width: 0) and (max-width: 767px) {
  .strongList__item__inner .strongCont .tocBox {
    margin: 30px 0 20px;
  }
  .strongList__item__inner .strongCont .tocBox__ttl {
    font-size: 2rem;
    padding: 10px 0;
  }
  .strongList__item__inner .strongCont .tocBox__ttl .toc_toggle {
    margin: 0 0 0 10px;
  }
  .strongList__item__inner .strongCont .tocBox .tocList__item a {
    font-size: 1.4rem;
    padding: 15px 3% 15px 40px;
  }
  .strongList__item__inner .strongCont .tocBox .tocList__item a::before {
    top: 15px;
    left: 10px;
  }
  .strongList__item__inner .strongCont .infoWrap {
    margin-bottom: 20px;
  }
  .strongList__item__inner .strongCont .infoWrap .info--left {
    float: none;
  }
  .strongList__item__inner .strongCont .infoWrap .info--left .data .date {
    font-size: 1.4rem;
  }
  .strongList__item__inner .strongCont .infoWrap .info--left .data .date + .date {
    margin-left: .8rem;
  }
  .strongList__item__inner .strongCont .infoWrap .info--left .data .view {
    font-size: 1.2rem;
    padding-left: 20px;
    margin-left: 1.5rem;
  }
  .strongList__item__inner .strongCont .infoWrap .info--left .data .view .num {
    font-size: 1.4rem;
  }
  .strongList__item__inner .strongCont .infoWrap .info--right {
    float: none;
    display: block;
    margin-top: 5px;
  }
  .strongList__item__inner .strongCont .infoWrap .info--right .list--tag {
    margin-left: 0;
  }
  .strongList__item__inner .strongCont .infoWrap .info--right a {
    font-size: 1.2rem;
  }
  .strongList__item__inner .strongCont .snsWrap::before {
    content: none;
  }
  .strongList__item__inner .strongCont .snsList li {
    margin-left: 3px;
  }
  .strongList__item__inner .strongCont .understand {
    margin: 40px auto 20px;
  }
  .strongList__item__inner .strongCont .understand__ttl {
    padding: 10px 3%;
  }
  .strongList__item__inner .strongCont .understand__cont {
    padding: 15px;
  }
  .strongList__item__inner .strongCont .understand__list__item {
    font-size: 1.4rem;
    padding: 5px 0 5px 30px;
    position: relative;
  }
  .strongList__item__inner .strongCont .understand__list__item::before {
    margin-right: 0;
    position: absolute;
    top: 5px;
    left: 0;
  }
  .strongList__item__inner .strongCont .understand__text {
    margin-top: 15px;
    padding: 0 3%;
  }
  .strongList__item__inner .strongCont .link-bx {
    margin: 30px auto 15px;
  }
  .strongList__item__inner .strongCont .link-bx a {
    padding: 20px 3% 15px;
  }
  .strongList__item__inner .strongCont .link-bx::before {
    padding: 3px 5px;
    top: -10px;
    right: -5px;
  }
  .strongList__item__inner .strongCont .point {
    margin: 30px auto 15px;
  }
  .strongList__item__inner .strongCont .point-ttl {
    font-size: 1.4rem;
    padding: 15px 0 10px;
  }
  .strongList__item__inner .strongCont .point-bx {
    padding: 15px;
    text-align: left;
  }
  .strongList__item__inner .strongCont .check-bx {
    padding: 10px 3%;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .strongList__item__inner .strongCont .check-bx li {
    padding: 5px 0 5px 32px;
  }
  .strongList__item__inner .strongCont .related--simple {
    margin: 40px auto;
  }
  .strongList__item__inner .strongCont .related--simple__item a {
    padding: 15px 3%;
  }
  .strongList__item__inner .strongCont .related--simple::before {
    padding: 8px 6px;
    top: -20px;
  }
}

.strongList__item__inner .strongCont h1 {
  margin-top: 40px;
}

.strongList__item__inner .strongCont img:not([class]) {
  max-width: 380px;
  margin-bottom: 15px;
}

.strongList__item__inner .strongCont .ttl {
  font-size: 3.6rem;
  font-weight: 500;
  color: #222;
  margin-bottom: 30px;
}

.strongList__item:nth-child(2n - 1) img:not([class]) {
  float: right;
  margin-left: 60px;
}

.strongList__item:nth-child(2n) {
  background: #eee;
}

.strongList__item:nth-child(2n) img:not([class]) {
  float: left;
  margin-right: 60px;
}

.price .bengoSecTtl {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.member {
  background: #eee;
}

.member .bengoSecTtl {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.memberList {
  padding-top: 68px;
}

.memberList__item .memberInfo__band {
  color: #fff;
  background: #333;
  padding: 18px 30px 18px 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.memberList__item .memberInfo__band .img {
  width: 100px;
  margin-right: 30px;
}

.memberList__item .memberInfo__band .ttl {
  font-size: 1.8rem;
  margin-right: 40px;
}

.memberList__item .memberInfo__band .ttl .name {
  font-size: 2.8rem;
  margin-right: 1rem;
}

.memberList__item .memberInfo__band .affInfo {
  display: flex;
  margin-top: 5px;
}

.memberList__item .memberInfo__band .affInfo__item .label {
  font-size: 1.4rem;
  color: #fff;
  background: #337cbd;
  border-radius: 50px;
  padding: 2px 12px;
  min-width: 80px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
}

.memberList__item .memberInfo__band .affInfo__item .text {
  font-size: 1.8rem;
  display: inline-block;
}

.memberList__item .memberInfo__band .affInfo__item + .affInfo__item {
  margin-left: 40px;
}

.memberList__item .memberInfo__sub {
  padding: 20px 0;
  background: #fff;
  display: flex;
}

.memberList__item .memberInfo__sub .subInfo {
  width: 50%;
  padding: 0 25px;
}

.memberList__item .memberInfo__sub .subInfo + .subInfo {
  border-left: 1px solid #d5d5d5;
}

.memberList__item .memberInfo__sub .subInfoCont {
  display: flex;
}

.memberList__item .memberInfo__sub .subInfoCont .subInfo__ttl {
  width: 125px;
}

.memberList__item .memberInfo__sub .subInfoCont .subInfo__cont {
  width: calc(100% - 125px);
}

.memberList__item .memberInfo__sub .subInfoCont + .subInfoCont {
  margin-top: 30px;
}

.memberList__item + .memberList__item {
  margin-top: 30px;
}

.access {
  padding-bottom: 0;
}

.access .gMap {
  margin: 0 auto;
}

.access .gMap iframe {
  width: 100%;
}

.access table {
  margin: 0;
  border: none;
}

.access table tr, .access table th, .access table td {
  font-size: 1.4rem;
  background: transparent;
  border: none;
}

.access table th {
  font-weight: 500;
  color: #005bac;
  padding-left: calc((100% - 1160px) / 2);
  padding-right: 30px;
}

.access table td {
  padding-right: calc((100% - 1160px) / 2);
}

.access table tr:nth-child(2n + 1) {
  background: #f4f4f4;
}

@media screen and (max-width: 1250px) and (min-width: 767px) {
  .access table th {
    padding: 20px;
  }
  .access table td {
    padding: 20px;
  }
}

.office .bengoSecTtl {
  border-bottom: 1px solid #dbdbdb;
}

.office__inner {
  padding: 0 1%;
}

.office table {
  margin: 0;
  border: none;
  max-width: 1160px;
  margin: 0 auto;
}

.office tr, .office th, .office td {
  font-size: 1.4rem;
  background: transparent;
}

.office th, .office td {
  border: none;
}

.office th {
  color: #005bac;
  padding-left: 0;
}

.fixedBnr.bengo {
  border: 5px solid #337cbd;
  background: #f1f8ff;
}

.fixedBnr.bengo .bnrInner {
  display: flex;
}

.fixedBnr.bengo .bnrInner .cont {
  width: calc(100% - 150px);
  padding: 25px 35px 40px;
}

.fixedBnr.bengo .bnrInner .cont .name {
  font-size: 2.6rem;
  font-weight: 400;
  margin-bottom: 5px;
  color: #222;
}

.fixedBnr.bengo .bnrInner .cont .info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.fixedBnr.bengo .bnrInner .cont .info .tel {
  margin-right: 30px;
}

.fixedBnr.bengo .bnrInner .cont .info .tel .label {
  font-size: 1.4rem;
  color: #fff;
  background: #338d3d;
  border-radius: 50px;
  min-width: 80px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px;
}

.fixedBnr.bengo .bnrInner .cont .info .tel .num {
  display: inline-block;
  vertical-align: bottom;
}

.fixedBnr.bengo .bnrInner .cont .info .tel .num a {
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1;
  color: #338d3d;
  display: block;
}

.fixedBnr.bengo .bnrInner .cont .info .tel .num a::before {
  content: "";
  width: 23px;
  height: 30px;
  display: inline-block;
  margin-right: 7px;
  background: url(../img/icon/ico_tel_g.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
}

.fixedBnr.bengo .bnrInner .cont .info .status {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.fixedBnr.bengo .bnrInner .cont .info .status__item .label {
  font-size: 1.4rem;
  background: #fff;
  border-radius: 50px;
  min-width: 80px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
}

.fixedBnr.bengo .bnrInner .cont .info .status__item .text {
  font-size: 1.8rem;
  display: inline-block;
}

.fixedBnr.bengo .bnrInner .cont .info .status__item + .status__item {
  margin-left: 20px;
}

.fixedBnr.bengo .bnrInner .message {
  width: 150px;
  text-align: center;
  background: #337cbd;
  position: relative;
}

.fixedBnr.bengo .bnrInner .message .text {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  padding: 20px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1025px) {
  .bengoDetail {
    padding: 20px 3% 60px;
  }
  .bengoDetail .bengoInfo {
    width: 100%;
    position: static;
  }
  .bengoDetail .bengoInfo__inner {
    width: 100%;
  }
  .bengoDetail .bengoInfo__inner .contactItem {
    display: flex;
  }
  .bengoDetail .bengoInfo__inner .contactItem .ttl {
    font-size: 1.4rem;
    width: 60px;
    height: inherit;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bengoDetail .bengoInfo__inner .contactItem .text {
    width: calc(100% - 60px);
    padding: 10px;
    display: block;
  }
  .bengoDetail .bengoInfo__inner .contactItem.tel .text a {
    font-size: 2.4rem;
  }
  .bengoDetail .bengoInfo__inner .contactItem.tel .text a::before {
    width: 18px;
    height: 24px;
    margin-right: 5px;
  }
  .bengoDetail .bengoInfo__inner .contactItem.status .text {
    font-size: 1.4rem;
  }
  .bengoDetail .bengoInfo__inner .contactItem.status .text .time {
    margin-right: 1.5rem;
  }
  .bengoDetail .bengoInfo__inner .contactItem.status .text .holiday .label {
    font-size: 1.2rem;
    padding: 3px 8px;
    margin-right: .5rem;
  }
  .bengoDetail .bengoInfo__inner .contact .mailBtn {
    padding: 15px;
  }
  .bengoDetail .bengoInfo__inner .contact .mailBtn .btn--blue {
    font-size: 1.6rem;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .bengoDetail .bengoInfo__inner .moreInfo__item .ttl {
    font-size: 1.6rem;
    padding: 12px;
  }
  .bengoDetail .bengoInfo__inner .moreInfo__item .cont {
    padding: 15px;
  }
  .bengoDetail .bengoInfo__inner .moreInfo__item .cont .areaList__item {
    font-size: 1.4rem;
    margin-right: 1rem;
  }
  .bengoDetail .bengoInfo__inner .moreInfo__item .cont .areaList__item a {
    font-size: 1.4rem;
  }
  .bengoDetail .bengoInfo__inner .moreInfo__item .cont .list__item {
    font-size: 1.4rem;
    padding-left: 30px;
  }
  .bengoDetail__cont {
    padding-right: 0;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    事務所詳細
  =================================*/
  .pageNav {
    display: none;
  }
  .bengoSec {
    padding: 0 0 40px;
  }
  .bengoSec__inner {
    padding: 0 3%;
  }
  .bengoSecTtl {
    font-size: 2.2rem;
    padding: 25px 0;
  }
  .strongList__item__inner {
    padding: 80px 3% 45px;
  }
  .strongList__item__inner .strongTtl {
    width: 100px;
    height: 100px;
    top: -35px;
  }
  .strongList__item__inner .strongTtl__inner {
    font-size: 1.8rem;
    width: 100px;
    height: 100px;
  }
  .strongList__item__inner .strongCont h1 {
    margin-top: 20px;
  }
  .strongList__item__inner .strongCont img:not([class]) {
    max-width: 80%;
    margin: 0 auto 15px;
    display: block;
  }
  .strongList__item__inner .strongCont .ttl {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  .strongList__item:nth-child(2n - 1) img:not([class]) {
    float: none;
    margin-left: auto;
  }
  .strongList__item:nth-child(2n) img:not([class]) {
    float: none;
    margin-right: auto;
  }
  .memberList {
    padding-top: 40px;
  }
  .memberList__item .memberInfo__band {
    padding: 20px 10px 10px;
    display: block;
    text-align: center;
  }
  .memberList__item .memberInfo__band .img {
    margin: 0 auto;
  }
  .memberList__item .memberInfo__band .ttl {
    font-size: 1.4rem;
    margin: 5px 0 0;
  }
  .memberList__item .memberInfo__band .ttl .name {
    font-size: 2rem;
    margin-right: .5rem;
  }
  .memberList__item .memberInfo__band .affInfo {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;
  }
  .memberList__item .memberInfo__band .affInfo__item {
    margin-right: 1.5rem;
  }
  .memberList__item .memberInfo__band .affInfo__item .label {
    font-size: 1.1rem;
    padding: 2px 8px;
    min-width: inherit;
    margin-right: 3px;
  }
  .memberList__item .memberInfo__band .affInfo__item .text {
    font-size: 1.4rem;
  }
  .memberList__item .memberInfo__band .affInfo__item + .affInfo__item {
    margin-left: 0;
  }
  .memberList__item .memberInfo__sub {
    padding: 10px 0;
    display: block;
  }
  .memberList__item .memberInfo__sub .subInfo {
    width: 100%;
    padding: 0 3%;
    font-size: 1.3rem;
  }
  .memberList__item .memberInfo__sub .subInfo + .subInfo {
    border-left: none;
  }
  .memberList__item .memberInfo__sub .subInfoCont .subInfo__ttl {
    width: 8em;
  }
  .memberList__item .memberInfo__sub .subInfoCont .subInfo__cont {
    width: calc(100% - 8em);
  }
  .memberList__item .memberInfo__sub .subInfoCont + .subInfoCont {
    margin-top: 10px;
  }
  .memberList__item .memberInfo__sub .subInfo + .subInfo {
    margin-top: 10px;
  }
  .memberList__item + .memberList__item {
    margin-top: 20px;
  }
  .access table {
    display: table;
    white-space: normal;
  }
  .access table tbody {
    border: none;
  }
  .access table th {
    padding: 15px 10px;
    width: 10em;
  }
  .access table td {
    padding: 15px 10px;
  }
  .office__inner {
    padding: 0 3%;
  }
  .office table {
    display: table;
    white-space: normal;
  }
  .office table tbody {
    border: none;
  }
  .office table th, .office table td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .office table th {
    width: 8em;
  }
  .fixedBnr.bengo {
    border: 3px solid #337cbd;
  }
  .fixedBnr.bengo .bnrInner .cont {
    width: calc(100% - 90px);
    padding: 10px 15px 15px;
  }
  .fixedBnr.bengo .bnrInner .cont .name {
    font-size: 1.5rem;
    display: none;
  }
  .fixedBnr.bengo .bnrInner .cont .info .tel {
    margin: 0;
  }
  .fixedBnr.bengo .bnrInner .cont .info .tel .label {
    font-size: 1.2rem;
    min-width: 60px;
    margin-right: 3px;
  }
  .fixedBnr.bengo .bnrInner .cont .info .tel .num a {
    font-size: 2rem;
  }
  .fixedBnr.bengo .bnrInner .cont .info .tel .num a::before {
    width: 18px;
    height: 25px;
  }
  .fixedBnr.bengo .bnrInner .cont .info .status {
    flex-wrap: wrap;
  }
  .fixedBnr.bengo .bnrInner .cont .info .status__item {
    margin-right: 5px;
  }
  .fixedBnr.bengo .bnrInner .cont .info .status__item .label {
    font-size: 1.2rem;
    min-width: 60px;
    margin-right: 3px;
  }
  .fixedBnr.bengo .bnrInner .cont .info .status__item .text {
    font-size: 1.3rem;
  }
  .fixedBnr.bengo .bnrInner .cont .info .status__item + .status__item {
    margin-left: 0px;
  }
  .fixedBnr.bengo .bnrInner .message {
    width: 90px;
  }
  .fixedBnr.bengo .bnrInner .message .text {
    font-size: 1.2rem;
    padding: 5px;
  }
}

/* 問い合わせフォーム */
/* .mw_wp_form */
.mw_wp_form input, .mw_wp_form textarea {
  font-size: 1.8rem;
  font-weight: 400;
  width: 100%;
  border: 2px solid #e5e5e5;
  background: #fff;
  padding: 5px 10px;
}

.mw_wp_form .hs {
  border: 2px solid #fbd193;
  background: #fffaf0;
}

.mw_wp_form .his {
  font-size: 1.2rem;
  color: #fff;
  background: #c94941;
  display: inline-block;
  margin: 3px 0 0 4px;
  padding: 4px 10px 5px;
}

.mw_wp_form th {
  width: 250px;
}

.mw_wp_form td span {
  margin: 10px 0 0 14px;
  color: #666;
  display: block;
}

.mw_wp_form table {
  display: table !important;
  white-space: inherit;
}

.mw_wp_form table tbody {
  display: table-row-group;
}

.mw_wp_form .submit {
  text-align: center;
  margin: 20px auto 0;
}

.mw_wp_form .submit input[type="submit"] {
  border: 1px solid #666;
  background: #f6f6f6;
  padding: 20px 0;
  margin: 2px 0;
  max-width: 400px;
  cursor: pointer;
}

@media screen and (min-width: 767px) {
  .mw_wp_form .submit input[type="submit"]:hover {
    background: #ddd;
  }
}

.mw_wp_form_preview td span {
  display: none;
}

@media screen and (max-width: 767px) {
  /* 問い合わせフォーム */
  /* .mw_wp_form */
  .mw_wp_form input, .mw_wp_form textarea {
    font-size: 1.6rem;
    padding: 5px 5px;
  }
  .mw_wp_form .his {
    font-size: 1.1rem;
    margin: 0 0 0 10px;
    padding: 3px 0;
    width: 3.5em;
    text-align: center;
  }
  .mw_wp_form th {
    width: 100%;
    display: block;
  }
  .mw_wp_form td {
    width: 100%;
    display: block;
  }
  .mw_wp_form td span {
    font-size: 1.1rem;
    margin: 6px 0 0;
  }
  .mw_wp_form .submit {
    margin: 0 auto 40px;
  }
  .mw_wp_form .submit input[type="submit"] {
    margin: 4px 0;
    padding: 20px 0;
  }
}

/* =================================
  header
================================= */
.header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  background: #005bac;
}

.header__top {
  position: relative;
}

.header__top .ttl--site {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 25%;
  text-align: center;
}

.header__top .ttl--site .logo {
  display: block;
  padding: 30px 10px;
  text-decoration: none;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.header__top .ttl--site .sub {
  font-size: 1.4rem;
  font-weight: 300;
  color: #000;
  display: block;
  margin-bottom: 3px;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 767px) {
  .header__top .ttl--site:hover .logo, .header__top .ttl--site:hover .sub {
    opacity: .8;
  }
}

.header .gNav__inner {
  padding: 10px 3% 10px 0;
  margin-left: 31%;
  display: flex;
  justify-content: flex-end;
}

.header .gNav .officeNum {
  color: #fff;
}

.header .gNav__list__item {
  margin-left: 20px;
  display: inline-block;
}

.header .gNav__list__item a {
  font-size: 1.3rem;
  font-weight: 300;
  color: #fff;
  padding-left: 14px;
  position: relative;
  text-decoration: none;
}

.header .gNav__list__item a::before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 5px 0 5px 8px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  top: 5px;
  left: 0;
}

.header .gNav__list__item a .icon {
  display: none;
}

@media screen and (min-width: 767px) {
  .header .gNav__list__item a:hover {
    text-decoration: underline;
  }
}

.header .headerNav__cover {
  display: none;
}

.header .searchBox__inner {
  max-width: inherit;
  padding-right: calc((100% - 1160px) / 2);
  padding-left: 31%;
}

.header .searchBox .inputBox {
  padding: 5px;
}

.header .searchBox .inputBox .ken {
  width: calc((115% - 120px) / 2);
  padding-left: 80px;
}

.header .searchBox .inputBox .ken::before {
  font-size: 1.2rem;
  top: 2px;
  left: 10px;
}

.header .searchBox .inputBox .ken::after {
  width: 22px;
  height: 22px;
  bottom: 8px;
  left: 15px;
}

.header .searchBox .inputBox .ken .area {
  right: 10px;
}

.header .searchBox .inputBox .ken .area a {
  font-size: 1.2rem;
}

.header .searchBox .inputBox .naiyo {
  width: calc((85% - 120px) / 2);
  padding-left: 80px;
}

.header .searchBox .inputBox .naiyo::before {
  font-size: 1.2rem;
  top: 2px;
  left: 10px;
}

.header .searchBox .inputBox .naiyo::after {
  width: 22px;
  height: 22px;
  bottom: 8px;
  left: 30px;
}

.header .searchBox .inputBox .btn {
  width: 121px;
}

.header .searchBox .inputBox .btn a::before {
  width: 24px;
  height: 24px;
}

.header .searchBox .inputBox .input {
  font-size: 1.8rem;
  padding: 12px 0;
}

@media screen and (max-width: 1025px) {
  /* =================================
    header
  ================================= */
  .header__top .ttl--site .logo {
    padding: 10px;
    text-align: left;
  }
  .header .searchBox {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* =================================
    header
  ================================= */
  .header {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    /*バーガーボタン設定*/
  }
  .header__top .ttl--site {
    position: static;
    max-width: 55%;
    width: inherit;
  }
  .header__top .ttl--site .logo {
    padding: 9px 0 0 10px;
  }
  .header__top .ttl--site .sub {
    font-size: 1rem;
    color: #444;
    margin-bottom: 0;
  }
  .header__top .ttl--site img {
    width: 150px;
    vertical-align: baseline;
  }
  .header .gNav {
    width: 100%;
    height: calc(90vh - 54px);
    overflow-y: auto;
    position: fixed;
    top: 54px;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 9999;
    opacity: 0;
    background: #333;
    visibility: hidden;
    transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
  }
  .header .gNav__inner {
    padding: 35px 3%;
    margin: 0 auto;
    display: block;
  }
  .header .gNav__inner .navTtl {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
  }
  .header .gNav__inner .navBox {
    text-align: center;
    margin-bottom: 30px;
  }
  .header .gNav__inner .navBox a {
    font-size: 1.4rem;
    color: #fff;
    text-align: center;
    padding: 29px 17px;
    overflow: hidden;
    background: #3d3d3d;
    border: 1px solid #515151;
    border-radius: 4px;
    display: inline-block;
  }
  .header .gNav__inner .navBox img {
    display: block;
    margin: 0 auto 5px;
  }
  .header .gNav .officeNum {
    display: none;
  }
  .header .gNav__list {
    display: block;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    background: #3d3d3d;
    border: 1px solid #515151;
    border-radius: 4px;
  }
  .header .gNav__list__item {
    margin-left: 0;
    display: inline-block;
  }
  .header .gNav__list__item a {
    padding: 15px 12px 15px;
    display: inline-block;
  }
  .header .gNav__list__item a::before {
    content: none;
  }
  .header .gNav__list__item a .icon {
    width: 26px;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .header .gNav.show {
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    opacity: 0;
    visibility: hidden;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: rgba(34, 34, 34, 0.95);
    text-align: center;
    position: absolute;
    top: 54px;
    display: none;
    z-index: 9998;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show .2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide .2s linear 0s;
  }
  .header .headerNav__cover span {
    font-size: 1.6rem;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 50%;
    bottom: 3vh;
    transform: translateX(-50%);
  }
  .header .headerNav__cover span::before {
    content: "";
    width: 15px;
    height: 3px;
    display: inline-block;
    background: #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 12px;
    left: -25px;
  }
  .header .headerNav__cover span::after {
    content: "";
    width: 15px;
    height: 3px;
    display: inline-block;
    background: #fff;
    transform: rotate(-45deg);
    position: absolute;
    top: 12px;
    left: -25px;
  }
  .header .burger {
    width: 30px;
    height: 26px;
    margin: auto;
    position: absolute;
    right: 18px;
    top: 15px;
    cursor: pointer;
    z-index: 9999;
    /*クリック後、バツボタンになる*/
  }
  .header .burger span {
    width: 30px;
    height: 4px;
    display: block;
    background: #347cbd;
    position: absolute;
    top: 11px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .header .burger span.burger--top {
    transform: translateY(-11px);
  }
  .header .burger span.burger--middle {
    transform: translateY(0px);
    position: relative;
    background: none;
  }
  .header .burger span.burger--middle::before {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 4px;
    background: #347cbd;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .header .burger span.burger--middle::after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 4px;
    background: #347cbd;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .header .burger span.burger--bottom {
    transform: translateY(11px);
  }
  .header .burger.is-open {
    background: transparent;
    box-shadow: none;
    position: fixed;
  }
  .header .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .header .burger.is-open .burger--middle::before {
    transform: rotate(135deg);
  }
  .header .burger.is-open .burger--middle::after {
    transform: rotate(45deg);
  }
  .header .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
}

/*=================================
  footer
=================================*/
.footer__cont--cate {
  background: #333;
  padding: 40px 3%;
}

.footer__cont--cate__inner {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer__cont--cate .fNavCate__ttl {
  font-size: 2.2rem;
  font-weight: 300;
  color: #fff;
  margin-bottom: 30px;
}

.footer__cont--cate .fNavCate--btn {
  width: 300px;
  margin-right: 20px;
  margin-bottom: 6px;
}

.footer__cont--cate .fNavCate--btn__wrap {
  display: flex;
  justify-content: space-between;
}

.footer__cont--cate .fNavCate--btn .fNavCate__item {
  width: 100%;
}

.footer__cont--cate .fNavCate--btn .fNavCate__item a {
  font-size: 1.4rem;
  color: #fff;
  border: 1px solid #515151;
  border-radius: 4px;
  background: #3d3d3d;
  padding: 33px 27px 35px;
  display: block;
  text-align: center;
  text-decoration: none;
}

@media screen and (min-width: 767px) {
  .footer__cont--cate .fNavCate--btn .fNavCate__item a:hover {
    text-decoration: underline;
  }
}

.footer__cont--cate .fNavCate--btn .fNavCate__item a img {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.footer__cont--cate .fNavCate--btn .fNavCate__item + .fNavCate__item {
  margin-left: 20px;
}

.footer__cont--cate .fNavCate--list {
  width: calc(100% - 356px);
  min-width: 664px;
}

.footer__cont--cate .fNavCate--list__wrap {
  background: #3d3d3d;
  border-radius: 4px;
  border: 1px solid #515151;
  position: relative;
}

.footer__cont--cate .fNavCate--list__item {
  display: inline-block;
}

.footer__cont--cate .fNavCate--list__item a {
  font-size: 1.4rem;
  color: #fff;
  padding: 20px 15px;
  background: #3d3d3d;
  display: block;
  text-decoration: none;
}

.footer__cont--cate .fNavCate--list__item a .icon {
  width: 32px;
  margin-right: 7px;
  vertical-align: middle;
}

@media screen and (min-width: 767px) {
  .footer__cont--cate .fNavCate--list__item a:hover {
    text-decoration: underline;
  }
}

.footer__cont--cate .fNavCate--list__item:last-child::after {
  content: "";
  width: 100%;
  height: 1px;
  display: block;
  background: #515151;
  position: absolute;
  top: 50%;
  left: 0;
}

.footer__cont--list {
  background: #444;
  padding: 30px 0;
}

.footer__cont--list .fNavList {
  max-width: 1160px;
  margin: 0 auto;
  text-align: center;
}

.footer__cont--list .fNavList__item {
  display: inline-block;
  margin: 0 18px;
}

.footer__cont--list .fNavList__item a {
  font-size: 1.4rem;
  color: #fff;
  text-decoration: none;
  position: relative;
}

.footer__cont--list .fNavList__item a::before {
  content: "";
  left: 8px;
  box-sizing: border-box;
  width: 6px;
  height: 6px;
  border: 4px solid transparent;
  border-left: 8px solid #fff;
  display: inline-block;
}

@media screen and (min-width: 767px) {
  .footer__cont--list .fNavList__item a:hover {
    text-decoration: underline;
  }
}

.footer__cont--group {
  background: #2f74b7;
  padding: 30px 0 25px;
}

.footer__cont--group .groupTtl {
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}

.footer__cont--group .groupList {
  width: 90%;
  margin: 0 auto;
}

.footer__cont--group .groupList__item {
  display: inline-block;
  margin: 0 20px 0 0;
}

.footer__cont--group .groupList__item a {
  font-size: 1.6rem;
  color: #fff;
}

.footer__cont--bnr {
  padding: 40px 1%;
}

.footer__cont--bnr .bnrTtl {
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
}

.footer__cont--bnr .bnrList {
  max-width: 1160px;
  margin: 0 auto;
}

.footer__cont--bnr .bnrList__item {
  display: inline-block;
  margin: 0 8px 15px 0;
}

.footer__cont--bnr .bnrList__item a {
  display: block;
}

@media screen and (min-width: 767px) {
  .footer__cont--bnr .bnrList__item a:hover {
    opacity: .8;
  }
}

.footer .copy {
  background: #333;
  text-align: center;
  padding: 35px 0;
}

.footer .copy__logo {
  margin-bottom: 10px;
}

.footer .copy__text {
  font-size: 1.4rem;
  color: #fff;
  display: block;
  text-align: center;
}

.fixedBnr {
  width: 100%;
  max-width: 1160px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  /*=================================
    footer
  =================================*/
  .footer__cont--cate {
    padding: 30px 3%;
  }
  .footer__cont--cate__inner {
    display: block;
  }
  .footer__cont--cate .fNavCate__ttl {
    font-size: 2rem;
    margin-bottom: 15px;
    text-align: center;
  }
  .footer__cont--cate .fNavCate--btn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }
  .footer__cont--cate .fNavCate--btn__wrap {
    justify-content: center;
  }
  .footer__cont--cate .fNavCate--btn .fNavCate__item {
    width: inherit;
  }
  .footer__cont--cate .fNavCate--btn .fNavCate__item a {
    padding: 26px 17px 27px;
  }
  .footer__cont--cate .fNavCate--btn .fNavCate__item + .fNavCate__item {
    margin-left: 10px;
  }
  .footer__cont--cate .fNavCate--list {
    width: 100%;
    min-width: inherit;
  }
  .footer__cont--cate .fNavCate--list__item {
    display: inline-block;
  }
  .footer__cont--cate .fNavCate--list__item a {
    padding: 15px 12px 15px;
  }
  .footer__cont--cate .fNavCate--list__item a .icon {
    width: 26px;
    margin-right: 5px;
  }
  .footer__cont--cate .fNavCate--list__item:last-child::after {
    content: none;
  }
  .footer__cont--list {
    padding: 25px 3%;
  }
  .footer__cont--list .fNavList {
    text-align: left;
  }
  .footer__cont--list .fNavList__item {
    margin: 0 10px 5px 0;
  }
  .footer__cont--list .fNavList__item a {
    font-size: 1.2rem;
  }
  .footer__cont--group {
    padding: 25px 3% 20px;
  }
  .footer__cont--group .groupTtl {
    margin-bottom: 15px;
  }
  .footer__cont--group .groupList {
    width: 100%;
  }
  .footer__cont--group .groupList__item {
    margin: 0 10px 5px 0;
  }
  .footer__cont--group .groupList__item a {
    font-size: 1.4rem;
  }
  .footer__cont--bnr {
    padding: 30px 3%;
  }
  .footer__cont--bnr .bnrTtl {
    font-size: 2rem;
    margin-bottom: 25px;
  }
  .footer__cont--bnr .bnrList__item {
    width: 47%;
    margin: 0 1% 2%;
  }
  .footer__cont--bnr .bnrList__item a {
    display: block;
  }
  .footer .copy {
    padding: 25px 0;
  }
  .footer .copy__text {
    font-size: 1.2rem;
  }
  .fixedBnr {
    bottom: 0;
  }
}

/*=================================
  visual
=================================*/
.mv {
  padding: 0 3%;
  background: #eee;
}

.mv__inner {
  max-width: 1160px;
  margin: 0 auto;
  padding: 121px 0 37px;
  background: url(../img/mv.png) no-repeat right top;
  text-shadow: 0 0 3px rgba(226, 226, 226, 0.8);
}

.mv__ttl {
  font-size: 5rem;
  font-weight: 400;
  margin-bottom: 14px;
}

.mv__text {
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 2;
}

.kv {
  padding: 0 3%;
  background: #eee;
}

.kv__inner {
  max-width: 1160px;
  margin: 0 auto;
  padding: 50px 0;
  background: url(../img/mv.png) no-repeat right top;
  text-shadow: 0 0 3px rgba(226, 226, 226, 0.8);
}

.kv__ttl {
  font-size: 4rem;
  font-weight: 400;
  margin-bottom: 14px;
}

.kv__text {
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 2;
}

.kv__link {
  font-size: 2rem;
  font-weight: 400;
  display: block;
  padding: 6px 0 6px 40px;
  background: url(../img/icon/ico_arw02.png) no-repeat left center;
  margin-top: 20px;
}

.kv.divorce .kv__inner {
  background: url(../img/kv_divorce.png) no-repeat right top;
}

.kv.debt .kv__inner {
  background: url(../img/kv_debt.png) no-repeat right top;
}

.kv.real-estate .kv__inner {
  background: url(../img/kv_real-estate.png) no-repeat right top;
}

.kv.labor-problems .kv__inner {
  background: url(../img/kv_labor-problems.png) no-repeat right top;
}

.kv.traffic-accident .kv__inner {
  background: url(../img/kv_traffic-accident.png) no-repeat right top;
}

.kv.corporate .kv__inner {
  background: url(../img/kv_corporate.png) no-repeat right top;
}

.kv.criminal-case .kv__inner {
  background: url(../img/kv_criminal-case.png) no-repeat right top;
}

.kv.debt-collection .kv__inner {
  background: url(../img/kv_debt-collection.png) no-repeat right top;
}

.kv.consumer-damage .kv__inner {
  background: url(../img/kv_consumer-damage.png) no-repeat right top;
}

.kv.inheritance .kv__inner {
  background: url(../img/kv_inheritance.png) no-repeat right top;
}

@media screen and (max-width: 767px) {
  /*=================================
    visual
  =================================*/
  .mv {
    height: 260px;
    padding: 0;
  }
  .mv__inner {
    padding: 30px 3% 60px;
    background: #ececea url(../img/mv_sp.png) no-repeat center center;
    background-size: cover;
    text-shadow: none;
  }
  .mv__ttl {
    font-size: 2.8rem;
    font-weight: 300;
    margin-bottom: 8px;
  }
  .mv__text {
    font-size: 1.6rem;
    line-height: 2;
  }
  .kv {
    padding: 0;
  }
  .kv__inner {
    padding: 30px 15% 15px 3%;
    background: #ececea url(../img/mv_sp.png) no-repeat center center;
    background-size: cover;
    text-shadow: none;
  }
  .kv__ttl {
    font-size: 2.8rem;
    font-weight: 300;
    margin-bottom: 8px;
  }
  .kv__text {
    font-size: 1.4rem;
    line-height: 1.5;
  }
  .kv__link {
    font-size: 1.4rem;
    padding: 6px 0 6px 22px;
    background: url(../img/icon/ico_arw02.png) no-repeat left center;
    background-size: 18px;
    margin-top: 12px;
  }
  .kv.divorce .kv__inner {
    background: url(../img/kv_sp_divorce.png) no-repeat right top;
    background-size: cover;
  }
  .kv.debt .kv__inner {
    background: url(../img/kv_sp_debt.png) no-repeat right top;
    background-size: cover;
  }
  .kv.real-estate .kv__inner {
    background: url(../img/kv_sp_real-estate.png) no-repeat right top;
    background-size: cover;
  }
  .kv.labor-problems .kv__inner {
    background: url(../img/kv_sp_labor-problems.png) no-repeat right top;
    background-size: cover;
  }
  .kv.traffic-accident .kv__inner {
    background: url(../img/kv_sp_traffic-accident.png) no-repeat right top;
    background-size: cover;
  }
  .kv.corporate .kv__inner {
    background: url(../img/kv_sp_corporate.png) no-repeat right top;
    background-size: cover;
  }
  .kv.criminal-case .kv__inner {
    background: url(../img/kv_sp_criminal-case.png) no-repeat right top;
    background-size: cover;
  }
  .kv.debt-collection .kv__inner {
    background: url(../img/kv_sp_debt-collection.png) no-repeat right top;
    background-size: cover;
  }
  .kv.consumer-damage .kv__inner {
    background: url(../img/kv_sp_consumer-damage.png) no-repeat right top;
    background-size: cover;
  }
  .kv.inheritance .kv__inner {
    background: url(../img/kv_sp_inheritance.png) no-repeat right top;
    background-size: cover;
  }
}

/* =================================
  side nav
================================= */
.sideNav {
  width: 300px;
}

.sideNav__inner {
  width: 300px;
}

.sideNav .sideSec__ttl {
  font-size: 2rem;
  font-weight: 300;
  color: #222;
  padding: 30px 0;
  border-top: 4px solid #ddd;
  position: relative;
}

.sideNav .sideSec__ttl::before {
  content: "";
  width: 35%;
  height: 4px;
  display: block;
  background: #005bac;
  position: absolute;
  top: -4px;
  left: 0;
}

.sideNav .sideSec + .sideSec {
  margin-top: 40px;
}

.sideNav .catList__item a {
  font-size: 1.4rem;
  display: block;
  border-top: 1px solid #ddd;
  padding: 16px 30px;
  position: relative;
}

.sideNav .catList__item a::before {
  content: "";
  display: inline-block;
  border-style: solid;
  border-color: transparent transparent transparent #005bac;
  border-width: 5px 0 5px 8px;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

@media screen and (min-width: 767px) {
  .sideNav .catList__item a:hover {
    color: #fff;
    background: #337cbd;
  }
  .sideNav .catList__item a:hover::before {
    border-color: transparent transparent transparent #fff;
  }
}

.sideNav .catList__item:last-child a {
  border-bottom: 1px solid #ddd;
}

.sideNav .pref__ttl {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1;
  color: #005bac;
}

.sideNav .prefWrap {
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.sideNav .prefList__item {
  display: inline-block;
  margin-right: 5px;
}

.sideNav .prefList__item a {
  font-size: 1.4rem;
  color: #222;
  display: block;
}

.bengoList .sideNav {
  width: 260px;
}

.bengoList .sideNav__inner {
  width: 260px;
}

.bengoList .sideNav .sideSec--blue__ttl {
  font-size: 1.8rem;
  font-weight: 400;
  color: #fff;
  padding: 25px 20px;
  background: #337cbd;
}

.bengoList .sideNav .sideSec--blue__inner {
  padding: 20px 15px;
  border: 1px solid #ddd;
  border-top: none;
  background: #fff;
}

.bengoList .sideNav .sideSec--blue .catList__item a {
  font-size: 1.6rem;
  border-top: none;
  padding: 5px 0;
}

.bengoList .sideNav .sideSec--blue .catList__item a::before {
  content: none;
}

@media screen and (min-width: 767px) {
  .bengoList .sideNav .sideSec--blue .catList__item a:hover {
    color: #005bac;
    background: inherit;
  }
}

.bengoList .sideNav .sideSec--blue .catList__item:last-child a {
  border-bottom: none;
}

.bengoList .sideNav .sideSec--blue + .sideSec--blue {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  /* =================================
    side nav
  ================================= */
  .sideNav {
    display: none;
    width: 100%;
  }
  .sideNav__inner {
    width: 100%;
  }
}
