@charset "utf-8";

/*=================================
  search
=================================*/
.searchBox {
  padding: 15px 0;
  background: #333;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .officeNum {
    font-size: 2.6rem;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    display: none; /* top-page only parts */
    margin-bottom: 20px;
    text-align: center;
    .num {
      font-size: 3.4rem;
    }
  }
  .inputBox {
    background: #222;
    padding: 10px;
    &__inner {
      background: #fff;
    }
    .formWrap {
      display: flex;
    }
    .ken {
      width: calc((115% - 180px) / 2);
      padding-left: 90px;
      position: relative;
      &::before {
        content: "エリア";
        font-size: 1.4rem;
        font-weight: 300;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 25px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url(../img/icon/ico_sea01.png) no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 18px;
        left: 30px;
      }
      .area {
        font-size: 1.6rem;
        color: #555;
        text-decoration: underline;
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
        @include mq-desktop {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .naiyo {
      width: calc((85% - 180px) / 2);
      padding-left: 90px;
      position: relative;
      background: #f2f2f2;
      /* Old browsers */
      background: -moz-linear-gradient(left,  #f2f2f2 0%, #ffffff 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  #f2f2f2 0%,#ffffff 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  #f2f2f2 0%,#ffffff 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#ffffff',GradientType=1 );
      /* IE6-9 */
      &::before {
        content: "ご相談内容";
        font-size: 1.4rem;
        font-weight: 300;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 14px;
      }
      &::after {
        content: "";
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url(../img/icon/ico_sea02.png) no-repeat;
        background-size: cover;
        position: absolute;
        bottom: 18px;
        left: 36px;
      }
    }
    .btn {
      width: 180px;
      cursor: pointer;
      background: #1166b2;
      /* Old browsers */
      background: -moz-linear-gradient(top,  #1166b2 0%, #005bac 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  #1166b2 0%,#005bac 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  #1166b2 0%,#005bac 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1166b2', endColorstr='#005bac',GradientType=0 );
      /* IE6-9 */
      a {
        display: block;
        height: 100%;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 30px;
          height: 30px;
          background: url(../img/icon/ico_sea03.png) no-repeat;
          background-size: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
      @include mq-desktop {
        &:hover {
          background: #157ad4;
          background: -moz-linear-gradient(top, #157ad4 0%, #0471d1 100%);
          background: -webkit-linear-gradient(top, #157ad4 0%,#0471d1 100%);
          background: linear-gradient(to bottom, #157ad4 0%,#0471d1 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#157ad4', endColorstr='#0471d1',GradientType=0 );
        }
      }
    }
    .input {
      font-size: 2.6rem;
      padding: 16px 0;
      width: 100%;
      display: inline-block;
      cursor: pointer;
    }
  }
  .kenlist,.naiyolist {
    display: none;
  }
  .kenlist {
    width: 100%;
    max-width: 800px;
    padding: 30px 30px 10px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 1px 6px -4px #000;
    .kenTtl {
      font-size: 1.5rem;
      font-weight: 500;
      color: $color-theme;
      margin-bottom: 5px;
    }
    .kenName {
      line-height: 1;
      margin: 0 10px 10px 0;
      float: left;
      a,span {
        font-size: 1.4rem;
        text-decoration: underline;
        color: #333;
        cursor: pointer;
        @include mq-desktop {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &__block {
      float: left;
      width: 370px;
    }
    &__item {
      margin-bottom: 10px;
    }
  }
  .naiyolist {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 600px;
    background: #fff;
    box-shadow: 0 1px 6px -4px #000;
    z-index: 9999;
    &__item {
      width: 25%;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      height: 80px;
      float: left;
      text-align: center;
      span {
        font-size: 1.4rem;
        color: #333;
        padding: 20px 0;
        display: block;
        cursor: pointer;
        .icon {
          margin-right: 6px;
          vertical-align: middle;
        }
        @include mq-desktop {
          &:hover {
            background: #ecf8fd;
          }
        }
      }
    }
    .other {
      color: #333;
      padding: 15px 20px;
      background: #fff;
    }
  }
}
/* オーバーレイ */
#overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9001;
  cursor: pointer;
}

@media screen and (max-width: 1025px) {
  /*=================================
    search
  =================================*/
  .searchBox {
    padding: 20px 10px;
    .officeNum {
      font-size: 1.8rem;
      .num {
        font-size: 2.4rem;
      }
    }
    .inputBox {
      padding: 5px;
      .formWrap {
        display: block;
      }
      .area {
        display: none;
        top: 10px;
        right: 10px;
        left: inherit;
        width: 50px;
        text-align: left;
      }
      .ken {
        width: 100%;
        padding-left: 64px;
        border-bottom: 1px solid #ddd;
        position: relative;
        &::before {
          font-size: 1.1rem;
          top: 7px;
          left: 16px;
        }
        &::after {
          width: 22px;
          height: 22px;
          bottom: 7px;
          left: 20px;
        }
        .area {
          display: none;
        }
      }
      .naiyo {
        width: 100%;
        padding-left: 64px;
        background: #fff;
        position: relative;
        &::before {
          font-size: 1.1rem;
          top: 7px;
          left: 6px;
        }
        &::after {
          width: 22px;
          height: 22px;
          bottom: 7px;
          left: 20px;
        }
      }
      .btn {
        width: 100%;
        padding: 20px 0;
        a {
          &::before {
            width: 18px;
            height: 18px;
          }
        }
      }
      .input {
        font-size: 1.6rem;
        padding: 15px 0;
      }
    }
    .kenlist {
      max-height: calc(50vh - 55px);
      overflow-y: auto;
      width: calc(100% - 56px);
      padding: 0;
      position: absolute;
      top: 55px;
      right: 0;
      left: inherit;
      transform: inherit;
      border: 1px solid #ddd;
      .kenTtl {
        font-size: 1.7rem;
        color: #333;
      }
      .kenName {
        margin: 0 0 0 1.8rem;
        padding: 8px 0;
        float: none;
        a,span {
          font-size: 1.6rem;
          color: #444;
        }
      }
      &__block {
        float: none;
        width: 100%;
      }
      &__item {
        margin-bottom: 10px;
      }
    }
    .naiyolist {
      max-height: calc(50vh - 55px);
      overflow-y: auto;
      width: calc(100% - 56px);
      position: absolute;
      top: 55px;
      right: 0;
      left: inherit;
      transform: inherit;
      border: 1px solid #ddd;
      &__item {
        width: 100%;
        height: inherit;
        border: none;
        float: none;
        text-align: left;
        span {
          font-size: 1.6rem;
          color: #444;
          padding: 8px 0;
          .icon {
            display: none;
          }
        }
      }
      .other {
        display: none;
      }
    }
  }
}