@charset "utf-8";
/*=================================
  事務所一覧
=================================*/
.catSelect {
  &Wrap {
    padding: 20px 1%;
    background: #333;
  }
  &List {
    max-width: $base-width;
    margin: 0 auto;
    border: 1px solid #515151;
    border-radius: 4px;
    background: #3d3d3d;
    position: relative;
    &__item {
      display: inline-block;
      a {
        font-size: 1.6rem;
        color: #fff;
        display: block;
        padding: 20px 35px 20px;
        background: #3d3d3d;
        text-decoration: none;
        @include mq-tab {
          padding: 15px 10px 15px;
        }
        .icon {
          width: 40px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
      &:last-child {
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: #515151;
          position: absolute;
          top: 50%;
          left: 0;
        }
      }
      &.on {
        a {
          background: #777;
        }
      }
      @include mq-desktop {
        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.bengoList {
  background: #eee;
  .layout--2col {
    padding: 40px 0 100px;
    &__cont {
      width: calc(100% - 300px);
      padding: 0;
      border-top: none;
      &::before {
        content: none;
      }
    }
  }
}

.officeList {
  &__item {
    background: #fff;
    box-shadow: 0 0 15px -5px rgba(0, 0, 0, .1);
    margin: 30px 0;
    .thumb {
      width: 270px;
      float: left;
    }
    .thumb + .contWrap {
      padding: 30px 30px 0 310px;
    }
    .contWrap {
      padding: 20px;
      .tag {
        margin-bottom: 15px;
        &__item {
          display: inline-block;
          font-size: 1.1rem;
          line-height: 1;
          color: #fff;
          margin: 0 3px 3px 0;
          padding: 4px 8px;
          border-radius: 3px;
          background: $color-blue;
        }
      }
      .name {
        font-size: 2.4rem;
        margin-bottom: 10px;
      }
      .add {
        font-size: 1.4rem;
      }
      .tableWrap {
        display: flex;
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        .tableBlock {
          width: 50%;
          text-align: center;
          &:not(:first-child) {
            border-left: 1px solid #d6d6d6;
          }
          .ttl {
            font-size: 1.3rem;
            font-weight: 600;
            padding: 3px;
            background: #f3f3f3;
          }
          .cont {
            padding: 10px;
            .text {
              display: inline-block;
              vertical-align: middle;
            }
            .fieldList {
              &__item {
                display: inline-block;
                width: 24px;
                margin: 0 4px;
              }
            }
          }
        }
      }
      .text {
        font-size: 1.4rem;
        margin-top: 30px;
      }
    }
    .btnWrap {
      margin: 0;
      padding: 40px 20px;
      background: #e9f5ff;
      .telBtn {
        font-size: 3.4rem;
        font-weight: 500;
        color: #fff;
        background: #338d3d;
        box-shadow: 0 5px 0 #1b7025;
        text-decoration: none;
        border-radius: 5px;
        padding: 15px;
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(5px);
          }
        }
      }
      .mailBtn {
        font-size: 2.8rem;
        font-weight: 500;
        color: #fff;
        background: #337cbd;
        box-shadow: 0 5px 0 #2667a1;
        text-decoration: none;
        border-radius: 5px;
        padding: 15px;
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq-desktop {
          &:hover {
            box-shadow: none;
            transform: translateY(5px);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    事務所一覧
  =================================*/
  .catSelect {
    &Wrap {
      padding: 20px 3%;
    }
    &List {
      border: none;
      border-radius: 0;
      display: flex;
      flex-wrap: wrap;
      background: none;
      &__item {
        display: block;
        width: calc(100% / 3);
        text-align: center;
        a {
          font-size: 1.4rem;
          padding: 8px 0;
          background: none;
          .icon {
            width: 20px;
            margin-right: 3px;
            margin-top: -2px;
          }
        }
        &:last-child {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  .bengoList {
    .layout--2col {
      padding: 30px 3% 50px;
      &__cont {
        width: 100%;
        &::before {
          content: none;
        }
      }
    }
  }

  .officeList {
    &__item {
      margin: 30px 0;
      .thumb {
        width: 30%;
        margin: 20px 15px 0 15px;
      }
      .thumb + .contWrap {
        padding: 20px 15px;
      }
      .contWrap {
        padding: 20px 15px;
        .name {
          font-size: 2rem;
          line-height: 1.4;
          font-weight: 500;
        }
        .tableWrap {
          width: 100%;
          margin: 20px 0;
          .tableBlock {
            .ttl {
              font-size: 1.2rem;
            }
            .cont {
              padding: 5px;
              .fieldList {
                &__item {
                  width: 20px;
                  margin: 0 2px;
                }
              }
            }
          }
        }
        .text {
          margin-top: 20px;
        }
      }
      .btnWrap {
        padding: 20px 5%;
        .telBtn {
          font-size: 2.4rem;
          border-radius: 3px;
          padding: 10px;
          width: 100%;
        }
        .mailBtn {
          font-size: 2.4rem;
          border-radius: 3px;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
}