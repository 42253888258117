@charset "utf-8";
/* =================================
  side nav
================================= */
.sideNav {
  width: 300px;
  &__inner {
    width: 300px;
  }
  .sideSec__ttl {
    font-size: 2rem;
    font-weight: 300;
    color: #222;
    padding: 30px 0;
    border-top: 4px solid #ddd;
    position: relative;
    &::before {
      content: "";
      width: 35%;
      height: 4px;
      display: block;
      background: $color-theme;
      position: absolute;
      top: -4px;
      left: 0;
    }
  }
  .sideSec + .sideSec {
    margin-top: 40px;
  }
  .catList {
    &__item {
      a {
        font-size: 1.4rem;
        display: block;
        border-top: 1px solid #ddd;
        padding: 16px 30px;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          border-style: solid;
          border-color: transparent transparent transparent $color-theme;
          border-width: 5px 0 5px 8px;
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }
        @include mq-desktop {
          &:hover {
            color: #fff;
            background: $color-blue;
            &::before {
              border-color: transparent transparent transparent #fff;
            }
          }
        }
      }
      &:last-child a {
        border-bottom: 1px solid #ddd;
      }
    }
  }
  .pref {
    &__ttl {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1;
      color: $color-theme;
    }
    &Wrap {
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
    &List {
      &__item {
        display: inline-block;
        margin-right: 5px;
        a {
          font-size: 1.4rem;
          color: #222;
          display: block;
        }
      }
    }
  }
}
.bengoList {
  .sideNav {
    width: 260px;
    &__inner {
      width: 260px;
    }
    .sideSec--blue {
      &__ttl {
        font-size: 1.8rem;
        font-weight: 400;
        color: #fff;
        padding: 25px 20px;
        background: $color-blue;
      }
      &__inner {
        padding: 20px 15px;
        border: 1px solid #ddd;
        border-top: none;
        background: #fff;
      }
      .catList {
        &__item {
          a {
            font-size: 1.6rem;
            border-top: none;
            padding: 5px 0;
            &::before {
              content: none;
            }
            @include mq-desktop {
              &:hover {
                color: $color-theme;
                background: inherit;
              }
            }
          }
          &:last-child a {
            border-bottom: none;
          }
        }
      }
    }
    .sideSec--blue + .sideSec--blue {
      margin-top: 40px;
    }
  }
}
@media screen and (max-width: $display-width-s){
  /* =================================
    side nav
  ================================= */
  .sideNav {
    display: none;
    width: 100%;
    &__inner {
      width: 100%;
    }
  }
}