@charset "utf-8";
/*=================================
  visual
=================================*/
.mv {
  padding: 0 3%;
  background: #eee;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    padding: 121px 0 37px;
    background: url(../img/mv.png) no-repeat right top;
    text-shadow: 0 0 3px rgba(226,226,226,0.8);
  }
  &__ttl {
    font-size: 5rem;
    font-weight: 400;
    margin-bottom: 14px;
  }
  &__text {
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 2;
  }
}

.kv {
  padding: 0 3%;
  background: #eee;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    padding: 50px 0;
    background: url(../img/mv.png) no-repeat right top;
    text-shadow: 0 0 3px rgba(226,226,226,0.8);
  }
  &__ttl {
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 14px;
  }
  &__text {
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 2;
  }
  &__link {
    font-size: 2rem;
    font-weight: 400;
    display: block;
    padding: 6px 0 6px 40px;
    background: url(../img/icon/ico_arw02.png) no-repeat left center;
    margin-top: 20px;
  }
  $caseList: divorce, debt, real-estate, labor-problems, traffic-accident, corporate, criminal-case, debt-collection, consumer-damage, inheritance;
  @each $name in $caseList {
    &.#{$name} {
      .kv__inner {
        background: url(../img/kv_#{$name}.png) no-repeat right top;
      }
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    visual
  =================================*/
  .mv {
    height: 260px;
    padding: 0;
    &__inner {
      padding: 30px 3% 60px;
      background: #ececea url(../img/mv_sp.png) no-repeat center center;
      background-size: cover;
      text-shadow: none;
    }
    &__ttl {
      font-size: 2.8rem;
      font-weight: 300;
      margin-bottom: 8px;
    }
    &__text {
      font-size: 1.6rem;
      line-height: 2;
    }
  }
  .kv {
    padding: 0;
    &__inner {
      padding: 30px 15% 15px 3%;
      background: #ececea url(../img/mv_sp.png) no-repeat center center;
      background-size: cover;
      text-shadow: none;
    }
    &__ttl {
      font-size: 2.8rem;
      font-weight: 300;
      margin-bottom: 8px;
    }
    &__text {
      font-size: 1.4rem;
      line-height: 1.5;
    }
    &__link {
      font-size: 1.4rem;
      padding: 6px 0 6px 22px;
      background: url(../img/icon/ico_arw02.png) no-repeat left center;
      background-size: 18px;
      margin-top: 12px;
    }
    $caseList: divorce, debt, real-estate, labor-problems, traffic-accident, corporate, criminal-case, debt-collection, consumer-damage, inheritance;
    @each $name in $caseList {
      &.#{$name} {
        .kv__inner {
          background: url(../img/kv_sp_#{$name}.png) no-repeat right top;
          background-size: cover;
        }
      }
    }
  }
}