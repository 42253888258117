@charset "utf-8";

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #ccc;
  margin: 50px 0 30px;
  width: 100%;
  caption {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  thead {
    th {
      font-weight: 700;
      color: #fff;
      background: $color-theme;
    }
  }
  tr {
    border-bottom: 1px solid #ddd;
  }
  th {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.25;
    text-align: left;
    background: #f6f6f6;
    padding: 20px;
  }
  td {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.25;
    padding: 20px;
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #F1F1F1;
    }
    ::-webkit-scrollbar-thumb {
      background: #BCBCBC;
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0;
    border: none;
    tbody {
      border: 1px solid #ccc;
      min-width: calc(100vw - 12px);
      display: table;
    }
    caption {
      font-size: 1.5rem;
      margin: 15px 0;
      width: 94vw;
    }
    th {
      font-size: 1.3rem;
      padding: 10px;
    }
    td {
      font-size: 1.3rem;
      padding: 10px;
    }
  }
}