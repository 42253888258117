@charset "utf-8";
/*=================================
  page--article
=================================*/
@mixin singleStyle-base {
  p {
    font-size: 1.6rem;
    line-height: 1.875;
    margin-top: 30px;
  }
  h1 {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.2272;
    padding: 40px 16px;
    background: #f6f6f6;
    margin-top: 54px;
    margin-bottom: 30px;
    border-top: 4px solid #999;
    border-bottom: 1px solid #ddd;
    position: relative;
    &::before {
      content: "";
      width: 28%;
      height: 4px;
      display: block;
      background: $color-theme;
      position: absolute;
      top: -4px;
      left: 0;
    }
  }
  h3 {
    font-size: 2rem;
    font-weight: 500;
    color: #333;
    background: #f6f6f6;
    padding: 18px;
    border: 1px solid #ddd;
    margin-top: 35px;
    margin-bottom: 24px;
    position: relative;
    &::before {
      content: "";
      width: 4px;
      height: calc(100% + 2px);
      display: block;
      background: $color-theme;
      position: absolute;
      top: -1px;
      left: -1px;
    }
  }
  h4 {
    font-size: 1.8rem;
    font-weight: 500;
    color: #333;
    padding: 0 0 26px;
    margin-top: 35px;
    margin-bottom: 25px;
    border-bottom: 2px solid #ddd;
    position: relative;
    &::before {
      content: "";
      width: 28%;
      height: 2px;
      display: block;
      background: $color-theme;
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }
  h5 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
    background: #fff;
    padding: 8px 4px 12px;
    border-bottom: 1px solid #ddd;
    margin-top: 30px;
    margin-bottom: 20px;
    position: relative;
    &::before {
      content: "";
      width: 4px;
      height: calc(100% + 2px);
      display: block;
      background: $color-theme;
      position: absolute;
      top: -1px;
      left: -1px;
    }
  }
  h6 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 20px;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $color-theme;
      vertical-align: middle;
      margin-right: .8rem;
      margin-top: -2px;
    }
  }
  blockquote {
    position: relative;
    padding: 40px 35px;
    margin: 30px 0;
    box-sizing: border-box;
    font-style: italic;
    color: #464646;
    border: 3px solid #ddd;
    &::before {
      content: "“";
      font-size: 8rem;
      font-weight: 700;
      line-height: 1;
      color: $color-blue;
      display: inline-block;
      font-style: normal;
      width: 80px;
      height: 50px;
      text-align: center;
      background: #fff;
      position: absolute;
      top: -28px;
      left: 0;
    }
    h4, h5, h6 {
      margin: 0 0 20px;
      font-size: 1.6rem;
      line-height: 1.75;
      letter-spacing: .06em;
      position: relative;
      &::after {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
        display: inline-block;
        height: 10px;
        content: '';
        background-image: repeating-linear-gradient(-45deg, $color-blue, $color-blue 1px, transparent 2px, transparent 5px);
        background-size: 7px 7px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
    }
    p {
      font-style: normal;
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 10px 0 0;
    }
  }
  img {
    display: block;
    margin: 15px auto 20px;
  }
  ul:not([class]) {
    margin-top: 30px;
    margin-bottom: 54px;
    padding: 20px 30px;
    background: #f6f6f6;
    border: 1px solid #ddd; 
    li {
      font-size: 1.6rem;
      font-weight: 400;
      padding-left: 22px;
      position: relative;
      &::before {
        content: "";
        display: inline-block;
        border-width: 5px 0 5px 8px;
        border-style: solid;
        border-color: transparent transparent transparent $color-theme;
        position: absolute;
        top: 12px;
        left: 0;
      }
      & + li {
        margin-top: 15px;
      }
    }
  }
  ol:not([class]) {
    margin-top: 30px;
    margin-bottom: 54px;
    padding: 20px 25px;
    background: #f6f6f6;
    border: 1px solid #ddd; 
    counter-reset: listNum;
    li {
      font-size: 1.6rem;
      font-weight: 400;
      padding-left: 34px;
      position: relative;
      &::before {
        counter-increment: listNum;
        content: counter(listNum);
        @include font-roboto;
        font-size: 1.8rem;
        line-height: 1;
        color: #fff;
        background: $color-blue;
        border-radius: 50%;
        padding: 3px 8px 4px 8px;
        position: absolute;
        top: 2px;
        left: 0;
      }
      & + li {
        margin-top: 15px;
      }
    }
  }
  @include mq-sp {
    p {
      font-size: 1.4rem;
      line-height: 1.5;
      margin-top: 15px;
    }
    h1 {
      font-size: 2rem;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 1.8rem;
      line-height: 1.38888;
      padding: 15px 10px;
      margin-top: 40px;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 1.6rem;
      padding: 15px 10px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    h4 {
      font-size: 1.5rem;
      padding: 0 0 12px;
      margin-top: 30px;
      margin-bottom: 15px;
    }
    h5 {
      padding: 6px 12px 8px;
      margin-bottom: 15px;
    }
    h6 {
      margin-bottom: 15px;
      &::before {
        width: 8px;
        height: 8px;
        margin-right: .6rem;
      }
    }
    blockquote {
      padding: 25px 3% 20px;
      margin: 20px 0;
      &::before {
        font-size: 6rem;
        width: 50px;
        top: -22px;
      }
      h4, h5, h6 {
        margin: 0 0 15px;
        font-size: 1.4rem;
        line-height: 1.2;
        &::after {
          height: 7px;
        }
      }
    }
    img {
      display: block;
      margin: 15px auto;
    }
    ul:not([class]) {
      margin-bottom: 15px;
      padding: 15px 3%;
      li {
        font-size: 1.5rem;
        padding-left: 15px;
        &::before {
          border-width: 5px 0 5px 6px;
          top: 7px;
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
    ol:not([class]) {
      margin-bottom: 15px;
      padding: 15px 3%;
      li {
        font-size: 1.5rem;
        padding-left: 30px;
        &::before {
          font-size: 1.4rem;
          padding: 3px 6px 3px 6px;
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
  }
}
@mixin singleStyle-parts {
  // 目次
  .tocBox {
    margin: 40px 0 20px;
    &__ttl {
      font-size: 2.2rem;
      font-weight: 300;
      text-align: center;
      padding: 12px 0;
      .toc_toggle {
        font-size: 1.2rem;
        font-weight: normal;
        color: #999;
        margin: 0 0 0 18px;
        vertical-align: middle;
        cursor: pointer;
      }
    }
    .tocList {
      counter-reset: tocNum;
      &__item {
        a {
          font-size: 1.6rem;
          font-weight: 500;
          display: block;
          padding: 18px 16px 18px 60px;
          position: relative;
          border-bottom: 1px solid #e2e2e2;
          &::before {
            counter-increment:tocNum;
            content:counter(tocNum);
            @include font-roboto;
            font-size: 1.4rem;
            color: #fff;
            line-height: 22px;
            width: 22px;
            text-align: center;
            text-decoration: none;
            background: $color-blue;
            border-radius: 50%;
            position: absolute;
            top: 23px;
            left: 23px;
          }
        }
      }
    }
  }
  .infoWrap {
    margin-bottom: 30px;
    .info--left {
      float: left;
      .data {
        display: flex;
        .date {
          font-size: 1.8rem;
          font-weight: 500;
          color: $color-theme;
          padding-left: 20px;
          background: url(../img/icon/ico_cal.png) no-repeat left center;
          & + .date {
            margin-left: 1rem;
            background: url(../img/icon/ico_sea02.png) no-repeat left center;
            background-size: 16px auto;
          }
        }
        .view {
          font-size: 1.4rem;
          background: url(../img/icon/ico_eye.png) no-repeat left center;
          padding-left: 18px;
          margin-left: 2rem;
          .num {
            font-size: 1.8rem;
            font-weight: 500;
            color: $color-theme;
            margin-right: 3px;
          }
        }
      }
    }
    .info--right {
      float: right;
      display: flex;
      .list {
        &--cat {
          display: flex;
          background: url(../img/icon/ico_fol.png) no-repeat left center;
          padding-left: 22px;
        }
        &--tag {
          display: flex;
          background: url(../img/icon/ico_tag.png) no-repeat left center;
          padding-left: 22px;
          margin-left: 1rem;
        }
      }
      a {
        font-size: 1.4rem;
        color: #333;
      }
    }
  }
  .articleMv {
    margin: 20px auto;
    text-align: center;
  }
  .sns {
    &Wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &::before {
        content: "この記事をシェアする";
        font-size: 1.4rem;
        line-height: 1;
        margin: -10px 1rem 0 0;
      }
    }
    &List {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      li {
        line-height: 1;
        margin-left: 5px;
        &.fb {
          margin-right: -5px;
        }
      }
    }
  }
  //この記事で分かること
  .understand {
    margin: 60px auto 30px;
    &__ttl {
      font-weight: 500;
      color: #fff;
      text-align: center;
      background: $color-blue;
      padding: 12px 15px;
      position: relative;
      &::before {
        content: "";
        width: 25px;
        height: 22px;
        margin-right: 6px;
        display: inline-block;
        background: url(../img/icon/ico_kiji.png) no-repeat;
        vertical-align: middle;
      }
    }
    &__cont {
      background: #f8f8f8;
      border: 1px solid #ddd;
      border-top: none;
      padding: 25px;
    }
    &__list {
      &__item {
        font-size: 1.8rem;
        padding: 8px 0;
        position: relative;
        &::before {
          content: "";
          width: 21px;
          height: 21px;
          margin-right: 10px;
          display: inline-block;
          background: url(../img/icon/ico_chk01.png) no-repeat;
          vertical-align: middle;
        }
      }
    }
    &__text {
      margin-top: 20px;
    }
  }
  .link-bx {
    border: 1px solid #aecbe5;
    margin: 40px auto 20px;
    position: relative;
    z-index: 0;
    a {
      padding: 16px 20px;
      background: #e9f5ff;
      display: block;
      @include mq-desktop {
        &:hover {
          background: #fff;
        }
      }
    }
    &::before {
      content: "関連記事";
      color: #fff;
      padding: 5px 10px;
      background: #F30;
      position: absolute;
      top: -5px;
      right: -5px;
      z-index: 1;
    }
  }
  .point {
    color: #fff;
    text-align: center;
    background: $color-blue;
    margin: 40px auto 20px;
    &-ttl {
      font-size: 2rem;
      padding: 20px 0;
      border-bottom: 1px solid #71a4d1;
      &::before {
        content: "";
        width: 19px;
        height: 25px;
        display: inline-block;
        margin-right: 10px;
        background: url(../img/icon/ico_pnt.png) no-repeat;
        vertical-align: middle;
      }
    }
    &-bx {
      padding: 20px;
      text-align: center;
    }
  }
  .check-bx {
    background: #fff5f5;
    border: 3px solid #ff8d8d;
    padding: 15px 30px;
    margin-top: 35px;
    margin-bottom: 54px;
    li {
      font-weight: 600;
      color: #e50000;
      background: url(../img/icon/ico_chk02.png) no-repeat left center;
      padding: 8px 0 8px 34px;
    }
  }
  .related--simple {
    margin: 50px auto;
    border: 1px solid #ddd;
    position: relative;
    &__item {
      a {
        padding: 20px 15px;
        display: block;
        @include mq-desktop {
          &:hover {
            background: $color-blue;
            color: #fff;
          }
        }
      }
      & + .related--simple__item {
        border-top: 1px dotted #ddd;
      }
    }
    &::before {
      content: "一緒に読まれている記事";
      line-height: 1;
      color: #fff;
      background: $color-theme;
      padding: 8px 10px;
      position: absolute;
      top: -15px;
      right: -1px;
    }
  }
  @include mq-sp {
    // 目次
    .tocBox {
      margin: 30px 0 20px;
      &__ttl {
        font-size: 2rem;
        padding: 10px 0;
        .toc_toggle {
          margin: 0 0 0 10px;
        }
      }
      .tocList {
        &__item {
          a {
            font-size: 1.4rem;
            padding: 15px 3% 15px 40px;
            &::before {
              top: 15px;
              left: 10px;
            }
          }
        }
      }
    }
    .infoWrap {
      margin-bottom: 20px;
      .info--left {
        float: none;
        .data {
          .date {
            font-size: 1.4rem;
            & + .date {
              margin-left: .8rem;
            }
          }
          .view {
            font-size: 1.2rem;
            padding-left: 20px;
            margin-left: 1.5rem;
            .num {
              font-size: 1.4rem;
            }
          }
        }
      }
      .info--right {
        float: none;
        display: block;
        margin-top: 5px;
        .list {
          &--tag {
            margin-left: 0;
          }
        }
        a {
          font-size: 1.2rem;
        }
      }
    }
    .sns {
      &Wrap {
        &::before {
          content: none;
        }
      }
      &List {
        li {
          margin-left: 3px;
        }
      }
    }
    //この記事で分かること
    .understand {
      margin: 40px auto 20px;
      &__ttl {
        padding: 10px 3%;
      }
      &__cont {
        padding: 15px;
      }
      &__list {
        &__item {
          font-size: 1.4rem;
          padding: 5px 0 5px 30px;
          position: relative;
          &::before {
            margin-right: 0;
            position: absolute;
            top: 5px;
            left: 0;
          }
        }
      }
      &__text {
        margin-top: 15px;
        padding: 0 3%;
      }
    }
    .link-bx {
      margin: 30px auto 15px;
      a {
        padding: 20px 3% 15px;
      }
      &::before {
        padding: 3px 5px;
        top: -10px;
        right: -5px;
      }
    }
    .point {
      margin: 30px auto 15px;
      &-ttl {
        font-size: 1.4rem;
        padding: 15px 0 10px;
      }
      &-bx {
        padding: 15px;
        text-align: left;
      }
    }
    .check-bx {
      padding: 10px 3%;
      margin-top: 30px;
      margin-bottom: 15px;
      li {
        padding: 5px 0 5px 32px;
      }
    }
    .related--simple {
      margin: 40px auto;
      &__item {
        a {
          padding: 15px 3%;
        }
      }
      &::before {
        padding: 8px 6px;
        top: -20px;
      }
    }
  }
}
/* singlePage */
.singlePage {
  &__in {
    & > {
      @include singleStyle-base;
    }
  }
  @include singleStyle-parts;
}
/* 記事詳細ページ */
.articleDetail {
  &__in {
    & > {
      @include singleStyle-base;
    }
  }
  @include singleStyle-parts;
}