@charset "utf-8";
/*=================================
  カテゴリーページ
=================================*/
.blackBox {
  background: #333;
  padding: 27px 1%;
  .btnWrap {
    margin: 0 auto;
    a {
      font-size: 2.6rem;
      font-weight: 500;
      color: #fff;
      display: inline-block;
      text-align: center;
      background: $color-blue;
      box-shadow: 0 4px 0 $color-blue-dark;
      border-radius: 5px;
      width: 100%;
      padding: 30px 0;
      line-height: 1;
      max-width: $base-width;
      text-decoration: none;
      @include animation-base(all,.1s);
      &:hover {
        box-shadow: none;
        transform: translateY(4px);
      }
    }
  }
}
.catContWrap {
  background: #fff;
  padding: 60px 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .catContTtl {
    font-size: 2.6rem;
    font-weight: 500;
    color: #222;
    margin-bottom: 50px;
  }
  //メリット
  .merit {
    width: calc((100% - 60px) / 2);
    &List {
      &__item {
        background: #fff;
        width: 100%;
        border-top: 1px solid #ccc;
        padding: 30px 5px 30px 50px;
        &:last-child {
          border-bottom: 1px solid #ccc;
        }
        .meritTtl {
          font-size: 2rem;
          font-weight: 500;
          color: $color-theme;
          margin-bottom: 20px;
          position: relative;
        }
        .meritText {
          line-height: 1.5;
        }
        &:not(:last-child) {
          margin-right: 2px;
        }
        @for $meritNum from 1 through 3 {
          &:nth-child(#{$meritNum}) {
            .meritTtl {
              &::before {
                content: "";
                width: 35px;
                height: 35px;
                display: inline-block;
                background: #fff url(../img/icon/ico_mer0#{$meritNum}.png) no-repeat left top;
                background-size: contain;
                position: absolute;
                top: 0;
                left: -50px;
              }
            }
          }
        }
      }
    }
  }
  //都道府県検索
  .pref {
    width: calc((100% - 60px) / 2);
    &Wrap {
      &__block {
        display: flex;
        align-items: center;
        &:nth-child(2n - 1) {
          background: #f4f4f4;
        }
      }
    }
    &Ttl {
      font-size: 1.6rem;
      font-weight: 500;
      color: $color-theme;
      width: 190px;
      padding: 20px 15px;
    }
    &List {
      width: calc(100% - 190px);
      padding: 20px 15px;
      line-height: 1.2;
      &__item {
        line-height: 1;
        display: inline-block;
        margin-right: 5px;
        a {
          font-size: 1.4rem;
          font-weight: 400;
          color: #222;
          display: block;
        }
      }
    }
  }
}
.catContOffice {
  background: #77b8f2 url(../img/bg_pickup_office.png) no-repeat center top;
  padding: 50px 1%;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .pickOfficeWrap {
    &__ttl {
      font-size: 2rem;
      font-weight: 500;
      background: #f3f3f3;
      padding: 27px 32px;
      position: relative;
      .date {
        color: $color-theme;
        margin-right: 2rem;
      }
      &::after {
        content: "";
        width: calc(100% / 6);
        height: 4px;
        display: block;
        background: $color-theme;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &__list {
      border-top: 1px solid #ccc;
      background: #f3f3f3;
      display: flex;
      &__item {
        width: calc(100% / 4);
        min-height: 380px;
        padding: 15px 15px 25px;
        position: relative;
        background: #f3f3f3;
        .thumb {
          margin-bottom: 20px;
        }
        .textWrap {
          .name {
            display: block;
            text-align: center;
          }
          .text {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.8571;
            margin-top: 15px;
          }
        }
        .areaWrap {
          position: absolute;
          left: 15px;
          bottom: 25px;
          &::before {
            content: "";
            width: 14px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/ico_taiou.png) no-repeat center center;
            background-size: cover;
            vertical-align: middle;
          }
          .lavel,.area {
            font-size: 1.2rem;
            display: inline-block;
          }
          .area {
            &::before {
              content: "/";
              display: inline-block;
              margin: 0 .2rem 0 0;
            }
          }
        }
        &:not(:first-child) {
          border-left: 1px solid #ccc;
        }
        @include mq-desktop {
          &:hover {
            background: #fff;
            opacity: 1!important;
          }
        }
      }
    }
  }
}
.catConArticle {
  background: #eee;
  padding: 50px 1% 130px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .moreBtn {
    margin-top: 30px;
    .btn--border {
      max-width: 720px;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    カテゴリーページ
  =================================*/
  .blackBox {
    padding: 15px 3%;
    .btnWrap {
      a {
        font-size: 1.8rem;
        padding: 17px 0;
        line-height: 1.3;
      }
    }
  }
  .catContWrap {
    padding: 30px 0;
    &__inner {
      display: block;
    }
    .catContTtl {
      font-size: 2.2rem;
      margin-bottom: 25px;
      text-align: center;
    }
    //メリット
    .merit {
      width: 100%;
      &List {
        &__item {
          padding: 20px 5% 20px 60px;
          .meritTtl {
            font-size: 1.8rem;
            margin-bottom: 10px;
          }
          &:not(:last-child) {
            margin-right: 0;
          }
        }
      }
    }
    //都道府県検索
    .pref {
      width: 100%;
      padding-top: 30px;
      &Wrap {
        &__block {
          display: block;
          align-items: center;
        }
      }
      &Ttl {
        width: 100%;
        padding: 25px 15px 0;
      }
      &List {
        width: 100%;
        padding: 10px 15px 25px;
        line-height: 1.4;
        &__item {
          a {
            font-size: 1.4rem;
            font-weight: 400;
            color: #222;
            display: block;
          }
        }
      }
    }
  }
  .catContOffice {
    padding: 30px 3%;
    .pickOfficeWrap {
      &__ttl {
        font-size: 1.6rem;
        padding: 10px 15px 15px;
        .date {
          display: block;
          margin: 0 0 .5rem;
        }
        &::after {
          width: 100px;
          height: 3px;
        }
      }
      &__list {
        display: block;
        &__item {
          width: 100%;
          min-height: inherit;
          padding: 15px 15px 55px;
          .thumb {
            margin-bottom: 15px;
            margin: 0 auto;
            text-align: center;
          }
          .textWrap {
            .text {
              margin-top: 10px;
            }
          }
          .areaWrap {
            bottom: 15px;
          }
          &:not(:first-child) {
            border-left: none;
            border-top: 1px solid #ccc;
          }
        }
      }
    }
  }
  .catConArticle {
    padding: 30px 3% 60px;
    .moreBtn {
      margin-top: 10px;
    }
  }
}