@charset "utf-8";

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
}
.btn--red {
  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  background: #ea5454;
  box-shadow: 0 5px 0 #ab1414;
  border-radius: 3px;
  padding: 17px 0px;
  width: 100%;
}
.btn--blue {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.3;
  color: #fff;
  display: inline-block;
  text-align: center;
  background: $color-blue;
  box-shadow: 0 4px 0 $color-blue-dark;
  border-radius: 5px;
  padding: 17px 0px;
  width: 100%;
  &.lg {
    font-size: 1.8rem;
    padding: 15px 0;
    text-decoration: none;
  }
}
.btn--white {
  font-size: 1.8rem;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  background: #fff;
  box-shadow: 0 5px 0 #555;
  border-radius: 5px;
  padding: 17px 0px;
  width: 100%;
  @include animation-base(all,.1s);
  @include mq-desktop {
    &:hover {
      box-shadow: none;
      transform: translateY(5px);
    }
  }
}
.btn--border {
  font-size: 1.5rem;
  color: #333;
  border: 4px solid #333;
  background: #fff;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding: 16px 1%;
  &::before {
    content: "";
    display: inline-block;
    box-sizing: border-box;
    width: 6px;
    height: 6px;
    margin-right: 4px;
    border: 4px solid transparent;
    border-left: 8px solid #333;
  }
}
.btn--grayRound {
  font-size: 1.4rem;
  color: #222;
  background: #eee;
  border-radius: 50px;
  text-decoration: none;
  padding: 6px 15px;
  display: inline-block;
  margin-top: 5px;
}
.link--back {
  margin: 0 0 30px;
  a {
    font-size: 1.5rem;
    padding: 4px 0 4px 25px;
    background: url(../img/icon/ico_arw01.png) no-repeat left center;
    display: block;
  }
}

@media screen and (min-width: $display-width-s){
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    > * + * {
      margin: 0 0 0 20px;
    }
  }
  .btn--red {
    font-size: 2.6rem;
    border-radius: 5px;
    padding: 23px 10px;
    max-width: 94%;
    @include animation-base(all,.1s);
    &:hover {
      box-shadow: none;
      transform: translateY(6px);
    }
  }
  .btn--blue {
    font-size: 1.6rem;
    box-shadow: 0 4px 0 $color-blue-dark;
    border-radius: 5px;
    padding: 13px 10px;
    max-width: 94%;
    @include animation-base(all,.1s);
    &:hover {
      box-shadow: none;
      transform: translateY(4px);
    }
    &.lg {
      font-size: 2.6rem;
      padding: 30px 0;
      line-height: 1;
      max-width: $base-width;
    }
  }
  .btn--white {
    font-size: 2.4rem;
    box-shadow: 0 6px 0 #555;
    border-radius: 5px;
    padding: 23px 10px;
    max-width: 555px;
    @include animation-base(all,.1s);
    &:hover {
      box-shadow: none;
      transform: translateY(6px);
    }
  }
  .btn--border {
    font-size: 1.6rem;
    max-width: 94%;
    padding: 19px 15px;
    @include animation-base(all,.1s);
    &:hover {
      background: $color-theme;
      color: #fff;
      text-decoration: underline;
      border-color: $color-theme;
      &::before {
        border-left: 8px solid #fff;
      }
    }
  }
  .btn--grayRound {
    font-size: 1.6rem;
    padding: 8px 25px;
    &:hover {
      color: #fff;
      background: $color-blue;
    }
  }
  .link--back {
    margin: 0 0 40px;
    a {
      font-size: 1.6rem;
    }
  }
}